import axios from 'axios'
import config from './config'

const URL = `${config.URL}/passwordReset`

export async function resetPassword (email) {
  const result = await axios.post(`${URL}/admin`, {email}, config.configWithToken())
  return result.data
}

export async function verifyToken (token) {
  const result = await axios.post(`${URL}/verify`, {token})
  return result.data
}

export async function changePassword (token, password) {
  const result = await axios.post(`${URL}/reset`, {token, password})
  return result.data
}

export default {
  resetPassword,
  verifyToken,
  changePassword
}
