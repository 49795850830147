import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Button
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'

const Content = Layout.Content

const UserDetailMerchant = (props) => {
  const { push } = useHistory()
  const [birthdate, setBirthdate] = React.useState(undefined)
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [date_join, setDate_join] = React.useState(undefined)
  const [user, setUser] = React.useState({})
  const id = props.match.params.id

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio','Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + 
    getMonth(newDate.getMonth()) +' del ' + newDate.getFullYear()
  }

  const getUser = async () => {
    const user = await API.user.detail(id)
    if (user.birthdate) {
      setBirthdate(formatDate(user.birthdate))
    }
    setStart_date(formatDate(user.start_date))
    setUpdate_date(formatDate(user.update_date))
    setDate_join(user.date_join)
    setUser(user)
  }

  React.useEffect(() => {
    getUser()
  }, [])

  return (
    <Layout className='layout'>
      <Header item='user' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del usuario</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => push('/usermerchant')}>Regresar</Button>
            </Col>
          </Row>
          <Form layout='vertical'>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Nombres'>
                  <Input
                    disabled
                    value={user.first_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Apellidos'>
                  <Input
                    disabled
                    value={user.last_name}
                  />
                </Form.Item>
              </Col>
              {user.image && (
                <Col span={7} offset={1}>
                  <Form.Item label='Imagen'>
                    <img
                      alt={user.name}
                      src={user.image}
                      style={
                        { maxWidth: '30%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {user.country && (
              <Row>
                <Col span={7} offset={1}>
                  <Form.Item label='País'>
                    <Input
                      disabled
                      value={user.country.name}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label='Estado'>
                    <Input
                      disabled
                      value={user.state.name}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label='Ciudad'>
                    <Input
                      disabled
                      value={user.city.name}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Teléfono'>
                  <Input
                    disabled
                    value={user.cellphone}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Correo electrónico'>
                  <Input
                    disabled
                    value={user.email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de nacimiento'>
                  <Input
                    disabled
                    value={birthdate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Tipo de usuario'>
                  <Input
                    disabled
                    value={user.type_user}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Género'>
                  <Input
                    disabled
                    value={user.gender}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Último Login'>
                  <Input
                    disabled
                    value={date_join}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de creación'>
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de actualización'>
                  <Input
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default UserDetailMerchant
