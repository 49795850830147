import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col,
  Upload,
  Icon
} from 'antd'
import API from '../../../api/index'
import Header from '../../Header'

const { Content } = Layout
const { TextArea } = Input

const BusinessUpdate = (props) => {
  const id = props.match.params.id
  const topic = props.match.params.topic
  const [topics, setTopics] = React.useState([])
  const [idTopic, setIdTopic] = React.useState([])
  const [errorTopic, setErrorTopic] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [email, setEmail] = React.useState(undefined)
  const [errorEmail, setErrorEmail] = React.useState(undefined)
  const [nameTrue, setNameTrue] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [idCity, setIdCity] = React.useState([])
  const [idCountry, setIdCountry] = React.useState([])
  const [idState, setIdState] = React.useState([])
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [address, setAddress] = React.useState(undefined)
  const [errorAddress, setErrorAddress] = React.useState(undefined)

  const onBack = () => {
    setRedirect(`/business/list/${topic}`)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const topicC = async () => {
    const topic = await API.topic.ListTrue()
    setIdTopic(topic)
  }

  const countryC = async () => {
    const idCountry = await API.country.list()
    setIdCountry(idCountry)
  }

  const stateC = async (country = country) => {
    if (country) {
      const idState = await API.state.list(country)
      setIdState(idState)
    }
  }

  const cityC = async (state = state) => {
    if (state) {
      const idCity = await API.city.list(state)
      setIdCity(idCity)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    let nameNew
    if (name !== nameTrue) {
      nameNew = name
    }
    const response = await API.business.update(id, {
      name: nameNew,
      city: city,
      email: email,
      cellphone: cellphone,
      address: address,
      topic: topics,
      image: image,
      description: description
    })
    if (response.ok) {
      Modal.success({
        title: 'Actualización de un negocío',
        content: `El negocío ${name} fue actualizado exitosamente`
      })
      clearErrors()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.topic) {
      setErrorTopic(error.topic.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorCity(undefined)
    setErrorEmail(undefined)
    setErrorCellphone(undefined)
    setErrorTopic(undefined)
    setErrorImage(undefined)
    setErrorDescription(undefined)
    setErrorAddress(undefined)
  }

  const beforeUpload = () => {
    return false
  }
  const handleClearImage = async () => {
    setImage(undefined)
  }

  const handleChangeCountry = async (value) => {
    setCountry(value)
    stateC(value)
  }

  const handleChangeState = async (value) => {
    setState(value)
    cityC(value)
  }

  const getBusiness = async () => {
    const business = await API.business.detail(id)
    setName(business.name)
    setTopics(business.topic)
    setEmail(business.email)
    setCellphone(business.cellphone)
    setCity(business.city)
    setState(business.state)
    setCountry(business.country)
    setNameTrue(business.name)
    setImage(business.image)
    setDescription(business.description)
    setAddress(business.address)
    stateC(business.country)
    cityC(business.state)
  }

React.useEffect(() => {
  countryC()
  topicC()
  getBusiness()
}, [])
  

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  const City = createRow(idCity)
  const State = createRow(idState)
  const Country = createRow(idCountry)
  const Topic = createRow(idTopic)
  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualizar negocío</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre completo'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre del negocio'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        type='picture'
                        theme='outlined'
                        style={{ fontSize: '20px' }}
                      />{' '}
                      <span 
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                        />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='País'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(event) => handleChangeCountry(event.target.value)}
                    value={country}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {Country}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Estado'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(event) => handleChangeState(event.target.value)}
                    value={state}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {State}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(event) => setCity(event.target.value)}
                    value={city}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {City}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    name='cellphone'
                    placeholder='Ingrese el teléfono del negocio'
                    onChange={(event) => setCellphone(event.target.value)}
                    value={cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tema'
                  validateStatus={errorTopic && ('error')}
                  help={errorTopic}
                >
                  <Select
                    showSearch
                    mode='multiple'
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un tema'
                    onChange={(event) => setTopics(event.target.value)}
                    value={topics}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } 
                  >
                    {Topic}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='email'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='email'
                    placeholder='Ingrese el email del negocio'
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la dirección del negocio'
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    placeholder='Ingrese una breve descripción del negocío'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    row={4}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default BusinessUpdate
