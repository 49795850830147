import React from 'react'
import Assistants from './List'
import { Layout } from 'antd'
import Header from '../../Header'

const { Content } = Layout

const Assistant = (props) => {
  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Assistants id={props.match.params.topic} />
        </div>
      </Content>
    </Layout>
  )
}

export default Assistant
