import React from 'react'
import LoyaltyList from './List'
import { Layout } from 'antd'
import Header from '../Header'

const { Content } = Layout

const Loyalty = (props) => {
  return (
    <Layout className='layout'>
      <Header item='loyalty' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <LoyaltyList />
        </div>
      </Content>
    </Layout>
  )
}

export default Loyalty
