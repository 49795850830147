import auth from './auth'
import products from './products'
import trade from './trade'
import branchOffice from './branchOffice'
import category from './category'
import city from './city'
import state from './state'
import country from './country'
import sections from './sections'
import loyalty from './loyalty'
import user from './User'
import address from './address'
import inventory from './inventory'
import bill from './bill'
import csv from './csv'
import log from './log'
import relation from './relationLoyalty'
import profession from './profession'
import persons from './persons'
import topic from './topic'
import business from './business'
import resetPassword from './resetPassword'
import configuration from './configuration'
import assistant from './assistant'

const API = {
  assistant,
  auth,
  products,
  trade,
  branchOffice,
  category,
  city,
  state,
  country,
  sections,
  loyalty,
  user,
  address,
  inventory,
  bill,
  csv,
  log,
  relation,
  profession,
  persons,
  topic,
  business,
  resetPassword,
  configuration
}

export default API
