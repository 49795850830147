import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  InputNumber,
  DatePicker,
  Layout
} from 'antd'
import API from '../../../../../api/index'
import Header from '../../../../Header'
import moment from 'moment'

const { TextArea } = Input
const { Content } = Layout
const dateFormat = 'YYYY/MM/DD'

const ProductsUpdate = (props) => {
  const id = props.match.params.id
  const branch = props.match.params.branch
  const trade = props.match.params.trade
  const [errorBranch, setErrorBranch] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [errorCategory, setErrorCategory] = React.useState(undefined)
  const [idcategory, setIdcategory] = React.useState([])
  const [buy_site, setBuy_site] = React.useState(undefined)
  const [errorBuySite, setErrorBuySite] = React.useState(undefined)
  const [buy_online, setBuy_online] = React.useState(undefined)
  const [errorBuyOnline, setErrorBuyOnline] = React.useState(undefined)
  const [price, setPrice] = React.useState(undefined)
  const [errorPrice, setErrorPrice] = React.useState(undefined)
  const [category, setCategory] = React.useState([])
  const [namebranch, setNamebranch] = React.useState(undefined)
  const [imageList, setImageList] = React.useState([])
  const [is_promotion, setIs_promotion] = React.useState(undefined)
  const [errorPromotion, setErrorPromotion] = React.useState(undefined)
  const [discount, setDiscount] = React.useState(undefined)
  const [expiration_date, setExpiration_date] = React.useState(undefined)
  const [errorExpirationDate, setErrorExpirationDate] = React.useState(undefined)
  const [price_discount, setPrice_discount] = React.useState(undefined)
  const [errorPriceDiscount, setErrorPriceDiscount] = React.useState(undefined)
  const [alert, setAlert] = React.useState(false)
  const [confirmation, setConfirmation] = React.useState(false)

  const onBack = () => {
    setRedirect(`/productsmerchants/${branch}/${trade}`)
  }

  const handleAlert = () =>  {
    setAlert(false)
    setConfirmation(true)
  }

  const handleAlertNot = () =>  {
    setAlert(false)
    setConfirmation(false)
  }

  const handlePriceDiscount = (value) =>  {
    const discount = ((price - value) * 100) / price
    setPrice_discount(value)
    setDiscount(discount)
  }
  
  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const idCategory = async () => {
    const idcategory = await API.category.list()
    setCategory(idcategory)
  }

  const idBranch = async () => {
    const idBranch = await API.branchOffice.detail(branch)
    setNamebranch(idBranch.name)
  }

  const onSubmit = async () => {
    clearErrors()
    let image = []
    if (imageList) {
      const images = imageList
      for (let index = 0; index < images.length; index++) {
        if (images[index].thumbUrl) {
          const element = images[index].thumbUrl
          image.push(element)
        } else {
          image.push(images[index].url)
        }
      }
    }
    if (discount >= 40 && confirmation === false) {
      setAlert(true)
    } else if (discount < 40 || confirmation === true || discount === undefined) {
      const response = await API.products.update(id , {
        name: name,
        description: description,
        idcategory: idcategory,
        idBranch: branch,
        price_discount: price_discount,
        buy_online: buy_online,
        buy_site: buy_site,
        price: price,
        imageList: image,
        expiration_date: expiration_date,
        discount: discount,
        is_promotion: is_promotion
      })
      if (response.ok) {
        Modal.success({
          title: 'Actualización de una sección',
          content: `La sección ${name} fue actualizada exitosamente`
        })
        clearErrors()
      }
      if (response.errors) {
        makeErrors(response.errors)
      }
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.idBranch) {
      setErrorBranch(error.idBranch.message)      
    }
    if (error.buy_online) {
      setErrorBuyOnline(error.buy_online.message)
    }
    if (error.buy_site) {
      setErrorBuySite(error.buy_site.message)
    }
    if (error.idcategory) {
      setErrorCategory(error.idcategory.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
    if (error.expiration_date) {
      setErrorExpirationDate(error.expiration_date.message)
    }
    if (error.is_promotion) {
      setErrorPromotion(error.is_promotion.message)
    }
    if (error.price) {
      setErrorPrice(error.price.message)
    }
    if (error.price_discount) {
      setErrorPriceDiscount(error.price_discount.message)
    }
  }

  const clearErrors = () => {
    setErrorBranch(undefined)
    setErrorBuyOnline(undefined)
    setErrorBuySite(undefined)
    setErrorCategory(undefined)
    setErrorDescription(undefined)
    setErrorExpirationDate(undefined)
    setErrorName(undefined)
    setErrorPrice(undefined)
    setErrorPromotion(undefined)
    setErrorPriceDiscount(undefined)
    setAlert(false)
    setConfirmation(false)
  }

  const getProduct = async (id) => {
    const product = await API.products.detail(id)
    setName(product.name)
    if (product.image) {
      let imageList = []
      const images = product.image
      for (let index = 0; index < images.length; index++) {
        const base64 = images[index];
        imageList.push({
          uid: index + 1,
          name: base64.split('/')[5],
          url: base64
        })
      }
      setImageList(imageList)
    }
    if (product.buy_online) {
      setBuy_online('true')
    } else {
      setBuy_online('false')
    }
    if (product.buy_site) {
      setBuy_site('true')
    } else {
      setBuy_site('false')
    }
    setIdcategory(product.idcategory)
    setDescription(product.description)
    setPrice(product.price)
    if (product.is_promotion === true) {
      setIs_promotion('true')
      setDiscount(product.discount)
      setExpiration_date(moment(product.expiration_date, dateFormat))
      setPrice_discount(product.price_discount)
    } else {
      setIs_promotion('false')
    }
  }

  const beforeUpload = () => {
    return false
  }

  const handleChangeUpload = (info) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-4);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    })
    fileList = fileList.filter((file) => {
      if (file.response) {
        return file.response.status === 'success'
      }
      return true
    })
    setImageList(fileList )
  }

  React.useState(() => {
    idCategory()
    idBranch()
    getProduct(id)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const Category = createRow(category)
  let active
  if (imageList.length === 4) {
    active = false
  } else {
    active = true
  }
  let promotion
  if (is_promotion === 'true') {
    promotion = true
  } else {
    promotion = false
  }

  return (
    <Layout className='layout'>
      <Header item='branch' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualización del producto</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              {alert && (
                <Modal 
                  title='Alerta'
                  visible={alert}
                  onOk={() => handleAlert()}
                  onCancel={() => handleAlertNot()}
                  okText='Continuar'
                  cancelText='Cancelar'
                >
                  <p>Producto con mas del 40% de descuento</p>
                  <p>¿Esta seguro continuar?</p>
                </Modal>
              )}
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese nombre del producto'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item>
                  <Upload
                    name='file'
                    listType='picture'
                    beforeUpload={() => beforeUpload()}
                    onChange={(infoList) => handleChangeUpload(infoList)}
                    fileList={imageList}
                    multiple
                  >
                  {active ? (
                    <Button><Icon type='upload' /> Seleccionar imagen</Button> 
                  ) : (
                    <Button disabled><Icon type='upload' /> Seleccionar imagen</Button>
                  )}
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    placeholder='Ingrese una breve descripción del producto'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    row={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categoría del producto'
                  validateStatus={errorCategory && ('error')}
                  help={errorCategory}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    onChange={(value) => setIdcategory(value)}
                    value={idcategory}
                    placeholder='Escoja una o varias categorias'
                    >
                    {Category}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Sucursal del producto'
                  validateStatus={errorBranch && ('error')}
                  help={errorBranch}
                >
                  <Input
                    disabled
                    value={namebranch}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Se puede comprar en linea?'
                  validateStatus={errorBuyOnline && ('error')}
                  help={errorBuyOnline}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setBuy_online(value)}
                    value={buy_online}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Se puede comprar directamente en el local?'
                  validateStatus={errorBuySite && ('error')}
                  help={errorBuySite}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setBuy_site(value)}
                    value={buy_site}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Precio'
                  validateStatus={errorPrice && ('error')}
                  help={errorPrice}
                >
                  <InputNumber
                    min={0}
                    value={price}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => setPrice(value)}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Col>
              {price ? (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='¿El producto esta en promoción?'
                    validateStatus={errorPromotion && ('error')}
                    help={errorPromotion}
                  >
                    <Select
                      type='text'
                      style={{ width: '100%' }}
                      onChange={(value) => setIs_promotion(value)}
                      value={is_promotion}
                      placeholder='Seleccione su respuesta'
                    >
                      <Select.Option value='true'>Si</Select.Option>
                      <Select.Option value='false'>No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='¿El producto esta en promoción?'
                    validateStatus={errorPromotion && ('error')}
                    help={errorPromotion}
                  >
                    <Select
                      disabled
                      type='text'
                      style={{ width: '100%' }}
                      onChange={(value) => setIs_promotion(value)}
                      value={is_promotion}
                      placeholder='Seleccione su respuesta'
                    >
                      <Select.Option value='true'>Si</Select.Option>
                      <Select.Option value='false'>No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={7} offset={1}>
                {(promotion && price)? (
                  <Form.Item
                    label='Precio con descuento'
                    validateStatus={errorPriceDiscount && ('error')}
                    help={errorPriceDiscount}
                  >
                    <InputNumber
                      min={0}
                      max={price}
                      value={price_discount}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => handlePriceDiscount(value)}
                      style={{ width: 150 }}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Precio con descuento'
                    validateStatus={errorPriceDiscount && ('error')}
                    help={errorPriceDiscount}
                  >
                    <InputNumber
                      min={0}
                      disabled
                      value={price_discount}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => handlePriceDiscount(value)}
                      style={{ width: 150 }}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={7} offset={1}>
                  <Form.Item
                    label='Descuento'
                  >
                  <InputNumber
                    disabled
                    min={0}
                    max={100}
                    value={discount}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    onChange={(value) => handlePriceDiscount(value)}
                    style={{ width: 150 }}
                  />
                  </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                {promotion ? (
                  <Form.Item
                    label='Fecha de vencimiento de la promoción'
                    validateStatus={errorExpirationDate && ('error')}
                    help={errorExpirationDate}
                  >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    value={expiration_date}
                    format={dateFormat}
                    onChange={(value) => setExpiration_date(value)} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Fecha de vencimiento de la promoción'
                  >
                  <DatePicker
                    disabled
                    placeholder='Seleccione una fecha'
                    value={expiration_date}
                    format={dateFormat}
                    onChange={(value) => setExpiration_date(value)} />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default ProductsUpdate
