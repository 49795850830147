import React, { Component } from 'react'
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom'
import Login from './components/Login'
import Authenticated from './Authenticated'
import Home from './components/Home'
import ResetPassword from './components/user/ResetPassword'
import ChangePassword from './components/user/ChangePassword'
import Products from './components/products/Products'
import ProductsCreate from './components/products/Create'
import ProductsDetail from './components/products/Detail'
import ProductsUpdate from './components/products/Update'
import Trade from './components/trade/Trade'
import TradeCreate from './components/trade/Create'
import TradeDetail from './components/trade/Detail'
import TradeUpdate from './components/trade/Update'
import Branch from './components/trade/branch/branch'
import BranchCreate from './components/trade/branch/Create'
import BranchDetail from './components/trade/branch/Detail'
import BranchUpdate from './components/trade/branch/Update'
import BranchSales from './components/trade/branch/Sales'
import Category from './components/category/Categories'
import CategoryCreate from './components/category/Create'
import CategoryDetail from './components/category/Detail'
import CategoryUptate from './components/category/Update'
import Sections from './components/sections/sections'
import SectionsCreate from './components/sections/Create'
import SectionsDetail from './components/sections/Detail'
import SectionsUpdate from './components/sections/Update'
import Loyalty from './components/loyalty/Loyalty'
import LoyaltyCreate from './components/loyalty/Create'
import LoyaltyDetail from './components/loyalty/Detail'
import LoyaltyUpdate from './components/loyalty/Update'
import LoyaltyTrade from './components/loyalty/trade/Trade'
import LoyaltyTradeCreate from './components/loyalty/trade/Relate'
import User from './components/user/User'
import UserCreate from './components/user/Create'
import UserDetail from './components/user/Detail'
import UserUpdate from './components/user/Update'
import BillUserDetail from './components/user/BillDetail'
import Inventory from './components/products/inventory/Inventory'
import InventoryCreate from './components/products/inventory/Create'
import InventoryUpdate from './components/products/inventory/Update'
import Sales from './components/sales/Sales'
import SalesDetail from './components/sales/Detail'
import Log from './components/log/Log'
import Backups from './components/backups/Backups'
import { getItem } from './api/utils'
import Profession from './components/profession/Profession'
import ProfessionCreate from './components/profession/Create'
import ProfessionDetail from './components/profession/Detail'
import ProfessionUpdate from './components/profession/Update'
import Persons from './components/profession/persons/Persons'
import PersonsCreate from './components/profession/persons/Create'
import PersonsDetail from './components/profession/persons/Detail'
import PersonsUpdate from './components/profession/persons/Update'
import Topic from './components/topic/Topic'
import TopicCreate from './components/topic/Create'
import TopicDetail from './components/topic/Detail'
import TopicUpdate from './components/topic/Update'
import Business from './components/topic/business/Business'
import BusinessCreate from './components/topic/business/Create'
import BusinessDetail from './components/topic/business/Detail'
import BusinessUpdate from './components/topic/business/Update'

import UserMerchant from './components/user/userMerchant/User'
import UserMerchantDetail from './components/user/userMerchant/Detail'
import UserMerchantCreate from './components/user/userMerchant/Create'
import UserMerchantUpdate from './components/user/userMerchant/Update'
import TradeMerchant from './components/trade/tradeMerchant/Trade'
import TradeMerchantDetail from './components/trade/tradeMerchant/Detail'
import TradeOperatorList from './components/trade/list/Operator'
import TradeOperatorCreate from './components/trade/create/Operator'
import BranchMerchant from './components/trade/tradeMerchant/branch/branch'
import BranchMerchantDetail from './components/trade/tradeMerchant/branch/Detail'
import BranchMerchantCreate from './components/trade/tradeMerchant/branch/Create'
import BranchMerchantUpdate from './components/trade/tradeMerchant/branch/Update'
import Billmerchant from './components/trade/tradeMerchant/branch/SalesDetail'
import BranchMerchantSales from './components/trade/tradeMerchant/branch/Sales'
import ProductsMerchant from './components/trade/tradeMerchant/branch/products/Products'
import ProductsMerchantCreate from './components/trade/tradeMerchant/branch/products/Create'
import ProductsMerchantDetail from './components/trade/tradeMerchant/branch/products/Detail'
import ProductsMerchantUpdate from './components/trade/tradeMerchant/branch/products/Update'
import InventoryMerchant from './components/trade/tradeMerchant/branch/products/inventory/Inventory'
import InventoryMerchantCreate from './components/trade/tradeMerchant/branch/products/inventory/Create'
import InventoryMerchantUpdate from './components/trade/tradeMerchant/branch/products/inventory/Update'
import BranchOperator from './components/trade/tradeMerchant/branch/branchOperator'
import BranchOperatorDetail from './components/trade/tradeMerchant/branch/DetailOperator'
import Configuration from './components/configuration/Configuration'
import Assistant from './components/topic/assistant/Assistant'
import AssistantCreate from './components/topic/assistant/Create'
import AssistantUpdate from './components/topic/assistant/Update'
import ReportIndex from './components/report/index'

import './App.css'

class App extends Component {
  constructor (props) {
    super(props)
    this.state = {
      type: getItem('type')
    }
  }
  render () {
    return (
      <Router>
        <div>
          <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/reset/password/:token' component={ChangePassword} />
            <Route exact path='/reset/password' component={ResetPassword} />
            <Authenticated exact path='/home' component={Home} />
          </Switch>
          {this.state.type === 'EntidadPromotora' && (
            <Switch>
              <Authenticated exact path='/user' component={User} />
              <Authenticated exact path='/user/create' component={UserCreate} />
              <Authenticated exact path='/user/detail/:id' component={UserDetail} />
              <Authenticated exact path='/user/update/:id' component={UserUpdate} />
              <Authenticated exact path='/topic/create' component={TopicCreate} />
              <Authenticated exact path='/topic/detail/:id' component={TopicDetail} />
              <Authenticated exact path='/topic/update/:id' component={TopicUpdate} />
              <Authenticated exact path='/topic' component={Topic} />
              <Authenticated exact path='/topic/assistant/:topic' component={Assistant} />
              <Authenticated exact path='/topic/assistant/:topic/create' component={AssistantCreate} />
              <Authenticated exact path='/assistant/update/:assistant' component={AssistantUpdate} />
              <Authenticated exact path='/business/list/:id' component={Business} />
              <Authenticated exact path='/business/create/:topic' component={BusinessCreate} />
              <Authenticated exact path='/business/detail/:id/:topic' component={BusinessDetail} />
              <Authenticated exact path='/business/update/:id/:topic' component={BusinessUpdate} />
              <Authenticated exact path='/trade/create' component={TradeCreate} />
              <Authenticated exact path='/trade/detail/:id' component={TradeDetail} />
              <Authenticated exact path='/trade/update/:id' component={TradeUpdate} />
              <Authenticated exact path='/trade/:id/operator/list' component={TradeOperatorList} />
              <Authenticated exact path='/trade/:id/operator/create' component={TradeOperatorCreate} />
              <Authenticated exact path='/trade' component={Trade} />
            </Switch>
          )}
          {(this.state.type === 'Administrador' || this.state.type === 'supervisor') && (
            <Switch>
              <Authenticated exact path='/user/create' component={UserCreate} />
              <Authenticated exact path='/user/detail/:id' component={UserDetail} />
              <Authenticated exact path='/user/update/:id' component={UserUpdate} />
              <Authenticated exact path='/bill/user/detail/:id' component={BillUserDetail} />
              <Authenticated exact path='/user' component={User} />
              <Authenticated exact path='/products/create/:branch' component={ProductsCreate} />
              <Authenticated exact path='/product/detail/:id' component={ProductsDetail} />
              <Authenticated exact path='/product/update/:id' component={ProductsUpdate} />
              <Authenticated exact path='/products/:branch' component={Products} />
              <Authenticated exact path='/trade/create' component={TradeCreate} />
              <Authenticated exact path='/trade/detail/:id' component={TradeDetail} />
              <Authenticated exact path='/trade/update/:id' component={TradeUpdate} />
              <Authenticated exact path='/trade/:id/operator/list' component={TradeOperatorList} />
              <Authenticated exact path='/trade/:id/operator/create' component={TradeOperatorCreate} />
              <Authenticated exact path='/trade' component={Trade} />
              <Authenticated exact path='/branch/create/:id' component={BranchCreate} />
              <Authenticated exact path='/branch/detail/:id' component={BranchDetail} />
              <Authenticated exact path='/branch/update/:id' component={BranchUpdate} />
              <Authenticated exact path='/branch/sales/:idbranch/:idtrade' component={BranchSales} />
              <Authenticated exact path='/bill/detail/:id/:idtrade' component={SalesDetail} />
              <Authenticated exact path='/branches/list/:branch' component={Branch} />
              <Authenticated exact path='/category/create' component={CategoryCreate} />
              <Authenticated exact path='/category/detail/:id' component={CategoryDetail} />
              <Authenticated exact path='/category/update/:id' component={CategoryUptate} />
              <Authenticated exact path='/category' component={Category} />
              <Authenticated exact path='/sections/create' component={SectionsCreate} />
              <Authenticated exact path='/sections/detail/:id' component={SectionsDetail} />
              <Authenticated exact path='/sections/update/:id' component={SectionsUpdate} />
              <Authenticated exact path='/sections' component={Sections} />
              <Authenticated exact path='/loyalty/create' component={LoyaltyCreate} />
              <Authenticated exact path='/loyalty/detail/:id' component={LoyaltyDetail} />
              <Authenticated exact path='/loyalty/update/:id' component={LoyaltyUpdate} />
              <Authenticated exact path='/Loyalty/trade/:loyalty' component={LoyaltyTrade} />
              <Authenticated exact path='/loyalty/tradecreate/:id' component={LoyaltyTradeCreate} />
              <Authenticated exact path='/loyalty' component={Loyalty} />
              <Authenticated exact path='/inventory/:trade/create/:id/:name' component={InventoryCreate} />
              <Authenticated exact path='/inventory/update/:id/:name' component={InventoryUpdate} />
              <Authenticated exact path='/inventory/:branch/:id/:name' component={Inventory} />
              <Authenticated exact path='/sales/detail/:id' component={SalesDetail} />
              <Authenticated exact path='/sales' component={Sales} />
              <Authenticated exact path='/backups' component={Backups} />
              <Authenticated exact path='/log' component={Log} />
              <Authenticated exact path='/configuration' component={Configuration} />
              <Authenticated exact path='/profession/create' component={ProfessionCreate} />
              <Authenticated exact path='/profession/detail/:id' component={ProfessionDetail} />
              <Authenticated exact path='/profession/update/:id' component={ProfessionUpdate} />
              <Authenticated exact path='/profession' component={Profession} />
              <Authenticated exact path='/persons/list/:id' component={Persons} />
              <Authenticated exact path='/persons/create' component={PersonsCreate} />
              <Authenticated exact path='/persons/detail/:id/:profession' component={PersonsDetail} />
              <Authenticated exact path='/persons/update/:id/:profession' component={PersonsUpdate} />
              <Authenticated exact path='/topic/create' component={TopicCreate} />
              <Authenticated exact path='/topic/detail/:id' component={TopicDetail} />
              <Authenticated exact path='/topic/update/:id' component={TopicUpdate} />
              <Authenticated exact path='/topic' component={Topic} />
              <Authenticated exact path='/topic/assistant/:topic' component={Assistant} />
              <Authenticated exact path='/topic/assistant/:topic/create' component={AssistantCreate} />
              <Authenticated exact path='/assistant/update/:assistant' component={AssistantUpdate} />
              <Authenticated exact path='/business/list/:id' component={Business} />
              <Authenticated exact path='/business/create/:topic' component={BusinessCreate} />
              <Authenticated exact path='/business/detail/:id/:topic' component={BusinessDetail} />
              <Authenticated exact path='/business/update/:id/:topic' component={BusinessUpdate} />
              <Authenticated exact path='/branch/report/:id' component={ReportIndex} />
            </Switch>
          )}
          {this.state.type === 'Empresario' && (
            <Switch>
              <Authenticated exact path='/usermerchant/create' component={UserMerchantCreate} />
              <Authenticated exact path='/usermerchant/detail/:id' component={UserMerchantDetail} />
              <Authenticated exact path='/usermerchant/update/:id' component={UserMerchantUpdate} />
              <Authenticated exact path='/usermerchant' component={UserMerchant} />
              <Authenticated exact path='/productsmerchant/create/:branch' component={ProductsCreate} />
              <Authenticated exact path='/productmerchant/detail/:product/:branch/:trade' component={ProductsMerchantDetail} />
              <Authenticated exact path='/productmerchant/update/:id/:branch/:trade' component={ProductsMerchantUpdate} />
              <Authenticated exact path='/productsmerchants/:id/:trade' component={ProductsMerchant} />
              <Authenticated exact path='/trademerchant/detail/:id' component={TradeMerchantDetail} />
              <Authenticated exact path='/trademerchant' component={TradeMerchant} />
              <Authenticated exact path='/branchmerchant/create/:id' component={BranchMerchantCreate} />
              <Authenticated exact path='/branchmerchant/detail/:id' component={BranchMerchantDetail} />
              <Authenticated exact path='/branchmerchant/update/:id' component={BranchMerchantUpdate} />
              <Authenticated exact path='/branchmerchant/sales/:idbranch/:idtrade' component={BranchMerchantSales} />
              <Authenticated exact path='/billmerchant/detail/:id' component={SalesDetail} />
              <Authenticated exact path='/branchesmerchant/list/:branch' component={BranchMerchant} />
              <Authenticated exact path='/category/create' component={CategoryCreate} />
              <Authenticated exact path='/category/detail/:id' component={CategoryDetail} />
              <Authenticated exact path='/category/update/:id' component={CategoryUptate} />
              <Authenticated exact path='/category' component={Category} />
              <Authenticated exact path='/inventorymerchant/create/:id/:name/:branch/:trade' component={InventoryMerchantCreate} />
              <Authenticated exact path='/inventorymerchant/update/:id/:name/:branch/:trade' component={InventoryMerchantUpdate} />
              <Authenticated exact path='/inventorymerchant/:id/:name/:branch/:trade' component={InventoryMerchant} />
              <Authenticated exact path='/branch/report/:id' component={ReportIndex} />
            </Switch>
          )}
          {this.state.type === 'operador' && (
            <Switch>
              <Authenticated exact path='/productsmerchant/create/:id/:trade' component={ProductsMerchantCreate} />
              <Authenticated exact path='/productmerchant/detail/:product/:branch/:trade' component={ProductsMerchantDetail} />
              <Authenticated exact path='/productmerchant/update/:id/:branch/:trade' component={ProductsMerchantUpdate} />
              <Authenticated exact path='/productsmerchants/:id/:trade' component={ProductsMerchant} />
              <Authenticated exact path='/branchmerchant/detail/:id' component={BranchOperatorDetail} />
              <Authenticated exact path='/branchmerchant/sales/:idbranch/:idtrade' component={BranchMerchantSales} />
              <Authenticated exact path='/billmerchant/detail/:id/:idtrade' component={Billmerchant} />
              <Authenticated exact path='/branchesoperator' component={BranchOperator} />
              <Authenticated exact path='/category/create' component={CategoryCreate} />
              <Authenticated exact path='/category/detail/:id' component={CategoryDetail} />
              <Authenticated exact path='/category/update/:id' component={CategoryUptate} />
              <Authenticated exact path='/category' component={Category} />
              <Authenticated exact path='/inventorymerchant/create/:id/:name/:branch/:trade' component={InventoryMerchantCreate} />
              <Authenticated exact path='/inventorymerchant/update/:id/:name/:branch/:trade' component={InventoryMerchantUpdate} />
              <Authenticated exact path='/inventorymerchant/:id/:name/:branch/:trade' component={InventoryMerchant} />
              <Authenticated exact path='/branch/report/:id' component={ReportIndex} />
            </Switch>
          )}
        </div>
      </Router>
    )
  }
}

export default App
