import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Layout
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'
import TextArea from 'antd/lib/input/TextArea'

const Content = Layout.Content

const PersonsDetail = (props) => {
  const [persons, setPersons] = React.useState({})
  const [redirect, setRedirect] = React.useState(undefined)
  const id = props.match.params.id
  const profession = props.match.params.profession

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo','Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + 
    getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + 
    ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  const getPersons = async () => {
    const persons = await API.persons.detail(id)
    setPersons(persons)
  }

  React.useEffect(() => {
    getPersons()
  }, [])

  const onBack = () => {
    setRedirect(`/persons/list/${profession}`)
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header item='profession' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle de la persona</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
           layout='vertical'
          >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={persons.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Imagen'
                >
                  <img
                    alt={persons.name}
                    src={persons.image}
                    style={{ maxWidth: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='País'
                >
                  <Input
                    disabled
                    value={persons.countryName}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Estado'
                >
                  <Input
                    disabled
                    value={persons.stateName}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Ciudad'
                >
                  <Input
                    type='text'
                    disabled
                    value={persons.cityName}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Email'
                >
                  <Input
                    disabled
                    value={persons.email}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Teléfono'
                >
                  <Input
                    disabled
                    value={persons.cellphone}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Profesión'
                >
                  <Input
                    type='text'
                    disabled
                    value={persons.nameProfession}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Dirección'
                >
                  <Input
                    type='text'
                    disabled
                    value={persons.address}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Descripción'
                >
                  <TextArea
                    type='text'
                    disabled
                    value={persons.description}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default PersonsDetail