import React from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import {
  Table,
  Row,
  Col,
  Tag,
  Icon
} from 'antd'
import Action from './Action'

const TradeList = (props) => {
  const [tradeAll, setTradeAll] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'NIT', dataIndex: 'nit', key: 'nit'},
    {title: 'Nombre', dataIndex: 'trade_name', key: 'trade_name'},
    {title: 'Soporte', dataIndex: 'supportType', key: 'supportType'},
    {title: 'Tarjeta Crédito', dataIndex: 'creditCards',key: 'creditCards',
      render: (text, record) => {
        return (
          <span>
            {record.creditCards ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    }, {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewTrade(record._id)}>Ver detalle</Tag>
            <Tag color='cyan' onClick={() => redirectViewBranch(record._id)}>Ver Sucursal</Tag>
            <Action trade={record} />
          </span>
        )
      }
    }
  ]

  const getTrade = async (page) => {
    const trade = await API.trade.listMerchant(page)
    setTradeAll(trade)
  }

  const handleViewTrade = (id) => {
    setRedirect(`/trademerchant/detail/${id}`)
  }

  const redirectViewBranch = (id) => {
    setRedirect(`/branchesmerchant/list/${id}`)
  }

  const handlePrevTrade = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      getTrade(pageTemp)
    }
  }

  const handleNextTrade = () => {
    if (tradeAll.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      getTrade(pageTemp)
    }
  }

  React.useEffect(() => {
    getTrade(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de comercios</strong></h3>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={tradeAll}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrevTrade()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li
            className={tradeAll.length >= 10 ? ('ant-pagination-next') 
            : ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNextTrade()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TradeList
