import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Layout,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Modal,
  Select,
  DatePicker
} from 'antd'
import Header from '../Header'
import moment from 'moment'
import API from '../../api/index'

const { Content } = Layout
const dateFormat = 'YYYY/MM/DD'

const TopicDetail = (props) => {
  const id = props.match.params.id
  const [name, setName] = React.useState(undefined)
  const [name_verify, setName_verify] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [typePayment, setTypePayment] = React.useState('free')
  const [errorTypePayement, setErrorTypePayment] = React.useState('')
  const [startDate, setStartDate] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [errorStartDate, setErrorStartDate] = React.useState('')
  const [errorEndDate, setErrorEndDate] = React.useState('')
  const [redirect, setRedirect] = React.useState(undefined)

  const onBack = () => {
    setRedirect('/topic')
  }

  const onSubmit = async () => {
    clearErrors()
    let nameTrue
    if (name_verify !== name) {
      nameTrue = name
    }
    const response = await API.topic.update(id, {
      name: nameTrue,
      image: image,
      type: typePayment,
      start_date: startDate,
      end_date: endDate
    })
    if (response.ok) {
      Modal.success({
        title: 'Actualización de un tema',
        content: `El tema ${name} fue actualizado exitosamente`
      })
      clearErrors()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.type) {
      setErrorTypePayment(error.type.message)
    }
    if (error.start_date) {
      setErrorStartDate(error.start_date.message)
    }
    if (error.end_date) {
      setErrorEndDate(error.end_date.message)
    }
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorImage(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const getTopic = async () => {
    const response = await API.topic.detail(id)
    setName(response.name)
    setName_verify(response.name)
    setImage(response.image)
    setTypePayment(response.type)
    if (response.start_date) {
      setStartDate(moment(response.start_date, dateFormat))
    }
    if (response.end_date) {
      setEndDate(moment(response.end_date, dateFormat))
    }
  }

  React.useState(() => {
    getTopic()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualización de un tema</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese nombre del tema'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}>{image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tipo*'
                  validateStatus={errorTypePayement && ('error')}
                  help={errorTypePayement}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione un tipo'
                    onChange={(value) => setTypePayment(value)}
                    value={typePayment}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    <Select.Option value='free'>Gratis</Select.Option>
                    <Select.Option value='payment'>Pago</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de inicio*'
                  validateStatus={errorStartDate && ('error')}
                  help={errorStartDate}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    format={dateFormat}
                    value={startDate}
                    onChange={(value) => setStartDate(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de finalización*'
                  validateStatus={errorEndDate && ('error')}
                  help={errorEndDate}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    format={dateFormat}
                    value={endDate}
                    onChange={(value) => setEndDate(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default TopicDetail
