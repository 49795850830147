import axios from 'axios'
import config from './config'

const URL = `${config.URL}/category`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/${page}`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else if (status === false) {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/changes/status`, {
    id: id,
    status: status
  }, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const result = await axios.get(`${URL}/search/admin/${page}/${name}`, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/category/${id}`, config.configWithToken())
  return result.data
}

export async function ListTrue () {
  const result = await axios.get(`${URL}/true/list/true`, config.configWithToken())
  return result.data
}

export async function ListLoyalty (id) {
  const result = await axios.get(`${URL}/loyalty/${id}`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  detail,
  update,
  change,
  search,
  deletes,
  ListTrue,
  ListLoyalty
}
