import React from 'react'
import { Tag } from 'antd'
import API from '../../../api/index'


const Action = (props) => {
  const business = props.business
  const [is_active, setIs_active] = React.useState(props.business.is_active)

  const handleAction = async () => {
    await API.business.change(business._id, !is_active)
    setIs_active(!is_active)
  }

  let color = 'green'
  let text = 'Activar'
  if (is_active) {
    color = 'red'
    text = 'Desactivar'
  }

  return (
    <Tag color={color} onClick={() => handleAction()}>{text}</Tag>
  )
}
export default Action