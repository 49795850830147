import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout
} from 'antd'
import API from '../../../api/index'
import Header from '../../Header'

const { Content } = Layout

const BusinessCreate = (props) => {
  const id = props.match.params.topic
  const [topic, setTopic] = React.useState({})
  const [redirect, setRedirect] = React.useState(undefined)
  const [trades, setTrades] = React.useState([])
  const [trade, setTrade] = React.useState(undefined)
  const [errorTrade, setErrorTrade] = React.useState('')
  const [users, setUsers] = React.useState([])
  const [user, setUser] = React.useState(undefined)
  const [errorUser, setErrorUser] = React.useState('')

  const onSubmit = async () => {
    clearErrors()
    const response = await API.business.create({
      topic: id,
      trade: trade,
      user: user
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de un negocío',
        content: `El negocío fue creado exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.trade) {
      setErrorTrade(error.trade.message)
    }
    if (error.user) {
      setErrorUser(error.user.message)
    }
  }

  const clearFields = () => {
    setTrade('')
    setUser('')
    clearErrors()
  }

  const clearErrors = () => {
    setErrorTrade('')
    setErrorUser('')
  }

  const users_by_trade = async (trade_id) => {
    const response = await API.user.users_by_trade(trade_id)
    if (!response.errors && response.length >= 0) {
      setUsers(response)
    } else {
      setUsers([])
    }
  }

  const getTrades = async() => {
    const response = await API.trade.ListTrue()
    setTrades(response)
  }

  const onBack = async () => {
    setRedirect(`/business/list/${id}`)
  }

  const get_topic = async () => {
    const response = await API.topic.detail(id)
    setTopic(response)
  }

  React.useEffect(() => {
    getTrades()
    get_topic()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout' >
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Comercio en la feria</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre de la feria'
                >
                  <Input
                    type='text'
                    disabled
                    value={topic ? topic.name : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Comercio*'
                  validateStatus={errorTrade && ('error')}
                  help={errorTrade}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un comercio'
                    onChange={(value) => {
                      setTrade(value)
                      users_by_trade(value)
                    }}
                    value={trade}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {trades.map((trade, index) => (
                      <Select.Option value={trade._id} key={index}>{trade.trade_name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Empresario*'
                  validateStatus={errorUser && ('error')}
                  help={errorUser}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un empresario'
                    onChange={(value) => {
                      setUser(value)
                    }}
                    value={user}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {users.map((user, index) => (
                      <Select.Option value={user._id} key={index}>{user.fullName}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default BusinessCreate
