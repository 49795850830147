import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Select,
  Layout,
  Button,
  InputNumber,
  Upload,
  Icon,
  Checkbox
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout

const TradeCreate = (props) => {
  const [nit, setNit] = React.useState(undefined)
  const [errorNit, setErrorNit] = React.useState(undefined)
  const [trade_name, setTrade_name] = React.useState(undefined)
  const [errorTradeName, setErrorTradeName] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [email, setEmail] = React.useState(undefined)
  const [errorEmail, setErrorEmail] = React.useState(undefined)
  const [url, setUrl] = React.useState(undefined)
  const [errorUrl, setErrorUrl] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [address, setAddress] = React.useState(undefined)
  const [errorAddress,setErrorAddress] = React.useState(undefined)
  const [valuePointByBuy, setValuePointByBuy] = React.useState(undefined)
  const [errorValuePointByBuy, setErrorValuePointByBuy] = React.useState(undefined)
  const [maxValuePoint, setMaxValuePoint] = React.useState(undefined)
  const [errorMaxValuePoint, setErrorMaxValuePoint] = React.useState(undefined)
  const [creditCards, setCreditCards] = React.useState(undefined)
  const [errorCreditCards, setErrorCreditCards] = React.useState(undefined)
  const [support_type, setSupport_type] = React.useState(undefined)
  const [errorSupportType, setErrorSupportType] = React.useState(undefined)
  const [categories, setCategories] = React.useState([])
  const [errorCategory, setErrorCategory] = React.useState(undefined)
  const [idCategory, setIdCategory] = React.useState([])
  const [user, setUser] = React.useState(undefined)
  const [errorUser, setErrorUser] = React.useState(undefined)
  const [listUser, setListUser] = React.useState([])
  const [band, setBand] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [idUniquePay, setIdUniquePay] = React.useState(undefined)
  const [keyService, setKeyService] = React.useState(undefined)
  const [codeService, setCodeService] = React.useState(undefined)
  const [codeRoute, setCodeRoute] = React.useState(undefined)
  const [userChecker, setUserChecker] = React.useState(undefined)
  const [passwordChecker, setPasswordChecker] = React.useState(undefined)
  const [percentajeIva, setPercentajeIva] = React.useState(0)
  const [isHome, setIsHome] = React.useState(undefined)
  const [description, setDescription] = React.useState('')
  const [errorDescription, setErrorDescription] = React.useState('')

  const onBack = () => {
    setRedirect('/trade')
  }

  const createRowUser = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.first_name + ' ' + value.last_name}</Select.Option>)
    })
    return row
  }

  const User = async () => {
    const users = await API.user.listUserTrade()
    if (users.errors) {
      setErrorUser(users.errors.user.message)
      setBand(false)
    } else {
      setListUser(users)
      setBand(true)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.trade.create({
      nit: nit,
      trade_name: trade_name,
      cellphone: cellphone,
      email: email,
      url: url,
      address: address,
      valuePointByBuy: valuePointByBuy,
      maxValuePoint: maxValuePoint,
      creditCards: creditCards,
      support_type: support_type,
      image: image,
      user: user,
      categories: categories,
      idUniquePay: idUniquePay,
      codeRoute: codeRoute,
      codeService: codeService,
      keyService: keyService,
      userChecker: userChecker,
      passwordChecker: passwordChecker,
      percentajeIva: percentajeIva,
      isHome: isHome,
      description: description
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de un comercio',
        content: `El comercio ${trade_name} fue creado exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.nit) {
      setErrorNit(error.nit.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
    if (error.trade_name) {
      setErrorTradeName(error.trade_name.message)
    }
    if (error.url) {
      setErrorUrl(error.url.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.valuePointByBuy) {
      setErrorValuePointByBuy(error.valuePointByBuy.message)
    }
    if (error.maxValuePoint) {
      setErrorMaxValuePoint(error.maxValuePoint.message)
    }
    if (error.creditCards) {
      setErrorCreditCards(error.creditCards.message)
    }
    if (error.support_type) {
      setErrorSupportType(error.support_type.message)
    }
    if (error.user) {
      setErrorUser(error.user.message)
    }
    if (error.categories) {
      setErrorCategory(error.categories.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
  }

  const clearFields = () => {
    setTrade_name(undefined)
    setNit(undefined)
    setCellphone(undefined)
    setImage(undefined)
    setEmail(undefined)
    setUrl(undefined)
    setAddress(undefined)
    setValuePointByBuy(undefined)
    setMaxValuePoint(undefined)
    setCreditCards(undefined)
    setSupport_type(undefined)
    setUser(undefined)
    setCategories(undefined)
    setIdUniquePay(undefined)
    setCodeRoute(undefined)
    setCodeService(undefined)
    setKeyService(undefined)
    setUserChecker(undefined)
    setPasswordChecker(undefined)
    setDescription(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorTradeName(undefined)
    setErrorNit(undefined)
    setErrorCellphone(undefined)
    setErrorUrl(undefined)
    setErrorAddress(undefined)
    setErrorEmail(undefined)
    setErrorCreditCards(undefined)
    setErrorSupportType(undefined)
    setErrorImage(undefined)
    setErrorUser(undefined)
    setErrorCategory(undefined)
    setErrorDescription(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const idcategory = async () => {
    const idcategory = await API.category.ListTrue()
    setIdCategory(idcategory)
  }

  React.useEffect(() => {
    User()
    idcategory()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  const category = createRow(idCategory)
  const userC = createRowUser(listUser)
  return (
    <Layout>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nuevo comercio</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button
                onClick={() => onSubmit()}
                disabled={!band}
              >
                Guardar
              </Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='NIT*'
                  validateStatus={errorNit && ('error')}
                  help={errorNit}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el NIT del comercio'
                    onChange={(event) => setNit(event.target.value)}
                    value={nit}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción*'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la descripción'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorTradeName && ('error')}
                  help={errorTradeName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre del comercio'
                    onChange={(event) => setTrade_name(event.target.value)}
                    value={trade_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono*'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el teléfono del comercio'
                    onChange={(event) => setCellphone(event.target.value)}
                    value={cellphone}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Administrador*'
                  validateStatus={errorUser && ('error')}
                  help={errorUser}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una usuario'
                    onChange={(value) => setUser(value)}
                    value={user}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userC}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Email*'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el email del comercio'
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categorías*'
                  validateStatus={errorCategory && ('error')}
                  help={errorCategory}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    onChange={(value) => setCategories(value)}
                    value={categories}
                    placeholder='Escoja una o varias categorias'
                  >
                    {category}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='URL'
                  validateStatus={errorUrl && ('error')}
                  help={errorUrl}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la url del comercio'
                    onChange={(event) => setUrl(event.target.value)}
                    value={url}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección*'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la dirección del comercio'
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Valor de puntos por compra*'
                  validateStatus={errorValuePointByBuy && ('error')}
                  help={errorValuePointByBuy}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    onChange={(value) => setValuePointByBuy(value)}
                    value={valuePointByBuy}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Máximo de puntos a redimir*'
                  validateStatus={errorMaxValuePoint && ('error')}
                  help={errorMaxValuePoint}
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    onChange={(value) => setMaxValuePoint(value)}
                    value={maxValuePoint}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tarjeta crédito*'
                  validateStatus={errorCreditCards && ('error')}
                  help={errorCreditCards}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setCreditCards(value)}
                    value={creditCards}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Id único' : 'Id único*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    name='idUniquePay'
                    placeholder='Ingrese el id único de pago'
                    onChange={(event) => setIdUniquePay(event.target.value)}
                    value={idUniquePay}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Clave de pago' : 'Clave de pago*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    placeholder='Ingrese la clave de pago'
                    onChange={(event) => setKeyService(event.target.value)}
                    value={keyService}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Código de ruta' : 'Código de ruta*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    placeholder='Ingrese la clave de pago'
                    onChange={(event) => setCodeRoute(event.target.value)}
                    value={codeRoute}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Código de servicio' : 'Código de servicio*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    placeholder='Ingrese la clave de pago'
                    onChange={(event) => setCodeService(event.target.value)}
                    value={codeService}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Usuario Verificado V4' : 'Usuario Verificado V4*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    placeholder='Ingrese el Usuario verificado V4'
                    onChange={(event) => setUserChecker(event.target.value)}
                    value={userChecker}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label={creditCards !== 'true' ? 'Clave Verificador V4' : 'Clave Verificador V4*'}
                >
                  <Input
                    disabled={creditCards !== 'true'}
                    type='text'
                    placeholder='Ingrese la Clave verificador V4'
                    onChange={(event) => setPasswordChecker(event.target.value)}
                    value={passwordChecker}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Porcentaje de iva'
                >
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    onChange={(value) => setPercentajeIva(value)}
                    value={percentajeIva}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tipo de soporte*'
                  validateStatus={errorSupportType && ('error')}
                  help={errorSupportType}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    onChange={(value) => setSupport_type(value)}
                    value={support_type}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='Soporte 1'>Soporte 1</Select.Option>
                    <Select.Option value='Soporte 2'>Soporte 2</Select.Option>
                    <Select.Option value='Soporte 3'>Soporte 3</Select.Option>
                    <Select.Option value='Soporte 4'>Soporte 4</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={beforeUpload}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()} />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Publicar en la home*'
                >
                  <Checkbox
                    onChange={() => setIsHome(!isHome)}
                    checked={isHome}
                  >
                    Publicar en la home
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default TradeCreate
