import axios from 'axios'
import config from './config'

const URL = `${config.URL}/state`

export async function list (value) {
  const response = await axios.get(`${URL}/list/active/${value}`, config.configWithToken())
  return response.data
}

export async function detail (id) {
  const response = await axios.get(`${URL}/${id}`, config.configWithToken())
  return response.data
}

export default {
  list,
  detail
}
