import React from 'react'
import PersonsList from './List'
import { Layout } from 'antd'
import Header from '../../Header'

const { Content } = Layout

const Persons = (props) => {
  const id = props.match.params.id

  return (
    <Layout className='layout'>
      <Header item='profession' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <PersonsList id={id} />
        </div>
      </Content>
    </Layout>
  )
}
export default Persons