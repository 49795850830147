import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Button
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const Content = Layout.Content

const  TopicDetail = (props) => {
  const id = props.match.params.id
  const [topic, setTopic] = React.useState({})
  const [start_create, setStart_create] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + 
    ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + 
    ('0' + newDate.getMinutes()).slice(-2)
  }

  const Topic = async () => {
    const topic = await API.topic.detail(id)
    setStart_create(formatDate(topic.start_create))
    setUpdate_date(formatDate(topic.update_date))
    setTopic(topic)
  }

  const onBack = () => {
    setRedirect('/topic')
  }

  React.useEffect(() => {
    Topic()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle de la feria</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Nombre'>
                  <Input
                    disabled
                    value={topic.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Tipo'>
                  <Input
                    disabled
                    value={topic.type === 'premium' ? 'Pago' : 'Gratis'}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Imagen'>
                  <img
                    alt={topic.name}
                    src={topic.image}
                    style={{ 
                      maxWidth: '30%',
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto'
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_create}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de inicio'
                >
                  <Input
                    disabled
                    value={topic.start_date ? formatDate(topic.start_date) : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de finalización'
                >
                  <Input
                    disabled
                    value={topic.end_date ? formatDate(topic.end_date) : ''}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default TopicDetail