import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Layout,
  Form,
  Input,
  Button,
  Row,
  Col
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'

const Content = Layout.Content

const TradeDetail = (props) => {
  const { push } = useHistory()
  const id = props.match.params.id
  const [redirect, setRedirect] = React.useState(undefined)
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [trade, setTrade] = React.useState({})


  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) +
    ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) +
    ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  const getTrade = async () => {
    const trade = await API.trade.detail(id)
    
    setStart_date(formatDate(trade.start_date))
    setUpdate_date(formatDate(trade.update_date))
    setTrade(trade)
  }

  React.useEffect(() => {
    getTrade()
  }, [])

  let creditCards
  if (trade.creditCards === true) {
    creditCards = 'Si'
  } else {
    creditCards = 'No'
  }
  return (
    <Layout className='layout' >
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del comercio</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => push('/trademerchant')}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='NIT'
                >
                  <Input
                    disabled
                    value={trade.nit}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={trade.trade_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono'
                >
                  <Input
                    disabled
                    value={trade.cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Representante'
                >
                  <Input
                    disabled
                    value={trade.name_owner}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Email'
                >
                  <Input
                    disabled
                    value={trade.email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Categorias'
                >
                  <Input
                    disabled
                    value={trade.nameCategories}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='URL'
                >
                  <Input
                    disabled
                    value={trade.url}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Dirección'
                >
                  <Input
                    disabled
                    value={trade.address}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Valor por punto'
                >
                  <Input
                    disabled
                    value={trade.valuePoint}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Tarjeta crédito'
                >
                  <Input
                    disabled
                    value={creditCards}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Tipo de soporte'
                >
                  <Input
                    disabled
                    value={trade.support_type}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Imagen'
                >
                  <img
                    src={trade.image}
                    style={{ maxWidth: '100%' }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default TradeDetail
