import axios from 'axios'
import config from './config'

const URL = `${config.URL}/relationLoyalty`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function list (id, page) {
  const result = await axios.get(`${URL}/loyalty/trade/${id}/${page}`, config.configWithToken())
  return result.data
}

export default {
  create,
  list
}
