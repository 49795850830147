import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Layout,
  Select
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout

const LoyaltyCreate = (props) => {
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState()
  const [category, setCategory] = React.useState([])
  const [errorCategory,setErrorCategory] = React.useState(undefined)
  const [idCategory, setIdCategory] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)


  const onBack = () => {
    setRedirect('/loyalty')
  }

  const idcategory = async () => {
    const idcategory = await API.category.ListTrue()
    setIdCategory(idcategory)
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.loyalty.create({
      name: name,
      image: image,
      category: category
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de un programa de fidelidad',
        content: `El programa de fidelidad ${name} fue creado exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const beforeUpload = () => {
    return false
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.category) {
      setErrorCategory('Las categorias son obligatorias')
    }
  }

  const clearFields = () => {
    setName(undefined)
    setImage(undefined)
    setCategory(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorImage(undefined)
    setErrorCategory(undefined)
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  React.useEffect(() => {
    idcategory()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  const Category = createRow(idCategory)

  return (
    <Layout className='layout'>
      <Header item='loyalty' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nuevo programa de fidelidad</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    name='name'
                    placeholder='Ingrese nombre del programa de fidelidad'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Image*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categorías*'
                  validateStatus={errorCategory && ('error')}
                  help={errorCategory}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    onChange={(value) => setCategory(value)}
                    value={category}
                    placeholder='Escoja una o varias categorias'
                  >
                    {Category}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default LoyaltyCreate
