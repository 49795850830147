import React from 'react'
import { Table, Icon } from 'antd'

const Addresses = (props) => {
  const addresses = props.addresses
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Ciudad', dataIndex: 'city', key: 'city'},
    {title: 'Dirección', dataIndex: 'address', key: 'address'},
    {title: 'Barrio', dataIndex: 'neighborhood',key: 'neighborhood'},
    {title: 'Activo', dataIndex: 'is_activate', key: 'is_activate',
      render: (index, record) => {
        return (
          <span>
            {record.is_activate ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Primaria', dataIndex: 'is_default', key: 'is_default',
      render: (index, record) => {
        return (
          <span>
            {record.is_default ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    }, {title: 'Fecha de creación', dataIndex: 'start_creater', key: 'start_creater',
      render: (index, record) => {
        const date = new Date(record.start_creater)
        const dateString = ('0' + date.getDate()).slice(-2) + ' de ' + getMonth(date.getMonth()) + ' del ' + date.getFullYear() + ', ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        return (
          <span>{dateString}</span>
        )
      }
    }
  ]



  const getMonth = (index) => {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    return months[index]
  }

  return (
    <Table
      columns={columns}
      dataSource={addresses}
      size='small'
      pagination={{
        position: 'none'
      }}
    />
  )
}

export default Addresses
