import React from 'react'
import {
  Col,
  Row,
  Table
} from 'antd'
import API from '../../api/index'

const BackupList = (props) => {
  const [backup, setBackup] = React.useState([])
  const [page, setPage] = React.useState(1)
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (text, record) => {
        return (
          <a href={record.url}>{record.url}</a>
        )
      }
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'start_create',
      key: 'start_create'
    }
  ]


  const backups = async (page) => {
    const result = await API.csv.list(page)
    setBackup(result)
  }

  React.useEffect(() => {
    backups(page)
  }, [])

  const handlePrev = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      backups(pageTemp)
    }
  }

  const handleNext = () => {
    if (backup.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      backups(pageTemp)
    }
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de backups</strong></h3>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={backup}
        size='small'
        pagination={{position: 'none'}}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrev()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={backup.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={() => handleNext()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BackupList
