import React from 'react'
import { Redirect } from 'react-router-dom'
import { Alert, Form, Button, Input, Icon } from 'antd'
import API from '../api/index'
import { getItem } from '../api/utils'
import '../styles/Login.css'

const FormItem = Form.Item

const Login = (props) => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState(false)
  const [messageError, setMessageError] = React.useState('')
  const [redirect, setRedirect] = React.useState(undefined)

  const onRedirect = (url) => {
    setRedirect(url)
  }

  const onSubmit = async () => {
    if (email && password) {
      const response = await API.auth.login(email, password)
      if (response.token) {
        window.location = '/home'
      } else if (response.errors) {
        setError(true)
        setMessageError(response.errors.token.message)
      } else {
        setError(true)
        setMessageError('Usuario o contraseña incorrectos intentalo nuevamente')
      }
    } else {
      setError(true)
      setMessageError('Usuario y contraseña son obligatorios')
    }
  }

  const getLogin = async () => {
    const token = getItem('token')
    if (token) {
      const response = await API.auth.verify(token)
      if (response._id) {
        window.location = '/home'
      }
    }
  }

  React.useEffect(() => {
    getLogin()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <div className='center-block-login container-login'>
      <div style={{ minWidth: '415px' }}>
        <div className='text-center'>
          <a onClick={() => onRedirect('/')} className='login-title'>
            <span className='yellow'>Ganga</span> <span className='white'>Hoy</span>
          </a>
          <p className='white'>Inicia sesión para continuar</p>
        </div>
        <div>
          <Form onSubmit={onSubmit}>
            {error && (
              <FormItem>
                <Alert message={messageError} type='warning' showIcon />
              </FormItem>
            )}
            <FormItem>
              <Input prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />} 
              placeholder='Ingrese su email' 
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              type='email'
              name='email'
              id='email' />
            </FormItem>
            <FormItem>
              <Input 
              prefix={<Icon type='lock' style={{ color: 'rgba(0,0,0,.25)' }} />} 
              type='password' 
              name='password' 
              id='password' 
              placeholder='Ingrese su contraseña' 
              onChange={(event) => setPassword(event.target.value)} 
              value={password} />
            </FormItem>
            <FormItem>
              <a className='login-form-forgot' onClick={() => onRedirect('/reset/password')}>¿Olvidaste tu contraseña?</a>
            </FormItem>
            <FormItem>
              <Button onClick={onSubmit}>Iniciar sesión</Button>
            </FormItem>
          </Form>
        </div>
        <h5 className='white'>Versión: 1.5.9</h5>
      </div>
    </div>
  )
}

export default Login
