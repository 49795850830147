import React from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import { getItem } from '../../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Modal
} from 'antd'
import Action from '../../trade/Action'


const TradeList = (props) => {
  const id = props.id
  const [tradeAll, setTradeAll] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [nameLoyalty, setNameLoyalty] = React.useState(undefined)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key' },
    {title: 'NIT', dataIndex: 'nit', key: 'nit'},
    {title: 'Nombre', dataIndex: 'trade_name', key: 'trade_name'},
    {title: 'Teléfono', dataIndex: 'cellphone', key: 'cellphone'},
    {title: 'Representante', dataIndex: 'name_owner', key: 'name_owner'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (is_active, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    { title: 'Acción', dataIndex: 'action', key: 'action',
      render: (action, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewTrade(record._id)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => handleUpdateTrade(record._id)}>Editar</Tag>
            <Tag color='cyan' onClick={() => redirectViewBranch(record._id)}>Ver Sucursal</Tag>
            <Action trade={record} />
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
          </span>
        )
      }
    }
  ]

  const onBack = () => {
    setRedirect('/loyalty')
  }

  const trade = async (id, page) => {
    const response = await API.relation.list(id, page)
    setTradeAll(response)
  }

  const handleDelete = async (id) => {
    setDeleteId(true)
    setDeleteId(id)
  }

  const handleUserDelete = async () => {
    const result = await API.trade.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El comercio ha sido eliminado exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const handleCreateTrade = () => {
    setRedirect(`/loyalty/tradecreate/${id}`)
  }

  const handleViewTrade = (id) => {
    setRedirect(`/trade/detail/${id}`)
  }

  const handleUpdateTrade = (id) => {
    setRedirect(`/trade/update/${id}`)
  }
  const redirectViewBranch = (id) => {
    setRedirect(`/branches/list/${id}`)
  }

  const handlePrevTrade = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      trade(id, pageTemp)
    }
  }

  const handleNextTrade = () => {
    if (tradeAll.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      trade(id, pageTemp)
    }
  }

  const get_name_loyalty = async (id) => {
    const response = await API.loyalty.detail(id)
    setNameLoyalty(response.name)
  }

  React.useEffect(() => {
    get_name_loyalty(id)
    trade(id, page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>{nameLoyalty} - Comercio</strong></h3>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'right' }}>
          <Button onClick={() => onBack()}>Regresar</Button>
          {' '}
          <Button onClick={() => handleCreateTrade()}>Relacionar a comercios</Button>
        </Col>
        {Delete && (
          <Modal
            title='Alerta'
            visible={Delete}
            onOk={() => handleUserDelete()}
            onCancel={() => handleAlertNot()}
            okText='Continuar'
            cancelText='Cancelar'
          >
            <p>¿Desea eliminar este comercio?</p>
          </Modal>
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={tradeAll}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrevTrade()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={tradeAll.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={() => handleNextTrade()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TradeList
