import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Tag,
  Button,
  Icon,
  InputNumber,
  Modal,
  Layout
} from 'antd'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import Action from './Action'
import Header from '../Header'

const { Content } = Layout

const ProductsList = (props) => {
  const [products, setProducts] = React.useState([])
  const [trade, setTrade] = React.useState(undefined)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [Delete, setDelete] = React.useState(false)
  const branch = props.match.params.branch
  const type = getItem('type')

  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Nombre', dataIndex: 'name', key: 'name'},
    {title: 'Inventario', dataIndex: 'inventory', key: 'inventory',
      render: (text, record) => {
        return (
          <span>{record.inventory ? record.inventory : 0}</span>
        )
      }
    },{title: 'Precio', dataIndex: 'price', key: 'price',
      render: (text, record) => {
        return (
          <span>
            {record.price && (
              <InputNumber
                value={record.price}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },{title: 'Promoción', dataIndex: 'is_promotion', key: 'is_promotion',
      render: (text, record) => {
        return (
          <span>
            {record.is_promotion ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewProducts(record._id)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => handleUpdateProducts(record._id)}>Editar</Tag>
            <Tag color='#1B6300' onClick={() => handleInventory(record._id, record.name)}>Inventario</Tag>
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
            <Action product={record} />
          </span>
        )
      }
    }
  ]

  const handleDownload = async () => {
    const users = 'products'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleDelete = (id) => {
    setDelete(true)
    setDeleteId(id)
  }

  const handleUserDelete = async () => {
    const result = await API.products.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El producto ha sido eliminado exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const Products = async (page) => {
    const products = await API.products.listBranch(branch, page)
    setProducts(products)
  }


  const handleCreateProducts = () => {
    setRedirect(`/products/create/${branch}`)
  }

  const handleViewProducts = (id) => {
    setRedirect(`/product/detail/${id}`)
  }

  const handleUpdateProducts = (id) => {
    setRedirect(`/product/update/${id}`)
  }

  const handleInventory = (id, name) => {
    setRedirect(`/inventory/${branch}/${id}/${name}`)
  }

  const handlePrevProduct = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      Products(pageTemp)
    }
  }

  const handleNextProduct = () => {
    if (products.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      Products(pageTemp)
    }
  }

  const getBranch = async () => {
    const response = await API.branchOffice.detail(props.match.params.branch)
    if (response._id) {
      setTrade(response.idTrade._id)
      Products(page)
    } else {
      setRedirect('/home')
    }
  }

  const onBack = () => {
    setRedirect(`/branches/list/${trade}`)
  }

  React.useEffect(() => {
    getBranch()
  }, [props.match.params.branch])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <div>
            {Delete && (
              <Modal
                title='Alerta'
                visible={Delete}
                onOk={() => handleUserDelete()}
                onCancel={() => handleAlertNot()}
                okText='Continuar'
                cancelText='Cancelar'
              >
                <p>¿Desea eliminar este producto?</p>
              </Modal>
            )}
            <Row style={{ marginBottom: '20px' }}>
              <Col span={8}>
                <h3><strong>Listado de productos</strong></h3>
              </Col>
              <Col offset={8} span={8} style={{ textAlign: 'right' }}>
                <Button onClick={() => onBack()}>Regresar</Button>
                {' '}
                <Button onClick={() => handleCreateProducts()}>Crear producto</Button>
              </Col>
            </Row>
            <Table
              columns={columns}
              dataSource={products}
              size='small'
              pagination={{
                position: 'none'
              }}
            />
            <div style={{
              textAlign: 'right',
              marginTop: '10px'
            }}>
              <ul className='ant-pagination '>
                <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev'
                  ) : ('ant-pagination-prev')}
                >
                  <a onClick={() => handlePrevProduct()}>Anterior</a>
                </li>
                <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
                  {page}
                </li>
                <li className={products.length >= 10 ? ('ant-pagination-next'
                  ) : ('ant-pagination-disabled ant-pagination-next')}
                >
                  <a onClick={() => handleNextProduct()}>Siguiente</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Content>
    </Layout>
  )
}    

export default ProductsList
