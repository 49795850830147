import React from 'react'
import { Layout } from 'antd'
import Header from '../Header'
import BackupsList from './List'
const { Content } = Layout

const Backups = (props) => {
  return (
    <Layout className='layout'>
      <Header item='backups' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <BackupsList />
        </div>
      </Content>
    </Layout>
  )
}

export default Backups
