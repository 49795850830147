import React from 'react'
import {
  Table,
  Row,
  Col,
  Icon,
  Tag,
  InputNumber,
  Input,
  Modal,
  Button
} from 'antd'
import API from '../../api/index'
import { Redirect } from 'react-router-dom'
const Search = Input.Search

const Bill = (props) => {
  const [bill, setBill] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React. useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Sucursal',
      dataIndex: 'nameBranch',
      key: 'nameBranch'
    },
    {
      title: 'Usuario',
      dataIndex: 'userName',
      key: 'userName'
    },
    {
      title: 'Método de pago',
      dataIndex: 'pay_mode',
      key: 'pay_mode',
      render: (text, record) => {
        let response
        if (record.pay_mode === 'buy_site') {
          response = 'Lugar'
        } else if (record.pay_mode === 'buy_online') {
          response = 'Online'
        }
        return (
          <span>{response}</span>
        )
      }
    },
    {
      title: 'Precio total',
      dataIndex: 'totalPriceBill',
      key: 'totalPriceBill',
      render: (text, record) => {
        return (
          <span>
            {record.totalPriceBill && (
              <InputNumber
                value={record.totalPriceBill}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },
    {
      title: 'Pagó',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (index, record) => {
        return (
          <span>
            {!record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {
      title: 'Fecha de pago',
      dataIndex: 'update_date',
      key: 'update_date',
      render: (index, record) => {
        const date = new Date(record.start_date)
        const dateString = ('0' + date.getDate()).slice(-2) + ' de ' + getMonth(date.getMonth()) + ' del ' 
        + date.getFullYear() + ', ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        return (
          <span>{!record.is_active && (dateString)}</span>
        )
      }
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleView(record._id)}>Ver detalle</Tag>
          </span>
        )
      }
    }
  ]
  
  const getMonth = (index) => {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    return months[index]
  }

  const handlePrevBranch = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      getBill(pageTemp)
    }
  }

  const handleNextBranch = () => {
    if (bill.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      getBill(pageTemp)
    }
  }

  const handleView = (id) => {
    setRedirect(`/sales/detail/${id}`)
  }

  const handleClearSearch = () => {
    setPage(1)
    setIs_search(false)
    setSearch(undefined)
    getBill(1)
  }

  const handleDownload = async () => {
    const users = 'sales'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const onSearch = () => {
    setIs_search(true)
    setPage(1)
    getBillByNameBranch(1)
  }

  const getBillByNameBranch = async (page) => {
    const result = await API.bill.search(page, search)
    setBill(result)
  }

  const getBill = async (page) => {
    const result = await API.bill.all(page)
    setBill(result)
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de Ventas</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            name='search'
            placeholder='Ingrese nombre de la sucursal'
            onSearch={() => onSearch()}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownload()}>Descargar csv</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={bill}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={handlePrevBranch}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={bill.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={handleNextBranch}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default Bill
