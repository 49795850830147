import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Button,
  Icon,
  Tag
} from 'antd'
import API from '../../../api/index'

const Assistants = (props) => {
  const { push } = useHistory()
  const [business, setBusiness] = React.useState([])
  const [page,setPage] = React.useState(1)
  const [topic, setTopic] = React.useState({})
  const id = props.id
  const columns = [
    {title: 'Nombre', dataIndex: 'fullName', key: 'fullName'},
    {title: 'E-mail', dataIndex: 'email', key: 'email'},
    {title: 'Cellphone', dataIndex: 'cellphone', key: 'cellphone'},
    {title: 'Pago', dataIndex: 'payment', key: 'payment', 
      render: (text, record) => {
        return (
          <span>
            {(record.payment || topic.type === 'free') ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active', 
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => {
        return (
          <span>
            {(!record.payment && topic.type === 'payment') && (
              <Tag color='green' onClick={() => marked_payment(record._id)}>Marcar como pagado</Tag>
            )}
            <Tag color='orange' onClick={() => push(`/assistant/update/${record._id}`)}>Editar asistente</Tag>
            <Tag color='red' onClick={() => delete_assistan(record._id)}>Borrar asistente</Tag>
          </span>
        )
      }
    }
  ]

  const delete_assistan = async (_id) => {
    await API.assistant.delete_assistant(_id)
    get_assistants(page)
  }

  const marked_payment = async (_id) => {
    await API.assistant.marked_payment(_id)
    get_assistants(page)
  }

  const get_assistants = async (page) => {
    const response = await API.assistant.filter_by_page(page, id)
    setBusiness(response)
  }

  const handlePrev = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      get_assistants(pageTemp)
    }
  }

  const handleNext = () => {
    if (business.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      get_assistants(pageTemp)
    }
  }

  const get_topic = async (id) => {
    const response = await API.topic.detail(id)
    setTopic(response)
  }

  React.useEffect(() => {
    get_assistants(page)
    get_topic(id)
  }, [])

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Asistentes de la feria {topic.name}</strong></h3>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'right' }}>
          {' '}
          <Button onClick={() => push('/topic')}>Regresar</Button>
          {' '}
          <Button onClick={() => push(`/topic/assistant/${id}/create`)}>Crear asistente</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={business}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : 
            ('ant-pagination-prev')}
          >
            <a onClick={() => handlePrev()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li
            className={business.length >= 10 ? ('ant-pagination-next') : 
            ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNext()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Assistants
