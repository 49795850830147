import React from 'react'
import { Layout } from 'antd'
import { getItem } from '../api/utils'
import Header from './Header'

const { Content } = Layout

const Home = (props) => {
  const type_user = getItem('type')
  const fullName = getItem('fullName')

  return (
    <Layout className='layout'>
      <Header item='home' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <h3>Bienvenido <strong>{fullName}</strong>, {type_user}</h3>
        </div>
      </Content>
    </Layout>
  )
}

export default Home
