import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Button
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const Content = Layout.Content
const { TextArea } = Input

const SecctionsDetail = (props) => {
  const [section, setSection] = React.useState({})
  const [start_create, setStart_create] = React.useState(undefined)
  const [start_update, setStart_update] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const id = props.match.params.id

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  const sections = async () => {
    const section = await API.sections.detail(id)
    setStart_create(formatDate(section.start_create))
    setStart_update(formatDate(section.start_update))
    setSection(section)
  }

  const onBack = () => {
    setRedirect('/sections')
  }

  React.useEffect(() => {
    sections()
  }, [])

  let isMenu
  let isPrimary
  if (section.is_menu === true) {
    isMenu = 'Si'
  } else {
    isMenu = 'No'
  }
  if (section.is_primary === true) {
    isPrimary = 'Si'
  } else {
    isPrimary = 'No'
  }
  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header iterm='section' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle de la sección</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Nombre'>
                  <Input
                    disabled
                    value={section.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Imagen'>
                  <img
                    alt={section.name}
                    src={section.image}
                    style={
                      { maxWidth: '30%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                >
                  <TextArea
                    disabled
                    value={section.description}
                    autosize={{ minRows: 4, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tipo de sección'
                >
                  <Input
                    disabled
                    value={section.type} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Es una sección primaria?'
                >
                  <Input
                    disabled
                    value={isPrimary}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿La sección pertenece al menú?'
                >
                  <Input
                    disabled
                    value={isMenu}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_create}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    disabled
                    value={start_update}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default SecctionsDetail