import React from 'react'
import {
  Layout, Row, Col, Button, Table, Icon, Tag
} from 'antd'
import Header from '../../Header'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
const Content = Layout.Content

const Operator = (props) => {
  const id = props.match.params.id
  const [trade_name, setTrade_name] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [operators, setOperators] = React.useState([])
  const columns = [
    {title: 'Nombre', dataIndex: 'fullName', key: 'fullName'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    }, 
    {title: 'Último inicio de sesión', dataIndex: 'date_join', key: 'date_join',
      render: (text, record) => {
        return (
          <span>
            {formatDate(record.date_join)}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewUser(record._id)}>Ver detalle</Tag>
          </span>
        )
      }
    }
  ]
  
  const handleViewUser = (id) => {
      setRedirect(`/user/detail/${id}`)
  }

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date)
      return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ' ' + newDate.getHours() + ':' + newDate.getMinutes()
    }
    return ''
  }

  const onBack = () => {
      setRedirect('/trade')
  }

  const handleCreateOperator = () => {
      setRedirect(`/trade/${id}/operator/create`)
  }

  const getTrade = async () => {
    const trade = await API.trade.detail(id)
    if (trade.trade_name) {
      setTrade_name(trade.trade_name)
    } else {
      setRedirect('/home')
    }
  }

  const getOperators = async () => {
    const operators = await API.trade.getOperators(id)
      setOperators(operators)
  }

  React.useEffect(() => {
    getTrade()
    getOperators()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Operadores del comercio {trade_name}</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => handleCreateOperator()}>Crear operador</Button>
            </Col>
          </Row>
          <Row>
            <Col span={23} offset={1}>
              <Table columns={columns} dataSource={operators} rowKey={(value) => value._id} />
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  )
}

export default Operator
