import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  DatePicker,
  Row,
  Col
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'
import moment from 'moment'

const { Content } = Layout
const dateFormat = 'YYYY/MM/DD'

const UserUpdateMerchant = (props) => {
  const id = props.match.params.id
  const [first_name, setFirst_name] = React.useState(undefined)
  const [errorFirstName, setErrorFirstName] = React.useState(undefined)
  const [last_name, setLast_name] = React.useState(undefined)
  const [errorLastName, setErrorLastName] = React.useState(undefined)
  const [email, setEmail] = React.useState(undefined)
  const [birthdate, setBirthdate] = React.useState(undefined)
  const [errorBirthdate, setErrorBirthdate] = React.useState(undefined)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [gender, setGender] = React.useState(undefined)
  const [errorGender, setErrorGender] = React.useState(undefined)
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const onBack = () => {
    setRedirect('/usermerchant')
  }

  const getCountries = async () => {
    const countries = await API.country.list()
    setCountries(countries)
  }

  const getStates = async (country = country) => {
    if (country) {
      const states = await API.state.list(country)
      setStates(states)
    }
  }

  const getCities = async (state = state) => {
    if (state) {
      const cities = await API.city.list(state)
      setCities(cities)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.user.updateMerchant(id, {
      first_name: first_name,
      last_name: last_name,
      birthdate: birthdate,
      city: city,
      cellphone: cellphone,
      image: image,
      gender: gender
    })
    console.log(response)
    if (response._id) {
      Modal.success({
        title: 'Actualización del usuario',
        content: `El usuario ${first_name} fue actualizado exitosamente`
      })
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.first_name) {
      setErrorFirstName(error.first_name.message)
    }
    if (error.last_name) {
      setErrorLastName(error.last_name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.birthdate) {
      setErrorBirthdate(error.birthdate.message)
    }
    if (error.gender) {
      setErrorGender(error.gender.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
  }

  const clearErrors = () => {
    setErrorFirstName(undefined)
    setErrorLastName(undefined)
    setErrorImage(undefined)
    setErrorCity(undefined)
    setErrorBirthdate(undefined)
    setErrorGender(undefined)
    setErrorCellphone(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleClearImage = () => {
    setImage(undefined)
  }

  const handleChangeCountry = (value) => {
    setCountry(value)
    getStates(value)
  }

  const handleChangeState = (value) => {
    setState(value)
    getCities(value)
  }

  const getUser = async () => {
    const user = await API.user.detail(id)
    setFirst_name(user.first_name)
    setLast_name(user.last_name)
    setCountry(user.country.id)
    getStates(user.country.id)
    getCities(user.state.id)
    setState(user.state.id)
    setCity(user.city.id)
    setCellphone(user.cellphone)
    setEmail(user.email)
    setGender(user.gender)
    setBirthdate(moment(user.birthdate, dateFormat))
    setImage(user.image)
  }

  React.useEffect(() => {
    getUser()
    getCountries()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const countryRow = createRow(countries)
  const stateRow = createRow(states)
  const cityRow = createRow(cities)

  return (
    <Layout className='layout'>
      <Header item='user' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualización de usuario</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombres'
                  validateStatus={errorFirstName && ('error')}
                  help={errorFirstName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre del usuario'
                    onChange={(event) => setFirst_name(event.target.value)}
                    value={first_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Apellidos'
                  validateStatus={errorLastName && ('error')}
                  help={errorLastName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese los apellidos del usuario'
                    onChange={(event) => setLast_name(event.target.value)}
                    value={last_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Foto'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Editar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        Foto de perfil
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='País'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryRow}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Estado'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => handleChangeState(value)}
                    value={state}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {stateRow}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => setCity(value)}
                    value={city}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cityRow}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el teléfono del usuario'
                    value={cellphone}
                    onChange={(event) => setCellphone(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Correo electrónico'
                >
                  <Input
                    type='email'
                    name='email'
                    disabled
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Género'
                  validateStatus={errorGender && ('error')}
                  help={errorGender}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setGender(value)}
                    value={gender}
                    placeholder='Seleccione un género'
                  >
                    <Select.Option value='masculino'>Masculino</Select.Option>
                    <Select.Option value='femenino'>Femenino</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de Nacimiento'
                  validateStatus={errorBirthdate && ('error')}
                  help={errorBirthdate}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    format={dateFormat}
                    value={birthdate}
                    onChange={(value) => setBirthdate(value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default UserUpdateMerchant
