import React from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Input,
  Modal
} from 'antd'
import Action from './Action'
const Search = Input.Search

const UserList = (props) => {
  const [users, setUsers] = React.useState([])
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Nombre', dataIndex: 'first_name', key: 'first_name'},
    {title: 'Apellidos', dataIndex: 'last_name', key: 'last_name'},
    {title: 'Tipo de usuario', dataIndex: 'type_user', key: 'type_user'},
    {title: 'Último inicio de sesión', dataIndex: 'date_join',key: 'date_join'},
    {title: 'Activo', dataIndex: 'is_active',key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <React.Fragment>
              <Tag color='blue' onClick={() => handleViewUser(record._id)}>Ver detalle</Tag>
              {(type === 'Administrador' || (record.type_user !== 'Administrador' && record.type_user !== 'supervisor')) && (
                <Tag color='orange' onClick={() => handleUpdateUser(record._id)}>Editar</Tag>
              )}
              {(type === 'Administrador' || (record.type_user !== 'Administrador' && record.type_user !== 'supervisor')) && (
                <Action user={record} />
              )}
              {type === 'Administrador' && (
                <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
              )}
            </React.Fragment>
          </span>
        )
      }
    }
  ]

  const handleAdministrador = async () => {
    setIs_search(true)
    setSearch('Administrador')
    const users = await API.user.search(page, 'Administrador')
    setUsers(users)
  }

  const handleEmpresario = async () => {
    setIs_search(true)
    setSearch('Empresario')
    const users = await API.user.search(page, 'Empresario')
    setUsers(users)
  }

  const handleAsesorComercial = async () => {
    setIs_search(true)
    setSearch('Asesor Comercial')
    const users = await API.user.search(page, 'Asesor Comercial')
    setUsers(users)
  }

  const handleEntidadPromotora = async () => {
    setIs_search(true)
    setSearch('EntidadPromotora')
    const users = await API.user.search(page, 'EntidadPromotora')
    setUsers(users)
  }

  const handleOperador = async () => {
    setIs_search(true)
    setSearch('operador')
    const users = await API.user.search(page, 'operador')
    setUsers(users)
  }

  const handleCliente = async () => {
    setIs_search(true)
    setSearch('cliente')
    const users = await API.user.search(page, 'cliente')
    setUsers(users)
  }

  const handleSupervisor = async () => {
    setIs_search(true)
    setSearch('supervisor')
    const users = await API.user.search(page, 'supervisor')
    setUsers(users)
  }

  const handleActivos = async () => {
    setIs_search(true)
    setSearch('Activos')
    const users = await API.user.search(page, 'Activos')
    setUsers(users)
  }

  const handleNoActivos = async () => {
    setIs_search(true)
    setSearch('No activos')
    const users = await API.user.search(page, 'No activos')
    setUsers(users)
  }

  const getUsers = async (page) => {
    const users = await API.user.list(page)
    setUsers(users)
  }

  const getUsersByName = async (page) => {
    const users = await API.user.search(page, search)
    setUsers(users)
  }

  const handleDownload = async () => {
    const users = 'users'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleCreateUser = () => {
    setRedirect('/user/create')
  }

   const handleDelete = (id) => {
    setDelete(true)
    setDeleteId(id)
  }

   const handleUserDelete = async () => {
    const result = await API.user.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El usuario ha sido eliminado exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const handleViewUser = (id) => {
    setRedirect(`/user/detail/${id}`)
  }

  const handleUpdateUser = (id) => {
    setRedirect(`/user/update/${id}`)
  }

  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    getUsers(1)
  }

  const onSearch = () => {
    setIs_search(true)
    setPage(1)
    getUsersByName(1)
  }

  React.useState(() => {
    getUsers(page)
  }, [])

  const handlePrevUser = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      if (search && is_search) {
        getUsersByName(pageTemp)
      } else {
        getUsers(pageTemp)
      }
    }
  }

  const handleNextUser = () => {
    if (users.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      if (search && is_search) {
        getUsersByName(pageTemp)
      } else {
        getUsers(pageTemp)
      }
    }
  }
  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de usuarios</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            name='search'
            placeholder='Ingrese nombre del usuario'
            onSearch={() => onSearch()}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {(type === 'Administrador' || type === 'supervisor') && (
            <React.Fragment>
              <Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleAdministrador()}
              >
                Administrador
              </Tag>
              <Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleEmpresario()}
              >
                Empresario
              </Tag>
              <Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleSupervisor()}
              >
                Supervisor
              </Tag><Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleCliente()}
              >
                Cliente
              </Tag><Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleOperador()}
              >
                Operador
              </Tag><Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleAsesorComercial()}
              >
                Asesor Comercial
              </Tag><Tag
                color='blue'
                style={{ marginTop: '5px' }}
                onClick={() => handleEntidadPromotora()}
              >
                Entidad promotora
              </Tag><Tag
                color='green'
                style={{ marginTop: '5px' }}
                onClick={() => handleActivos()}
              >
                Activos
              </Tag><Tag
                color='red'
                style={{ marginTop: '5px' }}
                onClick={() => handleNoActivos()}
              >
                No activos
              </Tag>
            </React.Fragment>
          )}
          {(type === 'Administrador' || type === 'supervisor' || Delete) && (
            <Modal
              title='Alerta'
              visible={Delete}
              onOk={() => handleUserDelete()}
              onCancel={() => handleAlertNot()}
              okText='Continuar'
              cancelText='Cancelar'
            >
              <p>¿Desea eliminar este usuario?</p>
            </Modal>
          )}
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {(type === 'Administrador' || type === 'supervisor') && (
            <Button onClick={() => handleDownload()}>Descargar csv</Button>
          )}
          {' '}
          <Button onClick={() => handleCreateUser()}>Crear usuario</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={users}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li
            className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev'
            ) : ('ant-pagination-prev')}
          >
            <a onClick={() => handlePrevUser()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li 
            className={users.length >= 10 ? ('ant-pagination-next'
            ) : ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNextUser()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserList
