import React from 'react'
import BusinessList from './List'
import { Layout } from 'antd'
import Header from '../../Header'

const { Content } = Layout

const Business = (props) => {
  const id = props.match.params.id

  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <BusinessList id={id} />
        </div>
      </Content>
    </Layout>
  )
}

export default Business
