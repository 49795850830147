import React from 'react'
import {
  Layout,
  Table,
  Row,
  Col,
  DatePicker,
  Tag
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { RangePicker } = DatePicker
const { Content } = Layout

const Log = (props) => {
  const [page, setPage] = React.useState(1)
  const [logs, setLogs] = React.useState([])
  const [startDate, setStartDate] = React.useState(undefined)
  const [endDate, setEndDate] = React.useState(undefined)
  const [date, setDate] = React.useState(undefined)
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Fecha de creación',
      dataIndex: 'creation_date',
      key: 'creation_date'
    }
  ]

  const handleClearSearch = async () => {
    const logs = await API.log.list(1)
    setPage(1)
    setDate(undefined)
    setStartDate(undefined)
    setEndDate(undefined)
    setLogs(logs)
  }

  const getLogs = async (page) => {
    let logs = []
    if (startDate && endDate) {
      logs = await API.log.filterByPage(page, startDate, endDate)
    } else {
      logs = await API.log.list(page)
    }
    setLogs(logs)
  }

  const handlePrevUser = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      getLogs(pageTemp)
    }
  }

  const handleNextUser = () => {
    if (logs.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      getLogs(pageTemp)
    }
  }

  React.useEffect(() => {
    getLogs(page)
  }, [])

  return (
    <Layout className='layout'>
      <Header item='log' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Registro del sistema</strong></h3>
            </Col>
            <Col span={8}>
              <RangePicker
                onChange={(event) => setDate(event.target.value)}
                value={date}
              />
              {startDate && (
                <Tag
                  color='red'
                  style={{ marginTop: '5px' }}
                  onClick={() => handleClearSearch()}
                >
                  Limpiar busqueda
                </Tag>
              )}
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={logs}
            size='small'
            pagination={{
              position: 'none'
            }}
          />
          <div style={{
            textAlign: 'right',
            marginTop: '10px'
          }}>
            <ul className='ant-pagination '>
              <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
                <a onClick={() => handlePrevUser()}>Anterior</a>
              </li>
              <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
              <li className={logs.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
                <a onClick={() => handleNextUser()}>Siguiente</a>
              </li>
            </ul>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default Log
