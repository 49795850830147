import axios from 'axios'
import config from './config'

const URL = `${config.URL}/loyalty`

export async function create (data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        if (key === 'category') {
          for (let index = 0; index < data[key].length; index++) {
            const id = data[key][index]
            form.append('category[]', id)
          }
        } else {
          form.append([key], data[key])
        }
      }
    }
  })
  const result = await axios.post(`${URL}`, form, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/${page}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (key === 'category') {
        for (let index = 0; index < data[key].length; index++) {
          const id = data[key][index]
          form.append('category[]', id)
        }
      } else {
        form.append([key], data[key])
      }
    }
  })
  const result = await axios.post(`${URL}/update/${id}`, form, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else if (status === false) {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/change/status`, { status: status, id: id }, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const result = await axios.get(`${URL}/search/admin/${page}/${name}`, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/loyalty/${id}`, config.configWithToken())
  return result.data
}

export default {
  create,
  detail,
  list,
  update,
  change,
  search,
  deletes
}
