import axios from 'axios'
import config from './config'

const URL = `${config.URL}/branch/office`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function list () {
  const status = true
  const result = await axios.get(`${URL}/list/${status}`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export async function trade (page, id) {
  const result = await axios.get(`${URL}/trade/${page}/${id}`, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else if (status === false) {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/change/status`, {
    status: status,
    id: id
  }, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/branch/${id}`, config.configWithToken())
  return result.data
}

export async function branchOperator () {
  const result = await axios.get(`${URL}/list/iduser`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  detail,
  update,
  trade,
  change,
  deletes,
  branchOperator
}
