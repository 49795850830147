import React from 'react'
import TopicList from './List'
import { Layout } from 'antd'
import Header from '../Header'

const { Content } = Layout


const Profession = (props) => {
  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <TopicList />
        </div>
      </Content>
    </Layout>
  )
}

export default Profession
