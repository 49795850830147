import React from 'react'
import BranchList from './ListOperator'
import { Layout } from 'antd'
import Header from '../../../Header'

const { Content } = Layout

const BranchMerchant = (props) => {
  return (
    <Layout className='layout'>
      <Header item='branch' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <BranchList />
        </div>
      </Content>
    </Layout>
  )
}

export default BranchMerchant
