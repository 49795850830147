import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout,
  Row,
  Col
} from 'antd'
import API from '../../../api/index'
import Header from '../../Header'
import Maps from '../../maps/Maps'
import Geosuggest from 'react-geosuggest'

const { Content } = Layout

const BranchCreate = (props) => {
  const [idTrade, setIdTrade] = React.useState(undefined)
  const [nameTrade, setNameTrade] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [address, setAddress] = React.useState(undefined)
  const [addressProps, setAddressProps] = React.useState(undefined)
  const [errorAddress, setErrorAddress] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [user, setUser] = React.useState(undefined)
  const [errorUser, setErrorUser] = React.useState(undefined)
  const [listUser, setListUser] = React.useState([])
  const [gps, setGps] = React.useState(undefined)
  const [lat, setLat] = React.useState(undefined)
  const [lng, setLng] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [allow_gps, setAllow_gps] = React.useState(true)
  const [idCity, setIdCity] = React.useState([])
  const [idCountry, setIdCountry] = React.useState([])
  const [idState, setIdState] = React.useState([])
  const [zoom, setZoom] = React.useState(18)
  const id = props.match.params.id

  const onBack = () => {
    setRedirect(`/branches/list/${idTrade}`)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const createRowUser = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.first_name + ' ' + value.last_name}</Select.Option>)
    })
    return row
  }

  const _user = async () => {
    const users = await API.trade.getOperators(idTrade)
    setListUser(users)
  }

  const _country = async () => {
    const idCountry = await API.country.list()
    setIdCountry(idCountry)
  }

  const stateC = async (country = country) => {
    if (country) {
      const idState = await API.state.list(country)
      setIdState(idState)
    }
  }

  const _city = async (state = state) => {
    if (state) {
      const idCity = await API.city.list(state)
      setIdCity(idCity)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    let address
    if (address !== addressProps) {
      address = address
    }
    const response = await API.branchOffice.update(id, {
      name: name,
      city: city,
      address: address,
      cellphone: cellphone,
      user: user,
      gps: `${lat},${lng}`
    })
    if (response.ok) {
      Modal.success({
        title: 'Actualización de una sucursal',
        content: `La sucursal ${name} fue actualizada exitosamente`
      })
      clearErrors()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
    if (error.user) {
      setErrorUser(error.user.message)
    }
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorCity(undefined)
    setErrorAddress(undefined)
    setErrorCellphone(undefined)
    setErrorUser(undefined)
  }

  const handleChangeCountry = async (value) => {
    setCountry(value)
    stateC(value)
  }

  const handleChangeState = async (value) => {
    setState(value)
    _city(value)
  }

  const handleMaps = async (latitude, longitude) => {
    setLat(latitude)
    setLng(longitude)
  }

  const getBranch = async () => {
    const branches = await API.branchOffice.detail(id)
    setName(branches.name)
    if (branches.idTrade) {
      setIdTrade(branches.idTrade._id)
      setNameTrade(branches.idTrade.trade_name)
      _user()
    }
    const users = []
    for (let index = 0; index < branches.users.length; index++) {
      const user = branches.users[index]
      users.push(user._id)
    }

    setUser(users)
    setCellphone(branches.cellphone)

    if (branches.city) {
      setCity(branches.city._id)
      setCountry(branches.country._id)
      setState(branches.state._id)
      stateC(branches.country._id)
      _city(branches.state._id)
    }
    setGps(branches.gps)
    let lat
    let lng
    if (branches.gps) {
      const gpsState = branches.gps
      lat = parseFloat(gpsState[0])
      lng = parseFloat(gpsState[1])
    }
    setLat(lat)
    setLng(lng)
    setAddress(branches.address)
    setAddressProps(branches.address)
  }

  const onSuggestSelect = (data) => {
    setAddress(undefined)
    setLat(undefined)
    setLng(undefined)

  if (data) {
      setAddress(data.description)
      setLat(data.location.lat)
      setLng(data.location.lng)
  }
}

  React.useState(() => {
    _country()
    getBranch()
  })

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  const City = createRow(idCity)
  const State = createRow(idState)
  const Country = createRow(idCountry)
  const User = createRowUser(listUser)
  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualizar sucursal</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Comercio'
                >
                  <Input
                    disabled
                    placeholder={nameTrade}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    type='text'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='País'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Country}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Estado'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => handleChangeState(value)}
                    value={state}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {State}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Ciudad'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => setCity(value)}
                    value={city}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {City}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Teléfono'
                >
                  <Input
                    type='text'
                    onChange={(event) => setCellphone(event.target.value)}
                    value={cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Operador(es)'
                >
                  <Select
                    mode='multiple'
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una usuario'
                    onChange={(value) => setUser(value)}
                    value={user}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {User}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección*'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Geosuggest
                    placeholder={address || 'Ingrese la dirección'}
                    onSuggestSelect={() => onSuggestSelect()}
                    location={new window.google.maps.LatLng(4.6979319, -74.0780855)}
                    radius={20}
                    inputClassName='ant-input'
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ubicación'
                >
                  {address && (<Maps lat={lat} lng={lng} handleMaps={() => handleMaps()} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default BranchCreate
