import axios from 'axios'
import config from './config'

const URL = `${config.URL}/log`

export async function list (page) {
  const result = await axios.get(`${URL}/list/${page}`, config.configWithToken())
  return result.data
}

export async function listByUser (page, user) {
  const result = await axios.get(`${URL}/list/${page}/${user}`, config.configWithToken())
  return result.data
}

export async function filterByPage (page, startDate, endDate) {
  const result = await axios.post(`${URL}/filter/${page}`, {startDate, endDate}, config.configWithToken())
  return result.data
}

export default {
  list,
  listByUser,
  filterByPage
}
