import React from 'react'
import { Table, Icon, Tag, InputNumber } from 'antd'
import { Redirect } from 'react-router-dom'

const Bill = (props) => {
  const bill = props.bill
  const [redirect, setRedirect] = React.useState(undefined)
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Sucursal', dataIndex: 'nameBranch', key: 'nameBranch'},
    {title: 'Método de pago', dataIndex: 'pay_mode', key: 'pay_mode',
      render: (index, record) => {
        let response = 'Redimir con puntos'
        if (record.pay_mode === 'buy_site') {
          response = 'Lugar'
        } else if (record.pay_mode === 'buy_online') {
          response = 'Online'
        }
        return (
          <span>{response}</span>
        )
      }
    },{title: 'Precio total', dataIndex: 'totalPriceBill', key: 'totalPriceBill',
      render: (index, record) => {
        return (
          <span>
            {record.totalPriceBill && (
              <InputNumber
                value={record.totalPriceBill}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },{title: 'Puntos', dataIndex: 'points', key: 'points'},
    {title: 'Pagó', dataIndex: 'is_active', key: 'is_active',
      render: (index, record) => {
        return (
          <span>
            {!record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleView(record._id)}>Ver detalle</Tag>
          </span>
        )
      }
    }
  ]

  const handleView = (id) => {
    setRedirect(`/bill/user/detail/${id}`)
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Table
      columns={columns}
      dataSource={bill}
      size='small'
      pagination={{
        position: 'none'
      }}
    />
  )
}

export default Bill
