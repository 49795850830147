import React from 'react'
import { Alert, Form, Button, Input, Icon } from 'antd'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import '../../styles/Login.css'

const FormItem = Form.Item

const ResetPassword = (props) => {
  const token = props.match.params.token
  const [password, setPassword] = React.useState(undefined)
  const [repeatPassword, setRepeatPassword] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [typeMessage, setTypeMessage] = React.useState(undefined)
  const [message, setMessage] = React.useState(undefined)

  const onLogin = () => {
    setRedirect('/')
  }

  const onSubmit = async () => {
    setTypeMessage(undefined)
    setMessage(undefined)

    if (password === repeatPassword) {
      const response = await API.resetPassword.changePassword(token, password)
      if (response.ok) {
        setTypeMessage('success')
        setMessage('La contraseña fue cambiada correctamente, por favor inicie sesión.')
        setPassword(undefined)
        setRepeatPassword(undefined)
      }
    } else {
      setTypeMessage('error')
      setMessage('Las contraseñas no coinciden, por favor intente de nuevo.')
    }
  }

  const verifyToken = async () => {
    const response = await API.resetPassword.verifyToken(token)
    if (!response.active) {
      onLogin()
    }
  }

  React.useEffect(() => {
    verifyToken()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <div className='center-block-login container-login'>
      <div style={{ minWidth: '415px' }}>
        <div className='text-center'>
          <a onClick={() => onLogin()} className='login-title'>
            <span className='yellow'>Ganga</span> <span className='white'>Hoy</span>
          </a>
          <p className='white'>Cambiar contraseña</p>
        </div>
        <div>
          <Form onSubmit={() => onSubmit()}>
            {(message && typeMessage) && (
              <FormItem>
                <Alert message={message} type={typeMessage} showIcon />
              </FormItem>
            )}
            <FormItem>
              <Input
                prefix={<Icon
                type='lock'
                style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Ingrese una contraseña'
                onChange={(event) => setPassword(event.target.value)}
                value={password}
                type='password'
                id='password' />
            </FormItem>
            <FormItem>
              <Input
                prefix={<Icon type='lock'
                style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Repita la contraseña'
                onChange={(event) => setRepeatPassword(event.target.value)}
                value={repeatPassword}
                type='password'
                id='repeatPassword'
              />
            </FormItem>
            <FormItem>
              <a className='login-form-forgot' onClick={onLogin}>Iniciar sesión</a>
            </FormItem>
            <FormItem>
              <Button onClick={() => onSubmit()}>Cambiar la contraseña</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
