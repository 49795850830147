import React from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../../api/index'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Modal,
  Icon
} from 'antd'
import Action from './Action'

const UserList = (props) => {
  const [users, setUsers] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [trade, setTrade] = React.useState([])

  const columns = [
      {title: 'Nombre', dataIndex: 'first_name', key: 'first_name'},
      {title: 'Apellidos', dataIndex: 'last_name', key: 'last_name'},
      {title: 'Último inicio de sesión', dataIndex: 'date_join',key: 'date_join',
        render: (index, record) => {
          return (
            <span>
              {formatDate(record.date_join)}
            </span>
          )
        }
      },{title: 'Activo', dataIndex: 'is_active', key: 'is_active',
        render: (index, record) => {
          return (
            <span>
              {record.is_active ? (
                <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
              ) : (
                <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
              )}
            </span>
          )
        }
      },{title: 'Acción', dataIndex: 'action', key: 'action',
        render: (index, record) => {
          return (
            <span>
              <Tag color='blue' onClick={() => handleViewUser(record._id)}>Ver detalle</Tag>
              <Tag color='orange' onClick={() => handleUpdateUser(record._id)}>Editar</Tag>
              <Action user={record} />
            </span>
          )
        }
      }
    ]

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date)
      return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) +
      ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' +
      ('0' + newDate.getMinutes()).slice(-2)
    }
    return '-------'
  }

  const handleCreateUser = () => {
    if (trade.length > 0) {
      setRedirect('/usermerchant/create')
    } else {
      Modal.error({
        title: 'Creación de un operador',
        content: `No puedes crear un operador porque no tienes un comercio relacionado`
      })
    }
  }

  const handleViewUser = (id) => {
    setRedirect(`/usermerchant/detail/${id}`)
  }

  const handleUpdateUser = (id) => {
    setRedirect(`/usermerchant/update/${id}`)
  }


  const getTrade = async () => {
    const trade = await API.trade.listMerchant(1)
    if (trade.length > 0) {
      const operators = await API.trade.getOperators(trade[0]._id)
      setUsers(operators)
      setTrade(trade[0]._id)
    }
  }

  React.useEffect(() => {
    getTrade()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de operadores</strong></h3>
        </Col>
        <Col offset={8} span={8} style={{ textAlign: 'right' }}>
          <Button onClick={() => handleCreateUser()}>Crear operador</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={users}
        size='small'
        pagination={{
          position: 'none'
        }}
        rowKey={(value) => value._id}
      />
    </div>
  )
}

export default UserList
