import axios from 'axios'
import config from './config'

const URL = `${config.URL}/city`

export async function detail (id) {
  const result = await axios.get(`${URL}/${id}`)
  return result.data
}
export async function list (state) {
  const result = await axios.get(`${URL}/list/active/${state}`, config.configWithToken())
  return result.data
}

export default {
  detail,
  list
}
