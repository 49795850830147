import axios from 'axios'
import config from './config'

const URL = `${config.URL}/topic`

export async function create (data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        form.append([key], data[key])
      }
    }
  })
  const result = await axios.post(`${URL}`, form, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/filter/${page}`, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  const result = await axios.post(`${URL}/change/status`, {
    status: status,
    id: id
  }, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        form.append([key], data[key])
      }
    }
  })
  const result = await axios.post(`${URL}/update/${id}`, form, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const response = await axios.get(`${URL}/search/${page}/${name}`, config.configWithToken())
  return response.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/remove/${id}`, config.configWithToken())
  return result.data
}

export async function ListTrue () {
  const result = await axios.get(`${URL}/active/listAll/true`, config.configWithToken())
  return result.data
}

export default {
  list,
  detail,
  change,
  create,
  update,
  search,
  deletes,
  ListTrue
}
