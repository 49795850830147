import React from 'react'
import { Redirect } from 'react-router-dom'
import { Alert, Form, Button, Input, Icon } from 'antd'
import API from '../../api/index'
import '../../styles/Login.css'

const FormItem = Form.Item

const ResetPassword = (props) => {
  const [email, setEmail] = React.useState('')
  const [message, setMessage] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const onLogin = () => {
    setRedirect('/')
  }

  const onSubmit = async () => {
    setMessage(undefined)
    if (email) {
      await API.resetPassword.resetPassword(email)
      setMessage(`Si existe una cuenta de Gangahoy para ${email}, se enviará un correo electrónico con más instrucciones.`)
    }
  }
  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <div className='center-block-login container-login'>
      <div style={{ minWidth: '415px' }}>
        <div className='text-center'>
          <a onClick={() => onLogin()} className='login-title'>
            <span className='yellow'>Ganga</span> <span className='white'>Hoy</span>
          </a>
          <p className='white'>Restaurar contraseña</p>
        </div>
        <div>
          <Form onSubmit={() => onSubmit()}>
            {message && (
              <FormItem>
                <Alert message={message} type='success' showIcon />
              </FormItem>
            )}
            <FormItem>
              <Input
                prefix={<Icon type='user' style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder='Ingrese su email'
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                type='email'
                id='email' />
            </FormItem>
            <FormItem>
              <a className='login-form-forgot' onClick={() => onLogin()}>Iniciar sesión</a>
            </FormItem>
            <FormItem>
              <Button onClick={() => onSubmit()}>Restablecer la contraseña</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword
