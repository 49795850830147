import React from 'react'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Input,
  Modal
} from 'antd'
import API from '../../api/index'
import { Redirect } from 'react-router-dom'
import Action from './Action'
const Search = Input.Search

const CategoryList = (props) => {
  const [categories, setCategories] = React.useState([])
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [eliminate, setEliminate] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState()
  const type = getItem('type')
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Secciones',
      dataIndex: 'sections',
      key: 'sections',
      render: (index, text) => {
        let names = []
        if (text.length > 0) {
          text.forEach(element => {
            names.push(<Tag color='blue' key={element._id}>{element.name}</Tag>)
          })
        }
        return (
          <span>{names}</span>
        )
       }
    },
    {
      title: 'Activado',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (index, record) => {
        return (
          <span>
            {record ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {
      title: 'Acción',
      dataIndex: '_id',
      key: '_id',
      render: (key, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewCategory(record._id)}>Ver detalle</Tag>
            {(type === 'Administrador' || type === 'supervisor') && (
              <Tag color='orange' onClick={() => handleUpdateCategory(record._id)}>Editar</Tag>
            )}
            {(type === 'Administrador' || type === 'supervisor') && (
              <Action category={record} />
            )}
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
          </span>
        )
      }
    }
  ]

  const category = async (page) => {
    const result = await API.category.list(page)
    setCategories(result)
  }

  const getCategoryByName = async (page) => {
    const category = await API.category.search(page, search)
     setCategories(category) 
  }

  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    category(1)
  }

  const handleDownload = async () => {
    const users = 'category'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleDelete = async (id) => {
    setEliminate(true)
     setDeleteId(id)
  }

  const handleUserDelete = async () => {
    const result = await API.category.deletes(deleteId)
    if (result.nModified === 1) {
       setEliminate(false)
       setDeleteId(undefined)
      Modal.success({
        title: 'La categoría ha sido eliminada exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
     setEliminate(false)
     setDeleteId(undefined)
  }

  const onSearch = () => {
     setIs_search(true)
     setPage(1)
    getCategoryByName(1)
  }

  const handleCreateCategory = () => {
     setRedirect('/category/create')
  }

  const handleViewCategory = (id) => {
     setRedirect(`/category/detail/${id}`)
  }

  const handleUpdateCategory = (id) => {
     setRedirect(`/category/update/${id}`)
  }

  const handlePrevCategory = () => {
    if (page > 1) {
      const pageTemp = page - 1
       setPage(pageTemp)
      category(pageTemp)
    }
  }

  const handleNextCategory = () => {
    if (categories.length >= 10) {
      const pageTemp = page + 1
       setPage(pageTemp)
      category(pageTemp)
    }
  }

  React.useEffect(() => {
    category(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de categorías</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            placeholder='Ingrese nombre de la categoría'
            onSearch={onSearch}
            onChange={(event) => {setSearch(event.target.value)}}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
          {eliminate && (
            <Modal
              title='Alerta'
              visible={eliminate}
              onOk={handleUserDelete}
              onCancel={handleAlertNot}
              okText='Continuar'
              cancelText='Cancelar'
            >
              <p>¿Desea eliminar esta categoría?</p>
            </Modal>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {type === 'Administrador' && (
            <Button onClick={() => handleDownload()}>Descargar csv</Button>
          )}
          {' '}
          <Button onClick={() => handleCreateCategory()}>Crear categoría</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={categories}
        size='small'
        pagination={{position: 'none'}}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrevCategory()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={categories.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={() => handleNextCategory()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default CategoryList
