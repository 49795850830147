import React, { Component } from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const GoogleMapComponent = withGoogleMap(props =>
  (<GoogleMap
    zoom={18}
    center={{ lat: props.lat, lng: props.lng }}
    onClick={(event) => (props.handleMaps(event.latLng.lat(), event.latLng.lng()))}
  >
    <Marker
      position={{lat: props.lat, lng: props.lng}}
    />
  </GoogleMap>)
)

class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lat: props.lat || 4.6979319,
      lng: props.lng || -74.0780855
    }
  }

  componentWillReceiveProps (newProps) {
    if ((newProps.lat !== this.state.lat) && (newProps.lng !== this.state.lng)) {
      this.setState({
        lat: newProps.lat,
        lng: newProps.lng
      })
    }
  }

  render () {
    return (
      <GoogleMapComponent
        lat={this.state.lat}
        lng={this.state.lng}
        handleMaps={this.props.handleMaps}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    )
  }
}

export default Map
