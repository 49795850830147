import axios from 'axios'
import config from './config'

const URL_USER = `${config.URL}/account`
const URL = `${config.URL}/trade`

export async function create (data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        if (key === 'categories') {
          for (let index = 0; index < data[key].length; index++) {
            const id = data[key][index]
            form.append('categories[]', id)
          }
        } else {
          if (key === 'support_type') {
            for (let index = 0; index < data[key].length; index++) {
              const id = data[key][index]
              form.append('support_type[]', id)
            }
          } else {
            form.append([key], data[key])
          }
        }
      }
    }
  })
  const result = await axios.post(`${URL}`, form, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/${page}`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key] || data[key] === 0) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        if (key === 'categories') {
          for (let index = 0; index < data[key].length; index++) {
            const id = data[key][index]
            form.append('categories[]', id)
          }
        } else {
          if (key === 'support_type') {
            for (let index = 0; index < data[key].length; index++) {
              const id = data[key][index]
              form.append('support_type[]', id)
            }
          } else {
            form.append([key], data[key])
          }
        }
      }
    }
  })
  const result = await axios.post(`${URL}/update/${id}`, form, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else if (status === false) {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/change/status`, {
    id: id,
    status: status
  }, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const result = await axios.get(`${URL}/search/admin/${page}/${name}`, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/trade/${id}`, config.configWithToken())
  return result.data
}

export async function listMerchant () {
  const result = await axios.post(`${URL}/user/list`, {}, config.configWithToken())
  return result.data
}

export async function listBranchMerchant (id, page) {
  const result = await axios.post(`${URL}/merchant/branch/list/${id}/${page}`, {}, config.configWithToken())
  return result.data
}

export async function ListTrue () {
  const result = await axios.get(`${URL}/list/${true}`, config.configWithToken())
  return result.data
}

export async function getOperators (trade) {
  const result = await axios.get(`${URL_USER}/trader/${trade}/operator/list`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  detail,
  update,
  change,
  search,
  deletes,
  listMerchant,
  listBranchMerchant,
  ListTrue,
  getOperators
}
