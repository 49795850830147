import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Modal
} from 'antd'
import API from '../../../api/index'
import Action from './Action'


const BranchList = (props) => {
  const id = props.id
  const [redirect, setRedirect] = React.useState(undefined)
  const [branches, setBranches] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [name, setName] = React.useState(undefined)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Nombre', dataIndex: 'name', key: 'name'},
    {title: 'Ciudad', dataIndex: 'cityName', key: 'cityName'},
    {title: 'Productos', dataIndex: 'total_products', key: 'totalProducts'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action', 
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewBranch(record._id)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => handleUpdateBranch(record._id)}>Editar</Tag>
            <Tag color='green' onClick={() => handleProducts(record._id)}>Productos</Tag>
            <Tag color='#254A90' onClick={() => handleVentas(record._id, id)}>Ventas</Tag>
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
            <Tag color='#52c41a' onClick={() => onReport(record._id)}>Reportes</Tag>
            <Action branch={record} />
          </span>
        )
      }
    }
  ]

  const onReport = (_id) => {
    setRedirect(`/branch/report/${_id}`)
  }

  const onBack = () => {
    setRedirect('/trade')
  }

  const handleDelete = async (id) => {
    setDelete(true)
    setDeleteId(id)
  }

  const handleUserDelete = async () => {
    const result = await API.branchOffice.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El usuario ha sido eliminado exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const branch = async (page, id) => {
    const response = await API.branchOffice.trade(page, id)
    setBranches(response)
  }

  const handleDownload = async () => {
    const users = 'branch'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleCreateBranch = () => {
    setRedirect(`/branch/create/${id}`)
  }

  const handleViewBranch = (id) => {
    setRedirect(`/branch/detail/${id}`)
  }

  const handleUpdateBranch = (id) => {
    setRedirect(`/branch/update/${id}`)
  }

  const handleVentas = (idbranch, idtrade) => {
    setRedirect(`/branch/sales/${idbranch}/${idtrade}`)
  }

  const handleProducts = (branch) => {
    setRedirect(`/products/${branch}`)
  }

  const handlePrevBranch = () => {
    if (page >= 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      branch(pageTemp, id)
    }
  }

  const handleNextBranch = () => {
    if (branches.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      branch(pageTemp, id)
    }
  }

  const trade = async (id) => {
    const detailTrade = await API.trade.detail(id)
    setName(detailTrade.trade_name)
  }

  React.useEffect(() => {
    branch(page, id)
    trade(id)
  })

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Sucursales de {name}</strong></h3>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownload()}>Descargar csv</Button>
          {' '}
          <Button onClick={() => onBack()}>Regresar</Button>
          {' '}
          <Button onClick={() => handleCreateBranch()}>Crear sucursal</Button>
        </Col>
        {Delete && (
          <Modal
            title='Alerta'
            visible={Delete}
            onOk={() => handleUserDelete()}
            onCancel={() => handleAlertNot()}
            okText='Continuar'
            cancelText='Cancelar'
          >
            <p>¿Desea eliminar esta sucursal?</p>
          </Modal>
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={branches}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li 
            className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : 
            ('ant-pagination-prev')}
          >
            <a onClick={() => handlePrevBranch()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li 
            className={branches.length >= 10 ? ('ant-pagination-next') : 
            ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNextBranch()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BranchList
