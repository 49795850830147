import React from 'react'
import InventoryList from './List'
import { Layout } from 'antd'
import Header from '../../../../../Header'

const { Content } = Layout

const Inventory = (props) => {
  const id = props.match.params.id
  const name = props.match.params.name
  const branch = props.match.params.branch
  const trade = props.match.params.trade

  return (
    <Layout className='layout'>
      <Header item='branch' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <InventoryList id={id} name={name} branch={branch} trade={trade} />
        </div>
      </Content>
    </Layout>
  )
}

export default Inventory
