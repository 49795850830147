import React from 'react'
import {
  Table,
  Row,
  Col,
  Button,
  Icon,
  Layout,
  Tag,
  InputNumber
} from 'antd'
import API from '../../../../api/index'
import Header from '../../../Header'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../../../api/utils'

const { Content } = Layout

const BillMerchant = (props) => {
  const id = props.match.params.idbranch
  const idtrade = props.match.params.idtrade
  const [bill,setBill] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [redirect, setRedirect] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Usuario', dataIndex: 'userName', key: 'userName'},
    {title: 'Método de pago', dataIndex: 'pay_mode', key: 'pay_mode',
      render: (text, record) => {
        let response
        if (record.pay_mode === 'buy_site') {
          response = 'Lugar'
        } else if (record.pay_mode === 'buy_online') {
          response = 'Online'
        } else if (record.pay_mode === 'buy_points') {
          response = 'Compra con puntos'
        }
        return (
          <span>{response}</span>
        )
      }
    },{title: 'Precio total', dataIndex: 'totalPriceBill', key: 'totalPriceBill',
      render: (text, record) => {
        return (
          <span>
            {record.totalPriceBill && (
              <InputNumber
                value={record.totalPriceBill}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },{title: 'Pagó', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {!record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Fecha de pago', dataIndex: 'update_date', key: 'update_date',
      render: (text, record) => {
        const date = new Date(record.start_date)
        const dateString = ('0' + date.getDate()).slice(-2) + ' de ' + getMonth(date.getMonth()) + ' del ' + date.getFullYear() + ', ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        return (
          <span>{!record.is_active && (dateString)}</span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleView(record._id, idtrade)}>Ver detalle</Tag>
          </span>
        )
      }
    }
  ]  

  const getMonth = (index) => {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    return months[index]
  }

  const onBack = (url) => {
    setRedirect(url)
  }

  const handlePrevBranch = () => {
    if (page >= 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
    }
  }

  const handleNextBranch = () => {
    if (bill.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
    }
  }

  const handleView = (id, idTrade) => {
    setRedirect(`/billmerchant/detail/${id}/${idTrade}`)
  }

  const getBill = async (id) => {
    const result = await API.bill.branch(id, page)
    setBill(result)
  }

  React.useEffect(() => {
    getBill(id)
  })

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout'>
      <Header item='branch' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Listado de Ventas</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              {type === 'Empresario' && (
                <Button onClick={() => onBack(`/branchesmerchant/list/${idtrade}`)}>Regresar</Button>
              )}
              {type === 'operador' && (
                <Button onClick={() => onBack('/branchesoperator')}>Regresar</Button>
              )}
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={bill}
            size='small'
            pagination={{
              position: 'none'
            }}
          />
          <div style={{
            textAlign: 'right',
            marginTop: '10px'
          }}>
            <ul className='ant-pagination '>
              <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
                <a onClick={() => handlePrevBranch()}>Anterior</a>
              </li>
              <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
                {page}
              </li>
              <li className={bill.length >= 10 ? ('ant-pagination-next') 
                : ('ant-pagination-disabled ant-pagination-next')}
              >
                <a onClick={() => handleNextBranch()}>Siguiente</a>
              </li>
            </ul>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default BillMerchant
