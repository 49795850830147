import axios from 'axios'
import config from './config'
const URL = `${config.URL}/download/csv`

export async function download (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/backups/${page}`, config.configWithToken())
  return result.data
}

export default {
  download,
  list
}
