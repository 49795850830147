import axios from 'axios'
import config from './config'

const URL = `${config.URL}/configuration`

export async function create (data) {
  const result = await axios.post(`${URL}/create`, data, config.configWithToken())
  return result.data
}

export async function detail () {
  const result = await axios.get(`${URL}/detail`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export default {
  create,
  detail,
  update
}
