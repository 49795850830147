import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout

const CategoryCreate = (props) => {
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [sections, setSections] = React.useState([])
  const [errorSections, setErrorSections] = React.useState(undefined)
  const [listSections, setListSections] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)


  const onBack = () => {
    setRedirect('/category')
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.category.create({
      name: name,
      sections: sections
    })

    if (response._id) {
      Modal.success({
        title: 'Creación de una categoría',
        content: `La categoría ${name} fue creada exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.sections) {
      setErrorSections(error.sections.message)
    }
  }

  const clearFields = () => {
    setName(undefined)
    setSections(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorSections(undefined)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const section = async () => {
    const listSections = await API.sections.product()
    setListSections(listSections)
  }

  React.useEffect(() => {
    section()
  }, [])

  if (redirect) {
    return (
     <Redirect to={redirect} />
    )
  }

  const ListSections = createRow(listSections)

  return (
    <Layout className='layout'>
      <Header item='category' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nueva categoría</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese nombre de la categoría'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Escoger una sección*'
                  validateStatus={errorSections && ('error')}
                  help={errorSections}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una sección'
                    onChange={(value) => setSections(value)}
                    value={sections}
                  >
                    {ListSections}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default CategoryCreate
