import axios from 'axios'
import config from './config'

const URL = `${config.URL}/inventory`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function list (id, page) {
  const result = await axios.get(`${URL}/list/${id}/${page}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}/`, data, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  detail,
  update
}
