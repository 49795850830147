import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Layout,
  Table
} from 'antd'
import Header from '../../Header'
import Maps from '../../maps/Maps'
import API from '../../../api/index'

const Content = Layout.Content

const BranchDetail = (props) => {
  const [branch, setBranch] = React.useState({})
  const [redirect, setRedirect] = React.useState(undefined)
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const id = props.match.params.id

  const  columns = [
    {title: 'Nombre', dataIndex: 'first_name', key: 'first_name'},
    {title: 'Apellidos', dataIndex: 'last_name', key: 'last_name'},
    {title: 'Teléfono', dataIndex: 'cellphone', key: 'cellphone'}
  ]

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio',
    'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) +
     ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + 
     ('0' + newDate.getMinutes()).slice(-2)
  }

  const getBranch = async () => {
    const response = await API.branchOffice.detail(id)
      setStart_date(formatDate(response.start_date))
      setUpdate_date(formatDate(response.update_date))
      setBranch(response)
  }

  React.useEffect(() => {
    getBranch()
  }, [])

  const onBack = () => {
    setRedirect(`/branches/list/${branch.idTrade._id}`)
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  let lat, lng
  if (branch.gps) {
    let gps = branch.gps
    lat = gps[0]
    lng = gps[1]
  }
  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle de la sucursal</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={onBack}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre del comercio'
                >
                  <Input
                    disabled
                    value={`${branch.idTrade.nit} - ${branch.idTrade.trade_name}`}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={branch.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Teléfono'
                >
                  <Input
                    disabled
                    value={branch.cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='País'
                >
                  <Input
                    disabled
                    value={branch.country.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Estado'
                >
                  <Input
                    disabled
                    value={branch.state.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Ciudad'
                >
                  <Input
                    type='text'
                    disabled
                    value={branch.city.name}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Dirección'
                >
                  <Input
                    disabled
                    value={branch.address}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              {lat && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Ubicación'
                  >
                    <Maps lat={lat} lng={lng} handleMaps={() => null} />
                  </Form.Item>
                </Col>
              )}
              <Col span={15} offset={1}>
                <Form.Item
                  label='Operadores'
                >
                  <Table columns={columns} dataSource={branch.users} rowKey={(value) => value._id} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default BranchDetail
