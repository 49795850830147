import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Layout,
  Select
} from 'antd'
import API from '../../../api/index'
import Header from '../../Header'

const { Content } = Layout

const AsistantCreate = (props) => {
  const { push } = useHistory()
  const id = props.match.params.topic
  const [fullName, setFullName] = React.useState('')
  const [errorFullName, setErrorFullName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [errorEmail, setErrorEmail] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [errorCellphone, setErrorCellphone] = React.useState('')
  const [payment, setPayment] = React.useState('false')

  const [topic, setTopic] = React.useState({})

  const onSubmit = async () => {
    clearErrors()
    const response = await API.assistant.create({
      topic: id,
      fullName: fullName,
      email: email,
      cellphone: cellphone,
      payment: payment
    })
    if (response._id) {
      Modal.success({
        title: 'Creación del asistente',
        content: `El asistente fue creado exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.fullName) {
      setErrorFullName(error.fullName.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
  }

  const clearFields = () => {
    setFullName('')
    setEmail('')
    setCellphone('')
    setPayment('false')
    clearErrors()
  }

  const clearErrors = () => {
    setErrorFullName('')
    setErrorEmail('')
    setErrorCellphone('')
  }

  const get_topic = async () => {
    const response = await API.topic.detail(id)
    setTopic(response)
  }

  React.useEffect(() => {
    get_topic()
  }, [])

  return (
    <Layout className='layout' >
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Creación del asistente</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => push(`/topic/assistant/${id}`)}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Nombre de la feria'>
                  <Input
                    type='text'
                    disabled
                    value={topic ? topic.name : ''}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre del asistente'
                  validateStatus={errorFullName && ('error')}
                  help={errorFullName}
                >
                  <Input
                    type='text'
                    value={fullName}
                    onChange={(event) => setFullName(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Correo electrónico'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='email'
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nº de celular'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='number'
                    value={cellphone}
                    onChange={(event) => setCellphone(event.target.value)}
                  />
                </Form.Item>
              </Col>
              {(topic.type === 'payment') && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='¿Realizó el pago?*'
                  >
                    <Select
                      showSearch
                      style={{ width: '100%' }}
                      onChange={(value) => setPayment(value)}
                      value={payment}
                    >
                      <Select.Option value={'false'}>No</Select.Option>
                      <Select.Option value={'true'}>Si</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default AsistantCreate
