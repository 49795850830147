import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  DatePicker,
  Row,
  Col
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'

const { Content } = Layout
const dateFormat = 'YYYY/MM/DD'

const OperatorCreate = (props) => {
  const id = props.match.params.id
  const [first_name, setFirst_name] = React.useState(undefined)
  const [errorFirstName, setErrorFirstName] = React.useState(undefined)
  const [last_name, setLast_name] = React.useState(undefined)
  const [errorLastName, setErrorLastName] = React.useState(undefined)
  const [type_user, setType_user] = React.useState('operador')
  const [email, setEmail] = React.useState(undefined)
  const [errorEmail, setErrorEmail] = React.useState(undefined)
  const [address, setAddress] = React.useState(undefined)
  const [errorAddress, setErrorAddress] = React.useState(undefined)
  const [birthdate, setBirthdate] = React.useState(undefined)
  const [errorBirthdate, setErrorBirthdate] = React.useState(undefined)
  const [gps, setGps] = React.useState(undefined)
  const [allow_gps, setAllow_gps] = React.useState(true)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [gender, setGender] = React.useState(undefined)
  const [errorGender, setErrorGender] = React.useState(undefined)
  const [password, setPassword] = React.useState(undefined)
  const [errorPassword, setErrorPassword] = React.useState(undefined)
  const [repeatPassword, setRepeatPassword] = React.useState(undefined)
  const [errorRepeatPassword, setErrorRepeatPassword] = React.useState(undefined)
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [trade_name, setTrade_name] = React.useState(undefined)

  const getTrade = async () => {
    const trade = await API.trade.detail(id)
    if (trade.trade_name) {
      setTrade_name(trade.trade_name)
    } else {
      setRedirect('/home')
    }
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const getCountries = async () => {
    const countries = await API.country.list()
    setCountries(countries)
  }

  const getStates = async (country = country) => {
    if (country) {
      const states = await API.state.list(country)
      setStates(states)
    }
  }

  const getCities = async (state = state) => {
    if (state) {
      const cities = await API.city.list(state)
      setCities(cities)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    if (password === repeatPassword) {
      const response = await API.user.create({
        first_name: first_name,
        last_name: last_name,
        type_user: type_user,
        email: email,
        address: address,
        birthdate: birthdate,
        gps: gps,
        allow_gps: allow_gps,
        city: city,
        cellphone: cellphone,
        image: image,
        gender: gender,
        password: password,
        trade: id
      })
      if (response._id) {
        Modal.success({
          title: 'Creación de un usuario',
          content: `El usuario ${first_name} fue creado exitosamente`
        })
        clearFields()
      }
      if (response.errors) {
        makeErrors(response.errors)
      }
    } else {
      setErrorRepeatPassword('Las contraseñas no coinciden, por favor intente de nuevo.')
    }
  }

  const makeErrors = (error) => {
    if (error.first_name) {
      setErrorFirstName(error.first_name.message)
    }
    if (error.last_name) {
      setErrorLastName(error.last_name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.password) {
      setErrorPassword(error.password.message)
    }
    if (error.birthdate) {
      setErrorBirthdate(error.birthdate.message)
    }
    if (error.gender) {
      setErrorGender(error.gender.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
  }

  const clearFields = () => {
    setFirst_name(undefined)
    setLast_name(undefined)
    setType_user(undefined)
    setEmail(undefined)
    setAddress(undefined)
    setBirthdate(undefined)
    setGps(undefined)
    setAllow_gps(true)
    setCity(undefined)
    setState(undefined)
    setCountry(undefined)
    setCellphone(undefined)
    setImage(undefined)
    setGender(undefined)
    setPassword(undefined)
    setRepeatPassword(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorFirstName(undefined)
    setErrorLastName(undefined)
    setErrorImage(undefined)
    setErrorCity(undefined)
    setErrorPassword(undefined)
    setErrorRepeatPassword(undefined)
    setErrorBirthdate(undefined)
    setErrorGender(undefined)
    setErrorAddress(undefined)
    setErrorEmail(undefined)
    setErrorCellphone(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleChangeUpload = async (file) => {
    setImage(file.file)
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const handleChangeCountry = async (value) => {
    setCountry(value)
    getStates(value)
  }

  const handleChangeState = async (value) => {
    setState(value)
    getCities(value)
  }

  const handleChangeBirthdate = async (value) => {
    setErrorBirthdate(undefined)
    if (value) {
      let date = new Date()
      date.setFullYear(date.getFullYear() - 14)
      const dateSelect = new Date(value.format('YYYY-MM-DD'))
      if (date.getTime() > dateSelect.getTime()) {
        setBirthdate(value)
      } else {
        setErrorBirthdate('La fecha de nacimiento seleccionada no es permitida')
      }
    } else {
      setBirthdate(undefined)
    }
  }

  const onBack = async () => {
    setRedirect(`/trade/${id}/operator/list/`)
  }

  React.useEffect(() => {
    getTrade()
    getCountries()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const Country = createRow(countries)
  const State = createRow(states)
  const City = createRow(cities)

  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nuevo operador</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorFirstName && ('error')}
                  help={errorFirstName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre del usuario'
                    onChange={(event) => setFirst_name(event.target.value)}
                    value={first_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Apellidos*'
                  validateStatus={errorLastName && ('error')}
                  help={errorLastName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese los apellidos del usuario'
                    onChange={(event) => setLast_name(event.target.value)}
                    value={last_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Foto*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()} />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='País*'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {Country}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Estado*'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => handleChangeState(value)}
                    value={state}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {State}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad*'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => setCity(value)}
                    value={city}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {City}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Correo electrónico*'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='email'
                    placeholder='Ingrese el correo del usuario'
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Contraseña*'
                  validateStatus={errorPassword && ('error')}
                  help={errorPassword}
                >
                  <Input
                    type='password'
                    placeholder='Ingrese la contraseña del usuario'
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Repetir Contraseña*'
                  validateStatus={errorRepeatPassword && ('error')}
                  help={errorRepeatPassword}
                >
                  <Input
                    type='password'
                    placeholder='Repita la contraseña del usuario'
                    onChange={(event) => setRepeatPassword(event.target.value)}
                    value={repeatPassword}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de nacimiento*'
                  validateStatus={errorBirthdate && ('error')}
                  help={errorBirthdate}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    format={dateFormat}
                    value={birthdate}
                    onChange={(date, dateString) => handleChangeBirthdate(date)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Género*'
                  validateStatus={errorGender && ('error')}
                  help={errorGender}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setGender(value)}
                    value={gender}
                    placeholder='Seleccione un género'
                  >
                    <Select.Option value='masculino'>Masculino</Select.Option>
                    <Select.Option value='femenino'>Femenino</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono*'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el teléfono del usuario'
                    value={cellphone}
                    onChange={(event) => setCellphone(event.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la dirección del usuario'
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Comercio'
                >
                  <Input
                    type='text'
                    disabled
                    value={trade_name}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default OperatorCreate
