import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../../../../api/utils'
import {
  Table,
  Row,
  Col,
  Tag,
  Button,
  Icon,
  Input,
  InputNumber
} from 'antd'
import API from '../../../../../api/index'
const Search = Input.Search

const ProductsListMerchant = (props) => {
  const id = props.id
  const trade = props.trade
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const [page, setPage] = React.useState(1)
  const [products, setProducts] = React.useState([])
  const [result, setResult] = React.useState([])
  const type = getItem('type')
  const [redirect, setRedirect] = React.useState(undefined)
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Nombre', dataIndex: 'name', key: 'name'},
    {title: 'Categorias', dataIndex: 'nameCategories', key: 'nameCategories'},
    {title: 'Precio', dataIndex: 'price', key: 'price',
      render: (text, record) => {
        return (
          <span>
            {record.price && (
              <InputNumber
                value={record.price}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },{title: 'Promoción', dataIndex: 'is_promotion', key: 'is_promotion',
      render: (text, record) => {
        return (
          <span>
            {record.is_promotion ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Calificación', dataIndex: 'qualification', key: 'qualification',
      render: (text, record) => {
        let star = []
        for (let index = 0; index < record.qualification; index++) {
          star.push(<Icon type='star' theme='filled' style={{ color: 'yellow' }} />)
        }
        return (
          <span>
            {record.qualification ? (
              <p>{star.map((value, index) => {
                return value
              })}</p>
            ) : (
              <span>---</span>
            )}
          </span>
        )
      }
    },{title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewProducts(record._id)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => handleUpdateProducts(record._id)}>Editar</Tag>
            <Tag color='#1B6300' onClick={() => handleInventory(record._id, record.name)}>Inventario</Tag>
          </span>
        )
      }
    }
  ]

  const Products = async (page) => {
    const result = await API.products.listBranch(id, page)
    setProducts(result)
  }

  const getProductsByName = async (page) => {
    const result = await API.products.search(page, search)
    setProducts(result)
  }

  const handleCreateProducts = () => {
    setRedirect(`/productsmerchant/create/${id}`)
  }

  const handleViewProducts = (id) => {
    setRedirect(`/productmerchant/detail/${id}/${id}/${trade}`)
  }

  const handleUpdateProducts = (id) => {
    setRedirect(`/productmerchant/update/${id}/${id}/${trade}`)
  }

  const handleInventory = (id, name) => {
    setRedirect(`/inventorymerchant/${id}/${name}/${id}/${trade}`)
  }

  const handlePrevProduct = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      Products(pageTemp)
    }
  }

  const handleNextProduct = () => {
    if (products.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      Products(pageTemp)
    }
  }

  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    Products(1)
  }

  const onSearch = () => {
    setIs_search(true)
    setPage(1)
    getProductsByName(1)
  }

  const onBack = async () => {
    if (type === 'Empresario') {
      setRedirect(`/branchesmerchant/list/${trade}`)
    } else {
      setRedirect(`/branchesoperator`)
    }
  }

  React.useState(() => {
    Products(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de productos</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            placeholder='Ingrese nombre del producto'
            onSearch={onSearch}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={handleClearSearch}
            >
              Limpiar busqueda
            </Tag>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button onClick={onBack}>Regresar</Button>
          {' '}
          <Button onClick={handleCreateProducts}>Crear producto</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={products}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={handlePrevProduct}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={products.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={handleNextProduct}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default ProductsListMerchant
