import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Modal
} from 'antd'
import API from '../../../api/index'
import Action from './Action'

const BusinessList = (props) => {
  const [business, setBusiness] = React.useState([])
  const [page,setPage] = React.useState(1)
  const [name, setName] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const id = props.id
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key' },
    {title: 'Comercio', dataIndex: 'tradeName', key: 'tradeName'},
    {title: 'Empresario', dataIndex: 'userFullName', key: 'userFullName'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active', 
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Action business={record} />
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
          </span>
        )
      }
    }
  ]

  const onBack = () => {
    setRedirect('/topic')
  }

  const handleDelete = async (id) => {
    setDelete(true)
    setDeleteId(id)
  }

  const handleBusinessDelete = async () => {
    const result = await API.business.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El negocio ha sido eliminado exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const get_business = async (page, id) => {
    const response = await API.business.listTopic(page, id)
    setBusiness(response)
  }

  const handleDownload = async () => {
    const users = 'business'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleCreateBusiness = () => {
    setRedirect(`/business/create/${id}`)
  }

  const handleViewBusiness = (id) => {
    setRedirect(`/business/detail/${id}/${id}`)
  }

  const handleUpdateBusiness = (id) => {
    setRedirect(`/business/update/${id}/${id}`)
  }

  const handlePrev = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      get_business(pageTemp, id)
    }
  }

  const handleNext = () => {
    if (business.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      get_business(pageTemp, id)
    }
  }

  const topic = async (id) => {
    const detail = await API.topic.detail(id)
    setName(detail.name)
  }

  React.useEffect(() => {
    get_business(page, id)
    topic(id)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Negocios de la feria {name}</strong></h3>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: 'right' }}>
          {(type === 'Administrador' || type === 'supervisor') && (
            <Button onClick={() => handleDownload()}>Descargar csv</Button>
          )}
          {' '}
          <Button onClick={() => onBack()}>Regresar</Button>
          {' '}
          <Button onClick={() => handleCreateBusiness()}>Crear negocío</Button>
        </Col>
        {Delete && (
          <Modal
            title='Alerta'
            visible={Delete}
            onOk={() => handleBusinessDelete()}
            onCancel={() => handleAlertNot()}
            okText='Continuar'
            cancelText='Cancelar'
          >
            <p>¿Desea eliminar esta negocío?</p>
          </Modal>
        )}
      </Row>
      <Table
        columns={columns}
        dataSource={business}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : 
            ('ant-pagination-prev')}
          >
            <a onClick={() => handlePrev()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li
            className={business.length >= 10 ? ('ant-pagination-next') : 
            ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNext()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}
export default BusinessList