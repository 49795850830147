import React from 'react'
import { Redirect } from 'react-router-dom'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Input,
  Modal
} from 'antd'
import Action from './Action'
const Search = Input.Search

const TradeList = (props) => {
  const [tradeAll, setTradeAll] = React.useState([])
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const [redirect, setRedirect] = React.useState(undefined)
  const [page, setPage] = React.useState(1)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'NIT', dataIndex: 'nit', key: 'nit'},
    {title: 'Nombre', dataIndex: 'trade_name', key: 'trade_name'},
    {title: 'Soporte', dataIndex: 'supportType', key: 'supportType'},
    {title: 'Tarjeta Crédito', dataIndex: 'creditCards', key: 'creditCards',
      render: (text, record) => {
        return (
          <span>
            {record.creditCards ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='orange' onClick={() => handleUpdateTrade(record._id)}>Editar</Tag>
            <Tag color='blue' onClick={() => handleViewTrade(record._id)}>Ver detalle</Tag>
            {(type === 'Administrador' || type === 'supervisor') && (
              <React.Fragment>
                <Tag color='purple' onClick={() => redirectViewOperatorList(record._id)}>Ver operadores</Tag>
                <Tag color='cyan' onClick={() => redirectViewBranch(record._id)}>Ver sucursal</Tag>
              </React.Fragment>
            )}
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
            <Action trade={record} />
          </span>
        )
      }
    }
  ]

  const handleDownload = async () => {
    const users = 'trade'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleDelete = async (id) => {
    setDelete(true)
    setDeleteId(id)
  }
  
  const handleUserDelete = async () => {
    const result = await API.trade.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'El comercio ha sido eliminado exitosamente'
      })
    }
  }
  
  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }
  
  const getTrade = async (page) => {
    const trade = await API.trade.list(page)
    setTradeAll(trade)
  }
  
  const getTradeByName = async (page) => {
    const trade = await API.trade.search(page, search)
    setTradeAll(trade)
  }
  
  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    getTrade(1)
  }
  
  const onSearch = () => {
    setIs_search(true)
    setPage(1)
    getTradeByName(1)
  }
  
  const handleCreateTrade = () => {
    setRedirect('/trade/create')
  }
  
  const handleViewTrade = (id) => {
    setRedirect(`/trade/detail/${id}`)
  }
  
  const handleUpdateTrade = (id) => {
    setRedirect(`/trade/update/${id}`)
  }
  
  const redirectViewBranch = (id) => {
    setRedirect(`/branches/list/${id}`)
  }
  
  const redirectViewOperatorList = (id) => {
    setRedirect(`/trade/${id}/operator/list/`)
  }
  
  const handlePrevTrade = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      getTrade(pageTemp)
    }
  }
  
  const handleNextTrade = () => {
    if (tradeAll.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      getTrade(pageTemp)
    }
  }
  
  React.useEffect(() => {
    getTrade(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de comercios</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            placeholder='Ingrese nombre del comercio'
            onSearch={() => onSearch()}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
          {Delete && (
            <Modal
              title='Alerta'
              visible={Delete}
              onOk={() => handleUserDelete()}
              onCancel={() => handleAlertNot()}
              okText='Continuar'
              cancelText='Cancelar'
            >
              <p>¿Desea eliminar este comercio?</p>
            </Modal>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {(type === 'Administrador' || type === 'supervisor') && (
            <Button onClick={() => handleDownload()}>Descargar csv</Button>
          )}
          {' '}
          <Button onClick={() => handleCreateTrade()}>Crear comercio</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={tradeAll}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li
            className={
              page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')
            }
          >
            <a onClick={() => handlePrevTrade()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li
            className={
              tradeAll.length >= 10 ? ('ant-pagination-next') :
              ('ant-pagination-disabled ant-pagination-next')
            }
          >
            <a onClick={() => handleNextTrade()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default TradeList
