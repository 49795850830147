import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Layout,
  Modal,
  Button,
  Form,
  Input,
  DatePicker,
  Select,
  InputNumber
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'

const { Content } = Layout
const { TextArea } = Input
const dateFormat = 'YYYY/MM/DD'


const TradeCreate = (props) => {
  const id = props.match.params.id
  const [trades, setTrades] = React.useState(undefined)
  const [listTrade, setListTrade] = React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [errorTrade, setErrorTrade] = React.useState(undefined)
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [discount, setDiscount] = React.useState(undefined)
  const [errorDiscount, setErrorDiscount] = React.useState(undefined)
  const [idCity, setIdCity] = React.useState([])
  const [idCountry, setIdCountry] = React.useState([])
  const [idState, setIdState] = React.useState([])
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [initDay, setInitDay] = React.useState(undefined)
  const [errorInitDay, setErrorInitDay] = React.useState(undefined)
  const [finishDay, setFinishDay] = React.useState(undefined)
  const [errorFinishDay, setErrorFinishDay] = React.useState(undefined)
  const [category, setCategory] = React.useState([])
  const [errorCategory, setErrorCategory] = React.useState(undefined)
  const [idCategory, setIdCategory] = React.useState([])


  const onBack = () => {
    setRedirect(`/loyalty/trade/${id}`)
  }

  const idcategory = async () => {
    const IdCategory = await API.category.ListLoyalty(id)
    setIdCategory(IdCategory)
  }

  const onSubmit = async () => {
    clearErrors()
    if (trades && id && city && description && discount &&
      initDay && finishDay && (category.length > 0)) {
      const date = initDay
      const finishDate = finishDay
      if (((date._d.getTime() + 360000) >= Date.now()) && (finishDate._d.getTime() > date._d.getTime())) {
        const result = await API.relation.create({
          loyalties: id,
          trade: trades,
          city: city,
          description: description,
          discount: discount,
          initDay: initDay,
          finishDay: finishDay,
          categories: category
        })
        if (result._id) {
          Modal.success({
            title: 'Relacion de programa de fidelidad',
            content: `El programa de fidelidad ${name} fue relacionado exitosamente`
          })
          clearData()
        }
        if (result.errors) {
          if (result.errors.initDay) {
            setErrorInitDay(result.errors.initDay.message)
          }
          if (result.errors.trade) {
            setErrorTrade(result.errors.trade.message)
            setErrorCategory(result.errors.trade.message)
          }
        }
      } else {
        if (date._d.getTime() < Date.now()) {
          setErrorInitDay('La fecha inicial no puede ser igual o inferior que la actual')
        }
        if (finishDate._d.getTime() < date._d.getTime()) {
          setErrorFinishDay('La fecha final no puede ser menor que la fecha de inicial')
        }
      }
    } else {
      if (trades === undefined) {
        setErrorTrade('El comercio es obligatorio')
      }
      if (city === undefined) {
        setErrorCity('La ciudad es obligatorio')
      }
      if (description === undefined) {
        
        setErrorDescription('La descripción es obligatorio')
      }
      if (discount === undefined) {
        
        setErrorDiscount('El descuento es obligatorio')
      }
      if (initDay === undefined) {
        
        setErrorInitDay('La fecha inicial es obligatorio')
      }
      if (finishDay === undefined) {
        
        setErrorFinishDay('La fecha final es obligatorio')
      }
      if (category.length === 0) {
        
        setErrorCategory('Las categorias son obligatorias')
      }
    }
  }

  const clearData = () => {    
    setTrades(undefined)    
    setCity(undefined)    
    setCountry(undefined)    
    setState(undefined)    
    setDescription(undefined)    
    setDiscount(undefined)    
    setFinishDay(undefined)    
    setInitDay(undefined)    
    setCategory(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    
    setErrorTrade(undefined)    
    setErrorCity(undefined)    
    setErrorDescription(undefined)    
    setErrorFinishDay(undefined)    
    setErrorInitDay(undefined)    
    setErrorDiscount(undefined)    
    setErrorCategory(undefined)
  }

  const _country = async () => {
    const response = await API.country.list()
    setIdCountry(response)
    stateC()
  }

  const stateC = async (country = country) => {
    if (country) {
      setCountry(country)
      const idState = await API.state.list(country)
      setIdState(idState)
    }
  }

  const cityC = async (state = state) => {
    if (state) {
      setState(state)
      const idCity = await API.city.list(state)
      setIdCity(idCity)
    }
  }

  const createRowTrade = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.trade_name}</Select.Option>)
    })
    return row
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const trade = async () => {
    const listTrade = await API.trade.ListTrue()
    setListTrade(listTrade)
  }

  const loyalty = async (id) => {
    const loyalty = await API.loyalty.detail(id)
    setName(loyalty.name)
  }

  React.useEffect(() => {
    _country()
    trade()
    loyalty(id)
    idcategory()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  const ListTrade = createRowTrade(listTrade)
  const City = createRow(idCity)
  const State = createRow(idState)
  const countries = createRow(idCountry)
  const Category = createRow(idCategory)
  return (
    <Layout className='layout'>
      <Header item='loyaty' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Relacion de un programa de fidelidad con comercios</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Escoger un comercio'
                  validateStatus={errorTrade && ('error')}
                  help={errorTrade}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un comercio'
                    onChange={(value) => setTrades(value)}
                    value={trades}
                  >
                    {ListTrade}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Programa de fidelidad'
                >
                  <Input
                    disabled
                    placeholder={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    name='description'
                    placeholder='Ingrese una breve descripción de la promoción'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    row={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='País'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => stateC(value)}
                    value={country}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {countries}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Estado'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => cityC(value)}
                    value={state}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {State}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select 
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => setCity(value)}
                    value={city}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {City}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de inicio'
                  validateStatus={errorInitDay && ('error')}
                  help={errorInitDay}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    value={initDay}
                    format={dateFormat}
                    onChange={(value) => setInitDay(value)} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de vencimiento'
                  validateStatus={errorFinishDay && ('error')}
                  help={errorFinishDay}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    value={finishDay}
                    format={dateFormat}
                    onChange={(value) => setFinishDay(value)} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descuento'
                  validateStatus={errorDiscount && ('error')}
                 help={errorDiscount}
                >
                  <InputNumber
                    min={0}
                    max={100}
                    value={discount}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                    onChange={(value) => setDiscount(value)}
                    style={{ width: 150 }}
                  />
               </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categorías'
                  validateStatus={errorCategory && ('error')}
                  help={errorCategory}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setCategory(value)}
                    value={category}
                    placeholder='Escoja una o varias categorias'
                  >
                    {Category}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default TradeCreate