import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Layout,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select
} from 'antd'
import API from '../../api/index'
import Header from '../Header'

const { Content } = Layout

const CategoryUpdate = (props) => {
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [nameProps, setNameProps] = React.useState(undefined)
  const [category, setCategory] = React.useState([])
  const [errorCategory, setErrorCategory] = React.useState(undefined)
  const [idCategory, setIdCategory] = React.useState([])
  const id = props.match.params.id

  const onBack = () => {
    setRedirect('/loyalty')
  }

  const idcategory = async () => {
    const idcategory = await API.category.ListTrue()
    setIdCategory(idcategory)
  }

  const onSubmit = async () => {
    clearErrors()
    let resultName
    if (nameProps !== name) {
      resultName = name
    }
    const response = await API.loyalty.update(id, {
      name: resultName,
      image: image,
      category: category
    })
    if (response.ok) {
      Modal.success({
        title: 'Actualización del programa de fidelidad',
        content: `El programa de fidelidad ${name} fue actualizado exitosamente`
      })
      clearErrors()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.category) {
      setErrorCategory('Las categorias son obligatorias')
    }
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorImage(undefined)
    setErrorCategory(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const loyalty = async (id) => {
    const loyalty = await API.loyalty.detail(id)
    setName(loyalty.name)
    setNameProps(loyalty.name)
    setImage(loyalty.image)
    setCategory(loyalty.category)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  React.useEffect(() => {
    loyalty(id)
    idcategory()
  }, [])

    if (redirect) {
      return (
        <Redirect to={redirect} />
      )
    }
    const Category = createRow(idCategory)

    return (
      <Layout className='layout'>
        <Header item='loyalty' />
        <Content>
          <div className='content-auto'>
            <Row style={{ marginBottom: '20px' }}>
              <Col span={8}>
                <h3><strong>Actualización del programa de fidelidad</strong></h3>
              </Col>
              <Col span={8} offset={8} style={{ textAlign: 'right' }}>
                <Button onClick={() => onBack()}>Regresar</Button>
                {' '}
                <Button onClick={() => onSubmit()}>Editar</Button>
              </Col>
            </Row>
            <Form
              layout='vertical'
            >
              <Row>
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Nombre'
                    validateStatus={errorName && ('error')}
                    help={errorName}
                  >
                    <Input
                      type='text'
                      name='name'
                      onChange={(event) => setName(event.target.value)}
                      value={name}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Imagen'
                  >
                    <img
                      alt={name}
                      src={image}
                      style={{ maxWidth: '100%' }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Image'
                    validateStatus={errorImage && ('error')}
                    help={errorImage}
                  >
                    {!image ? (
                      <Upload
                        name='file'
                        listType='picture'
                        beforeUpload={() => beforeUpload()}
                        onChange={(event) => setImage(event.file)}
                      >
                        <Button><Icon type='upload' /> Cambiar imagen</Button>
                      </Upload>
                    ) : (
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                        <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>{image.name}</span> {' '}
                        <Icon type='close' style={{ fontSize: '20px', color: 'red' }} onClick={() => handleClearImage()} />
                      </div>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Categorías del producto'
                    validateStatus={errorCategory && ('error')}
                    help={errorCategory}
                  >
                    <Select
                      type='text'
                      mode='multiple'
                      style={{ width: '100%' }}
                      onChange={(value) => setCategory(value)}
                      value={category}
                      placeholder='Escoja una o varias categorias'
                    >
                      {Category}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </Content>
      </Layout>
    )
}

export default CategoryUpdate
