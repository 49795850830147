import React from 'react'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  InputNumber,
  DatePicker,
  Layout
} from 'antd'
import { Redirect } from 'react-router-dom'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout
const { TextArea } = Input
const dateFormat = 'YYYY/MM/DD'

const ProductsCreate = (props) => {
  const [name, setName] = React.useState(undefined)
  const [errorName,setErrorName] = React.useState(undefined)
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [idcategory, setIdcategory] = React.useState([])
  const [errorCategory, setErrorCategory] = React.useState(undefined)
  const [nameBranch, setNameBranch] = React.useState(undefined)
  const [buy_site, setBuy_site] = React.useState(undefined)
  const [errorBuySite, setErrorBuySite] = React.useState(undefined)
  const branch = props.match.params.branch
  const [buy_online, setBuy_online] = React.useState(undefined)
  const [errorBuyOnline, setErrorBuyOnline] = React.useState(undefined)
  const [price, setPrice] = React.useState(undefined)
  const [errorPrice, setErrorPrice] = React.useState(undefined)
  const [category, setCategory] = React.useState([])
  const [imageList, setImageList] = React.useState([])
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [is_promotion, setIs_promotion] = React.useState('false')
  const [errorPromotion, setErrorPromotion] = React.useState(undefined)
  const [discount, setDiscount] = React.useState(undefined)
  const [expiration_date, setExpiration_date] = React.useState(undefined)
  const [errorExpirationDate, setErrorExpirationDate] = React.useState(undefined)
  const [price_discount, setPrice_discount] = React.useState(undefined)
  const [errorPriceDiscount, setErrorPriceDiscount] = React.useState(undefined)
  const [maximumDiscountAllowed, setMaximumDiscountAllowed] = React.useState(0)
  const [alert, setAlert] = React.useState(false)
  const [confirmation, setConfirmation] = React.useState(false)
  const [minimumStock, setMinimumStock] = React.useState(1)
  const [redirect, setRedirect] = React.useState(undefined)

  const handleAlert = () => {
    setAlert(false)
    setConfirmation(true)
    onSubmit()
  }

  const handleAlertNot = () => {
    setAlert(false)
    setConfirmation(false)
  }

  const handlePriceDiscount = (value) => {
    const discount = ((price - value) * 100) / price
    setPrice_discount(value)
    setDiscount(discount)
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const Idcategory = async () => {
    const idcategory = await API.category.ListTrue()
    setCategory(idcategory)
  }

  const getBranch = async () => {
    const branch = await API.branchOffice.detail(branch)
    if (branch.name) {
      setNameBranch(branch.name)
    } else {
      setRedirect('/home')
    }
  }

  const onBack = () => {
    setRedirect(`/products/${branch}`)
  }

  const onSubmit = async () => {
    clearErrors()
    const maximumDiscountAllowed = parseFloat(maximumDiscountAllowed)
    if (buy_online === 'true' || buy_site === 'true') {
      if ((is_promotion === 'false') || (maximumDiscountAllowed >= discount)) {
        let image = []
        if (imageList) {
          const images = imageList
          for (let index = 0; index < images.length; index++) {
            const element = images[index].thumbUrl
            image.push(element)
          }
        }
        if (discount >= 50 && confirmation === false) {
          setAlert(true)
        } else if (discount < 50 || confirmation === true || discount === undefined) {
          if (minimumStock >= 1) {
            const response = await API.products.create({
              name: name,
              description: description,
              idcategory: idcategory,
              idBranch: branch,
              buy_online: buy_online,
              buy_site: buy_site,
              price: price,
              imageList: image,
              is_promotion: is_promotion,
              discount: discount,
              expiration_date: expiration_date,
              price_discount: price_discount,
              minimumStock: minimumStock,
              maximumDiscountAllowed: maximumDiscountAllowed
            })
            if (response._id) {
              Modal.success({
                title: 'Creación de un producto',
                content: `el producto ${name} fue creado exitosamente`
              })
              onBack()
            }
            if (response.errors) {
              makeErrors(response.errors)
            }
          } else {
            Modal.error({
              title: 'Creación de un producto',
              content: 'El stock mínimo debe ser mayor o igual a 1'
            })
          }
        }
      } else {
        Modal.error({
          title: 'Descuento no permitido',
          content: 'El descuento de la promoción es mayor al descuento permitido'
        })
      }
    } else {
      Modal.error({
        title: 'Lugar de venta',
        content: 'Debe seleccionar mínimo un lugar de venta Internet / Tienda'
      })
    }
  }
  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.buy_online) {
      setErrorBuyOnline(error.buy_online.message)
    }
    if (error.buy_site) {
      setErrorBuySite(error.buy_site.message)
    }
    if (error.idcategory) {
      setErrorCategory(error.idcategory.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
    if (error.expiration_date) {
      setErrorExpirationDate(error.expiration_date.message)
    }
    if (error.is_promotion) {
      setErrorPromotion(error.is_promotion.message)
    }
    if (error.price) {
      setErrorPrice(error.price.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.price_discount) {
      setErrorPriceDiscount(error.price_discount.message)
    }
  }

  const clearErrors = () => {
    setErrorBuyOnline(undefined)
    setErrorBuySite(undefined)
    setErrorCategory(undefined)
    setErrorDescription(undefined)
    setErrorExpirationDate(undefined)
    setErrorName(undefined)
    setErrorPrice(undefined)
    setErrorPromotion(undefined)
    setErrorImage(undefined)
    setErrorPriceDiscount(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleChangeUpload = (info) => {
    let fileList = info.fileList;
    fileList = fileList.slice(-4);
    fileList = fileList.map((file) => {
      if (file.response) {
        file.url = file.response.url;
      }
      return file;
    })
    fileList = fileList.filter((file) => {
      if (file.response) {
        return file.response.status === 'success';
      }
      return true
    })
    setImageList(fileList )
  }

  const onChangeMaximumDiscountAllowed = async (event) => {
    const value = event.target.value
    if ((value >= 0) && (value <= 100)) {
      setMaximumDiscountAllowed(value)
    }
    if (value <= 0) {
      setIs_promotion('false')
      setPrice_discount(undefined)
      setDiscount(undefined)
      setExpiration_date(undefined)
    }
  }

  React.useState(() => {
    Idcategory()
    getBranch()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  const categoryRow = createRow(category)
  let promotion 
  if (is_promotion === 'true') {
    promotion = true
  } else {
    promotion = false
  }

  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nuevo producto</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              {alert && (
                <Modal 
                  title='Alerta'
                  visible={alert}
                  onOk={() => handleAlert()}
                  onCancel={() => handleAlertNot()}
                  okText='Continuar'
                  cancelText='Cancelar'
                >
                  <p>Producto con mas del 50% de descuento</p>
                  <p>¿Esta seguro continuar?</p>
                </Modal>
              )}
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    name='name'
                    placeholder='Ingrese nombre del producto'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagenes*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  <Upload
                    name='file'
                    listType='picture-card'
                    beforeUpload={() => beforeUpload()}
                    onChange={(info) => handleChangeUpload(info)}
                    fileList={imageList}
                    multiple
                  >
                    <Button><Icon type='upload' /> Seleccionar imagen</Button>              
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción*'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    placeholder='Ingrese una breve descripción del producto'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    row={4}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categorías del producto*'
                  validateStatus={errorCategory && ('error')}
                  help={errorCategory}
                >
                  <Select
                    type='text'
                    mode='multiple'
                    style={{ width: '100%' }}
                    onChange={(value) => setIdcategory(value)}
                    value={idcategory}
                    placeholder='Escoja una o varias categorias'
                    >
                    {categoryRow}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Sucursal del producto*'
                >
                  <Input
                    type='text'
                    disabled
                    value={nameBranch}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Se puede comprar en linea?*'
                  validateStatus={errorBuyOnline && ('error')}
                  help={errorBuyOnline}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setBuy_online(value)}
                    value={buy_online}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Se puede comprar directamente en el local?*'
                  validateStatus={errorBuySite && ('error')}
                  help={errorBuySite}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setBuy_site(value)}
                    value={buy_site}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Máximo de descuento permitido (%)'
                >
                  <Input
                    name='maximumDiscountAllowed'
                    value={maximumDiscountAllowed}
                    onChange={(event) => onChangeMaximumDiscountAllowed(event)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                  <Form.Item
                    label='Stock mínimo'
                  >
                  <InputNumber
                    min={1}
                    value={minimumStock}
                    onChange={(value) => setMinimumStock(value)}
                  />
                  </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Precio*'
                  validateStatus={errorPrice && ('error')}
                  help={errorPrice}
                >
                  <InputNumber
                    min={0}
                    value={price}
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    onChange={(value) => setPrice(value)}
                  />
                </Form.Item>
              </Col>
              {(price && maximumDiscountAllowed > 0) ? (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='¿El producto esta en promoción?*'
                    validateStatus={errorPromotion && ('error')}
                    help={errorPromotion}
                  >
                    <Select
                      type='text'
                      style={{ width: '100%' }}
                      onChange={(value) => setIs_promotion(value)}
                      value={is_promotion}
                      placeholder='Seleccione su respuesta'
                    >
                      <Select.Option value='true'>Si</Select.Option>
                      <Select.Option value='false'>No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              ) : (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='¿El producto esta en promoción?'
                    validateStatus={errorPromotion && ('error')}
                    help={errorPromotion}
                  >
                    <Select
                      disabled
                      type='text'
                      style={{ width: '100%' }}
                      onChange={(value) => setIs_promotion(value)}
                      value={is_promotion}
                      placeholder='Seleccione su respuesta'
                    >
                      <Select.Option value='true'>Si</Select.Option>
                      <Select.Option value='false'>No</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={7} offset={1}>
                {(promotion && price)? (
                  <Form.Item
                    label='Precio con descuento*'
                    validateStatus={errorPriceDiscount && ('error')}
                    help={errorPriceDiscount}
                  >
                    <InputNumber
                      min={0}
                      max={price}
                      value={price_discount}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => handlePriceDiscount(value)}
                    />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Precio con descuento'
                    validateStatus={errorPriceDiscount && ('error')}
                    help={errorPriceDiscount}
                  >
                    <InputNumber
                      min={0}
                      disabled
                      value={price_discount}
                      formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      onChange={(value) => handlePriceDiscount(value)}
                    />
                  </Form.Item>
                )}
              </Col>
              <Col span={7} offset={1}>
                  <Form.Item
                    label='Descuento'
                  >
                  <InputNumber
                    disabled
                    min={0}
                    max={100}
                    value={discount}
                    formatter={value => `${value}%`}
                    parser={value => value.replace('%', '')}
                  />
                  </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                {promotion ? (
                  <Form.Item
                    label='Fecha de vencimiento de la promoción*'
                    validateStatus={errorExpirationDate && ('error')}
                    help={errorExpirationDate}
                  >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    value={expiration_date}
                    format={dateFormat}
                    onChange={(value) => setExpiration_date(value)} />
                  </Form.Item>
                ) : (
                  <Form.Item
                    label='Fecha de vencimiento de la promoción'
                  >
                  <DatePicker
                    disabled
                    placeholder='Seleccione una fecha'
                    value={expiration_date}
                    format={dateFormat}
                    onChange={(value) => setExpiration_date(value)} />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}   

export default ProductsCreate
