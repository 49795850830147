import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Button,
  Select
} from 'antd'
import Header from '../Header'
import API from '../../api/index'
import Addresses from './Addresses'
import Bill from './Bill'
import Log from './Log'

const Content = Layout.Content

const UserDetil = (props) => {
  const id = props.match.params.id
  const [birthdate, setBirthdate] = React.useState(undefined)
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [date_join, setDate_join] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [addresses, setAddresses] = React.useState([])
  const [bill, setBill] = React.useState([])
  const [user, setUser] = React.useState({})
  const [trades, setTrades] = React.useState([])

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear()
  }

  const getTrades = async() => {
    const response = await API.trade.ListTrue()
    setTrades(response)
  }

  const getUser = async () => {
    const user = await API.user.detail(id)
    console.log('user', user)
    if (user.birthdate) {
      setBirthdate(formatDate(user.birthdate))
    }
    setStart_date(formatDate(user.start_date))
    setUpdate_date(formatDate(user.update_date))
    setDate_join(user.date_join)
    setUser(user)
  }

  const getAddress = async () => {
    const addresses = await API.address.list(id)
    setAddresses(addresses)
  }

  const getBill = async () => {
    const bills = await API.bill.list(id)
    setBill(bills)
  }

  const onBack = () => {
    setRedirect('/user')
  }

  React.useEffect(() => {
    getUser()
    getAddress()
    getBill()
    getTrades()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header item='user' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del usuario</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form layout='vertical'>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Nombres'>
                  <Input
                    disabled
                    value={user.first_name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Apellidos'>
                  <Input
                    disabled
                    value={user.last_name}
                  />
                </Form.Item>
              </Col>
              {user.image && (
                <Col span={7} offset={1}>
                  <Form.Item label='Imagen'>
                    <img
                      alt={user.name}
                      src={user.image}
                      style={
                        { maxWidth: '30%', display: 'block', marginLeft: 'auto', marginRight: 'auto' }
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
            {user.country && (
              <Row>
                <Col span={7} offset={1}>
                  <Form.Item label='País'>
                    <Input
                      disabled
                      value={user.country.name}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label='Estado'>
                    <Input
                      disabled
                      value={user.state.name}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label='Ciudad'>
                    <Input
                      disabled
                      value={user.city.name}
                    />
                  </Form.Item>
                </Col>
              </Row>
            )}
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Teléfono'>
                  <Input
                    disabled
                    value={user.cellphone}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Correo electrónico'>
                  <Input
                    disabled
                    value={user.email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de nacimiento'>
                  <Input
                    disabled
                    value={birthdate}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Tipo de usuario'>
                  <Input
                    disabled
                    value={user.type_user}
                  />
                </Form.Item>
              </Col>
              {user.type_user === 'Asesor Comercial' && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Comercio'
                  >
                    <Select
                      style={{ width: '100%' }}
                      value={user.trade}
                      disabled
                      placeholder='Seleccione un comercio'
                    >
                      {trades.map((trade, index) => (
                        <Select.Option key={index} value={trade._id}>{trade.trade_name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
              <Col span={7} offset={1}>
                <Form.Item label='Género'>
                  <Input
                    disabled
                    value={user.gender}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Puntos'>
                  <Input
                    disabled
                    value={user.points}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de creación'>
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Fecha de actualización'>
                  <Input
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Último Login'>
                  <Input
                    disabled
                    value={date_join}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          {addresses && (
            <div>
              <Row style={{ marginBottom: '20px' }}>
                <Col span={8}>
                  <h3><strong>Direcciones del usuario</strong></h3>
                </Col>
              </Row>
              <Row>
                <Col span={23} offset={1}>
                  <Addresses addresses={addresses} />
                </Col>
              </Row>
            </div>
          )}
          {bill && (
            <div>
              <Row style={{ marginBottom: '20px', marginTop: '25px' }}>
                <Col span={8}>
                  <h3><strong>Compras del usuario</strong></h3>
                </Col>
              </Row>
              <Row>
                <Col span={23} offset={1}>
                  <Bill bill={bill} />
                </Col>
              </Row>
            </div>
          )}
          <div>
            <Row style={{ marginBottom: '20px', marginTop: '25px' }}>
              <Col span={8}>
                <h3><strong>Registro del sistema</strong></h3>
              </Col>
            </Row>
            <Row>
              <Col span={23} offset={1}>
                <Log user={id} />
              </Col>
            </Row>
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default UserDetil
