import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout,
  Upload,
  Icon
} from 'antd'
import API from '../../../api/index'
import Header from '../../Header'

const { Content } = Layout
const { TextArea } = Input

const ProfessionCreate = (props) => {
  const [profession, setProfession] = React.useState([])
  const [idProfession, setIdProfession] = React.useState([])
  const [errorProfession, setErrorProfession] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [email, setEmail] = React.useState(undefined)
  const [errorEmail, setErrorEmail] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [image, setImage] = React.useState(undefined)
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [idCity, setIdCity] = React.useState([])
  const [idCountry, setIdCountry] = React.useState([])
  const [idState, setIdState] = React.useState([])
  const [address, setAddress] = React.useState(undefined)
  const [errorAddress, setErrorAddress] = React.useState(undefined)
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const Profession = async () => {
    const profession = await API.profession.ListTrue()
    setIdProfession(profession)
  }

  const Country = async () => {
    const idCountry = await API.country.list()
    setIdCountry(idCountry)
    stateC()
  }

  const stateC = async (country = country) => {
    if (country) {
      const idState = await API.state.list(country)
      setIdState(idState)
    }
  }

  const City = async (state = state) => {
    if (state) {
      const idCity = await API.city.list(state)
      setIdCity(idCity)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.persons.create({
      profession: profession,
      name: name,
      city: city,
      email: email,
      cellphone: cellphone,
      image: image,
      address: address,
      description: description
    })
    if (response._id) {
      Modal.success({
        title: 'Creación de una persona',
        content: `La persona ${name} fue creada exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.profession) {
      setErrorProfession(error.profession.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
  }

  const clearFields = () => {
    setName(undefined)
    setCountry(undefined)
    setState(undefined)
    setCity(undefined)
    setCellphone(undefined)
    setEmail(undefined)
    setProfession(undefined)
    setImage(undefined)
    setAddress(undefined)
    setDescription(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorCity(undefined)
    setErrorEmail(undefined)
    setErrorCellphone(undefined)
    setErrorProfession(undefined)
    setErrorImage(undefined)
    setErrorAddress(undefined)
    setErrorDescription(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleChangeUpload = async (file) => {
    setImage(file.file)
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  const handleChangeCountry = async (value) => {
    setCountry(value)
    stateC(value)
  }

  const handleChangeState = (value) => {
    setState(value)
    City(value)
  }

  const handleChangeCity = (value) => {
    setCity(value)
  }

  const handleChangeProfession = async (value) => {
    setProfession(value)
  }

  const onBack = () => {
    setRedirect('/profession')
  }

  React.useEffect(() => {
    Profession()
    Country()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  const cityC = createRow(idCity)
  const State = createRow(idState)
  const countryC = createRow(idCountry)
  const ProfessionC = createRow(idProfession)
  return (
    <Layout className='layout' >
      <Header item='profession' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
             <h3><strong>Nueva Persona</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre completo*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre de la persona'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(file) => handleChangeUpload(file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        type='picture' theme='outlined' 
                        style={{ fontSize: '20px' }} 
                      />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='País*'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryC}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Estado*'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => handleChangeState(value)}
                    value={state}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    } 
                  >
                    {State}
                  </Select>
                 </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad*'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad' 
                    onChange={(value) => handleChangeCity(value)}
                    value={city}
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {cityC}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono*'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el teléfono de la persona'
                    onChange={(event) => setCellphone(event.target.value)}
                    value={cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Profesión*'
                  validateStatus={errorProfession && ('error')}
                  help={errorProfession}
                >
                  <Select
                    showSearch
                    mode='multiple'
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una profesión'
                    onChange={(value) => handleChangeProfession(value)}
                    value={profession}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {ProfessionC}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='email*'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='email'
                    placeholder='Ingrese el email de la persona'
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción*'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    placeholder='Ingrese una breve descripción de la persona'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    row={4}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección*'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese la direccion de la persona'
                    onChange={(event) => setAddress(event.target.value)}
                    value={address}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default ProfessionCreate