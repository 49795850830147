import axios from 'axios'
import config from './config'

const URL = `${config.URL}/assistant`

export async function create (data) {
  const response = await axios.post(`${URL}`, data, config.configWithToken())
  return response.data
}

export async function update (_id, data) {
  const response = await axios.post(`${URL}/update/${_id}`, data, config.configWithToken())
  return response.data
}

export async function detail (_id) {
  const response = await axios.get(`${URL}/detail/${_id}`, config.configWithToken())
  return response.data
}

export async function filter_by_page (page, topic) {
  const response = await axios.get(`${URL}/filter-by-page/${page}/${topic}`, config.configWithToken())
  return response.data
}

export async function change_status (status) {
  const response = await axios.post(`${URL}/change/status`, {status}, config.configWithToken())
  return response.data
}

export async function marked_payment (_id) {
  const response = await axios.get(`${URL}/marked-payment/${_id}`, config.configWithToken())
  return response.data
}

export async function delete_assistant (_id) {
  const response = await axios.get(`${URL}/delete/${_id}`, config.configWithToken())
  return response.data
}

export default {
  create,
  update,
  filter_by_page,
  detail,
  change_status,
  marked_payment,
  delete_assistant
}
