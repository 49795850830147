import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Layout,
  Row,
  Col,
  Button
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const Content = Layout.Content

const LoyaltyDetail = (props) => {
  const id = props.match.params.id
  const [loyalty, setLoyalty] = React.useState({})
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
      'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ]
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return (
      '0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() 
      + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2
    )
  }

  const loylaties = async () => {
    const Loyalty = await API.loyalty.detail(id)
    setStart_date(formatDate(Loyalty.start_date))
    setUpdate_date(formatDate(Loyalty.update_date))
    setLoyalty(Loyalty)
  }

  const onBack = () => {
    setRedirect('/loyalty')
  }

  React.useEffect(() => {
    loylaties()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header item='/loyalty' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del programa de fidelidad</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={loyalty.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Imagen'
                >
                  <img
                  alt={loyalty.name}
                    src={loyalty.image}
                    style={{ maxWidth: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    disabled
                    value={update_date}
                  />
                 </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Categorias'
                >
                  <Input
                    disabled
                    value={loyalty.categoryName}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default LoyaltyDetail
