import React from 'react'
import CategoryList from './List'
import { Layout } from 'antd'
import Header from '../Header'

const { Content } = Layout

const Category = (props) => {
  return (
    <Layout className='layout'>
      <Header item='category' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <CategoryList />
        </div>
      </Content>
    </Layout>
  )
}

export default Category
