import React from 'react'
import { getItem } from '../api/utils'
import { Layout } from 'antd'
import Administrador from '../components/menu/Administrador'
import Comerciante from '../components/menu/Comerciante'
import Operator from '../components/menu/operator'
import EntidadPromotora from '../components/menu/EntidadPromotora'

const { Header } = Layout

const HeaderComponent = (props) => {
  const item = props.item
  const typeUser = getItem('type')

  return (
    <Header>
      {(typeUser === 'Administrador' || typeUser === 'supervisor') && (
        <React.Fragment>
          <Administrador item={item} /> 
        </React.Fragment>
      )}
      {(typeUser === 'Empresario') && (
        <Comerciante item={item} />
      )}
      {(typeUser === 'operador') && (
        <Operator item={item} />
      )}
      {typeUser === 'EntidadPromotora' && (
        <EntidadPromotora item={item} />
      )}
    </Header>
  )
}

export default HeaderComponent
