import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../api/utils'
import { Menu } from 'antd'

const Administrador = (props) => {
  const item = props.item
  const profile = getItem('type')
  const [redirect, setRedirect] = React.useState(undefined)


  const handleLogout = () => {
    window.localStorage.clear()
    setRedirect('/')
  }

  const handleRedirect = (url) => {
    const pathname = window.location.pathname
    if (pathname !== url) {
      setRedirect(url)
    }
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <Menu
      theme='dark'
      mode='horizontal'
      defaultSelectedKeys={[item]}
      style={{ lineHeight: '64px' }}
    >
      <Menu.Item key='1'>
        <span className='yellow'>Ganga</span> <span className='white'>Hoy</span>
      </Menu.Item>
      <Menu.Item key='home'>
        <a onClick={() => handleRedirect('/home')}>Inicio</a>
      </Menu.Item>
      <Menu.Item key='user'>
        <a onClick={() => handleRedirect('/user')}>Usuarios</a>
      </Menu.Item>
      <Menu.Item key='sections'>
        <a onClick={() => handleRedirect('/sections')}>Secciones</a>
      </Menu.Item>
      <Menu.Item key='category'>
        <a onClick={() => handleRedirect('/category')}>Categorias</a>
      </Menu.Item>
      <Menu.Item key='trade'>
        <a onClick={() => handleRedirect('/trade')}>Comercios</a>
      </Menu.Item>
      <Menu.Item key='loyalty'>
        <a onClick={() => handleRedirect('/loyalty')}>Programas de fidelidad</a>
      </Menu.Item>
      <Menu.Item key='profession'>
        <a onClick={() => handleRedirect('/profession')}>Profesiones</a>
      </Menu.Item>
      <Menu.Item key='topic'>
        <a onClick={() => handleRedirect('/topic')}>Ferias</a>
      </Menu.Item>
      <Menu.Item key='sales'>
        <a onClick={() => handleRedirect('/sales')}>Ventas</a>
      </Menu.Item>
      {profile === 'Administrador' && (
        <Menu.Item key='backups'>
          <a onClick={() => handleRedirect('/backups')}>Backups</a>
        </Menu.Item>
      )}
      <Menu.Item key='log'>
        <a onClick={() => handleRedirect('/log')}>Registro del sistema</a>
      </Menu.Item>
      <Menu.Item key='configuration'>
        <a onClick={() => handleRedirect('/configuration')}>Configuración</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handleLogout()}>Salir</a>
      </Menu.Item>
    </Menu>
  )
}

export default Administrador
