import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../api/utils'
import {
  Layout,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Select,
  DatePicker,
  Row,
  Col
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout
const dateFormat = 'YYYY/MM/DD'

const userTypes = {
  Administrador: [
    {value: 'Administrador', text: 'Administrador'},
    {value: 'supervisor', text: 'Supervisor'},
    {value: 'cliente', text: 'Cliente'},
    {value: 'Empresario', text: 'Empresario'},
    {value: 'EntidadPromotora', text: 'Entidad promotora'},
    {value: 'Asesor Comercial', text: 'Asesor Comercial'}
  ],
  EntidadPromotora: [
    {value: 'Empresario', text: 'Empresario'},
    {value: 'Asesor Comercial', text: 'Asesor Comercial'}
  ],
  default: [
    {value: 'cliente', text: 'Cliente'},
    {value: 'Empresario', text: 'Empresario'}
  ]
}

const UserCreate = (props) => {
  const [firstName, setFirstName] = React.useState('')
  const [errorFirstName, setErrorFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [errorLastName, setErrorLastName] = React.useState('')
  const [typeUser, setTypeUser] = React.useState('')
  const [errorTypeUser, setErrorTypeUser] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [errorEmail, setErrorEmail] = React.useState('')
  const [address, setAddress] = React.useState('')
  const [errorAddress, setErrorAddress] = React.useState('')
  const [birthdate, setBirthdate] = React.useState('')
  const [errorBirthdate, setErrorBirthdate] = React.useState('')
  const [city, setCity] = React.useState('')
  const [errorCity, setErrorCity] = React.useState('')
  const [state, setState] = React.useState('')
  const [country, setCountry] = React.useState('')
  const [cellphone, setCellphone] = React.useState('')
  const [errorCellphone, setErrorCellphone] = React.useState('')
  const [image, setImage] = React.useState('')
  const [errorImage, setErrorImage] = React.useState('')
  const [gender, setGender] = React.useState('')
  const [errorGender, setErrorGender] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [errorPassword, setErrorPassword] = React.useState('')
  const [repeatPassword, setRepeatPassword] = React.useState('')
  const [errorRepeatPassword, setErrorRepeatPassword] = React.useState('')
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [redirect, setRedirect] = React.useState('')
  const [trades, setTrades] = React.useState([])
  const [trade, setTrade] = React.useState('')
  const type = getItem('type')

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const handleValidateName = (event) => {
    let caracter = event.target.value;
    let regex = new RegExp("^[a-zA-Z ]+$");
    if(regex.test(caracter)){
      const name = event.target.name
      const value = event.target.value
      if (name === 'first_name') {
        setFirstName(value)
      } else {
        setLastName(value)
      }
    }
  }

  const getCountries = async () => {
    const response = await API.country.list()
    setCountries(response)
  }

  const getTrades = async() => {
    const response = await API.trade.ListTrue()
    setTrades(response)
  }

  const getStates = async (value=country) => {
    if (value) {
      const response = await API.state.list(value)
      setStates(response)
    }
  }

  const getCities = async (value=state) => {
    if (value) {
      const response = await API.city.list(value)
      setCities(response)
    }
  }

  const onSubmit = async () => {
    clearErrors()
    if (password === repeatPassword) {
      const response = await API.user.create({
        first_name: firstName,
        last_name: lastName,
        type_user: typeUser,
        email: email,
        address: address,
        birthdate: birthdate,
        city: city,
        cellphone: cellphone,
        image: image,
        gender: gender,
        password: password,
        trade: trade,
        allow_gps: true
      })
      console.log(response)
      if (response._id) {
        Modal.success({
          title: 'Creación de un usuario',
          content: `El usuario ${firstName} fue creado exitosamente`
        })
        clearFields()
      }
      if (response.errors) {
        makeErrors(response.errors)
      }
    } else {
      setErrorRepeatPassword('Las contraseñas no coinciden, por favor intente de nuevo.')
    }
  }

  const makeErrors = (error) => {
    if (error.first_name) {
      setFirstName(error.first_name.message)
    }
    if (error.last_name) {
      setErrorLastName(error.last_name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.password) {
      setErrorPassword(error.password.message)
    }
    if (error.birthdate) {
      setErrorBirthdate(error.birthdate.message)
    }
    if (error.type_user) {
      setErrorTypeUser(error.type_user.message)
    }
    if (error.gender) {
      setErrorGender(error.gender.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.email) {
      setErrorEmail(error.email.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
  }

  const clearFields = () => {
    setFirstName('')
    setLastName('')
    setTypeUser('')
    setEmail('')
    setAddress('')
    setBirthdate('')
    setCity('')
    setState('')
    setCountry('')
    setCellphone('')
    setImage('')
    setGender('')
    setPassword('')
    setRepeatPassword('')
    clearErrors()
  }

  const clearErrors = () => {
    setErrorFirstName('')
    setErrorLastName('')
    setErrorImage('')
    setErrorCity('')
    setErrorPassword('')
    setErrorRepeatPassword('')
    setErrorBirthdate('')
    setErrorTypeUser('')
    setErrorGender('')
    setErrorAddress('')
    setErrorEmail('')
    setErrorCellphone('')
  }

  const beforeUpload = () => {
    return false
  }

  const handleChangeUpload = (file) => {
    setImage(file)
  }

  const handleClearImage = () => {
    setImage('')
  }

  const handleChangeCountry = (value) => {
    setCountry(value)
    getStates(value)
  }

  const handleChangeState = (value) => {
    setState(value)
    getCities(value)
  }

  const handleChangeCity = (value) => {
    setCity(value)
  }

  const handleChangeBirthdate = (value) => {
    setErrorBirthdate('')
    if (value) {
      let date = new Date()
      let dateMax = new Date()
      date.setFullYear(date.getFullYear() - 14)
      dateMax.setFullYear(dateMax.getFullYear() - 90)
      const dateSelect = new Date(value.format('YYYY-MM-DD'))
      if (date.getTime() > dateSelect.getTime() && dateMax.getTime() < dateSelect.getTime()) {
        setBirthdate(value)
      } else {
        setErrorBirthdate('La fecha de nacimiento seleccionada no es permitida')
      }
    } else {
      setBirthdate('')
    }
  }

  const onBack = () => {
    setRedirect('/user')
  }

  React.useEffect(() => {
    getCountries()
    getTrades()
  }, [])

  const getUsers = () => {
    let users = userTypes.default
    if (userTypes.hasOwnProperty(type)) {
      users = userTypes[type]
    }
    return users
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const countryRow = createRow(countries)
  const stateRow = createRow(states)
  const cityRow = createRow(cities)

  return (
    <Layout className='layout'>
      <Header item='user' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nuevo usuario</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorFirstName && ('error')}
                  help={errorFirstName}
                >
                  <Input
                    type='text'
                    name='first_name'
                    placeholder='Ingrese el nombre del usuario'
                    onChange={(event) => handleValidateName(event)}
                    value={firstName}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Apellidos*'
                  validateStatus={errorLastName && ('error')}
                  help={errorLastName}
                >
                  <Input
                    type='text'
                    name='last_name'
                    placeholder='Ingrese los apellidos del usuario'
                    onChange={(event) => handleValidateName(event)}
                    value={lastName}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Foto'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => handleChangeUpload(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon type='picture' theme='outlined' style={{ fontSize: '20px' }} />{' '}
                      <span style={{ paddingLeft: '10px', paddingRight: '10px' }}>{image.name}</span> {' '}
                      <Icon type='close' style={{ fontSize: '20px', color: 'red' }} onClick={() => handleClearImage()} />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='País*'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {countryRow}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Estado*'>
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una estado'
                    onChange={(value) => handleChangeState(value)}
                    value={state}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {stateRow}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad*'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => handleChangeCity(value)}
                    value={city}
                    filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                  >
                    {cityRow}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Correo electrónico*'
                  validateStatus={errorEmail && ('error')}
                  help={errorEmail}
                >
                  <Input
                    type='email'
                    name='email'
                    placeholder='Ingrese el correo del usuario'
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Contraseña*'
                  validateStatus={errorPassword && ('error')}
                  help={errorPassword}
                >
                  <Input
                    type='password'
                    name='password'
                    placeholder='Ingrese la contraseña del usuario'
                    onChange={(event) => setPassword(event.target.value)}
                    value={password}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Repetir Contraseña*'
                  validateStatus={errorRepeatPassword && ('error')}
                  help={errorRepeatPassword}
                >
                  <Input
                    type='password'
                    name='repeatPassword'
                    placeholder='Repita la contraseña del usuario'
                    onChange={(event) => setRepeatPassword(event.target.value)}
                    value={repeatPassword}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de nacimiento*'
                  validateStatus={errorBirthdate && ('error')}
                  help={errorBirthdate}
                >
                  <DatePicker
                    placeholder='Seleccione una fecha'
                    format={dateFormat}
                    value={birthdate}
                    onChange={(value) => handleChangeBirthdate(value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tipo de usuario*'
                  validateStatus={errorTypeUser && ('error')}
                  help={errorTypeUser}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setTypeUser(value)}
                    value={typeUser}
                    placeholder='Seleccione un tipo de usuario'
                  >
                    {getUsers().map((user, index) => (
                      <Select.Option key={index} value={user.value}>{user.text}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {typeUser === 'Asesor Comercial' && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Comercio*'
                  >
                    <Select
                      style={{ width: '100%' }}
                      onChange={(value) => setTrade(value)}
                      value={trade}
                      placeholder='Seleccione un comercio'
                    >
                      {trades.map((trade, index) => (
                        <Select.Option key={index} value={trade._id}>{trade.trade_name}</Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Género*'
                  validateStatus={errorGender && ('error')}
                  help={errorGender}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => setGender(value)}
                    value={gender}
                    placeholder='Seleccione un género'
                  >
                    <Select.Option value='masculino'>Masculino</Select.Option>
                    <Select.Option value='femenino'>Femenino</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono*'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    name='cellphone'
                    placeholder='Ingrese el teléfono del usuario'
                    value={cellphone}
                    onChange={(event) => setCellphone(event.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Input
                    type='text'
                    name='address'
                    placeholder='Ingrese la dirección del usuario'
                    value={address}
                    onChange={(event) => setAddress(event.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default UserCreate
