import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Bar } from '@reactchartjs/react-chart.js'
import Header from '../Header'
import API from '../../api/index'

import {
  DatePicker,
  Col,
  Form,
  Layout,
  Row,
  Button,
  Select
} from 'antd'

const ReportIndex = (props) => {
  const { Content } = Layout
  const dateFormat = 'YYYY/MM/DD'
  let history = useHistory()
  const [branch, setBranch] = useState({})
  const [startDate, setStartDate] = useState('')
  const [errorStartDate, setErrorStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [errorEndDate, setErrorEndDate] = useState('')
  const [products, setProducts] = useState([])
  const [product, setProduct] = useState([])
  const [errorProduct, setErrorProduct] = useState('')
  const [labels, setLabels] = useState([])
  const [datasets, setDatasets] = useState([])
  const [datasetsNotSales, setDatasetsNotSales] = useState([])
  const [datasetsDiscount, setDatasetsDiscount] = useState([])

  const sales_month = {
    labels: labels,
    datasets: datasets,
  }

  const sales_not_month = {
    labels: labels,
    datasets: datasetsNotSales,
  }
  
  const discount_apply = {
    labels: labels,
    datasets: datasetsDiscount,
  }

  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  }

  useEffect(() => {
    getBranch()
  }, [props.match.params.id])

  const getBranch = async () => {
    const response = await API.branchOffice.detail(props.match.params.id)
    if (response._id) {
      setBranch(response)
      getProducts()
    } else {
      history.push('/home')
    }
  }

  const getProducts = async () => {
    let is_search = true
    let counter = 1
    let products = []
    while (is_search) {
      const response = await API.products.listBranch(props.match.params.id, counter)
      counter += 1
      if (response.length > 0) {
        products = [...products, ...response]
      } else {
        is_search = false
      }
    }
    setProducts(products)
  }

  const getMonth = (month) => {
    const months = ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    return months[month-1]
  }

  const generateLabels = () => {
    let is_created = true
    let start_date = new Date(startDate)
    let end_date = new Date(endDate)
    let date_response = []
    while (is_created) {
      let month = start_date.getMonth() + 1
      date_response.push(`${getMonth(month)} ${start_date.getFullYear()}`)
      if (start_date.getFullYear() === end_date.getFullYear() && start_date.getMonth() === end_date.getMonth()) {
        is_created = false
      } else {
        start_date.setMonth(start_date.getMonth() + 1)
      }
    }
    setLabels(date_response)
  }

  const getColorRandom = () => {
    var makeColorCode = '0123456789ABCDEF'
      var code = '#'
      for (var count = 0; count < 6; count++) {
         code =code+ makeColorCode[Math.floor(Math.random() * 16)]
      }
      return code
  }

  const onClick = async () => {
    generateLabels()
    const response = await API.bill.generate_report(startDate, endDate, product, props.match.params.id)
    console.log(response)
    if (response.sales_month) {
      let data_sets = []
      for (const key in product) {
        if (Object.hasOwnProperty.call(product, key)) {
          const element = product[key]
          const responseProduct = await API.products.detail(element)
          const dataset = {
            label: responseProduct.name,
            data: response.sales_month[element],
            backgroundColor: getColorRandom(),
          }
          data_sets = [...data_sets, dataset]
        }
      }
      setDatasets(data_sets)
    }
    if (response.sales_not_month) {
      let data_sets_not_sales = []
      for (const key in product) {
        if (Object.hasOwnProperty.call(product, key)) {
          const element = product[key]
          const responseProduct = await API.products.detail(element)
          const dataset = {
            label: responseProduct.name,
            data: response.sales_not_month[element],
            backgroundColor: getColorRandom(),
          }
          data_sets_not_sales = [...data_sets_not_sales, dataset]
        }
      }
      setDatasetsNotSales(data_sets_not_sales)
    }
    if (response.discount_apply) {
      let data_sets_discount_apply = []
      for (const key in product) {
        if (Object.hasOwnProperty.call(product, key)) {
          const element = product[key]
          const responseProduct = await API.products.detail(element)
          const dataset = {
            label: responseProduct.name,
            data: response.discount_apply[element],
            backgroundColor: getColorRandom(),
          }
          data_sets_discount_apply = [...data_sets_discount_apply, dataset]
        }
      }
      setDatasetsDiscount(data_sets_discount_apply)
    }
  }

  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <h2>Reportes de la sucursal <strong>{branch.name}</strong></h2>
          <Row>
            <Col span={5} offset={1}>
              <Form.Item
                label='Inicio*'
                validateStatus={errorStartDate && ('error')}
                help={errorStartDate}
              >
                <DatePicker
                  placeholder='Seleccione una fecha'
                  format={dateFormat}
                  value={startDate}
                  onChange={(value) => setStartDate(value)}
                />
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item
                label='Fin*'
                validateStatus={errorEndDate && ('error')}
                help={errorEndDate}
              >
                <DatePicker
                  placeholder='Seleccione una fecha'
                  format={dateFormat}
                  value={endDate}
                  onChange={(value) => setEndDate(value)}
                />
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item
                label='Productos*'
                validateStatus={errorProduct && ('error')}
                help={errorProduct}
              >
                <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione un producto'
                    mode='multiple'
                    onChange={(value) => setProduct(value)}
                    value={product}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {products.map((product, index) => {
                      return(
                        <Select.Option value={product._id} key={index}>{product.name}</Select.Option>
                      )
                    })}
                  </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={5} offset={1}>
              <Button icon='search' onClick={() => onClick()}>Buscar</Button>
            </Col>
          </Row>
          {(labels.length > 0) && (
            <Row style={{marginTop: '30px'}}>
              <Col span={10} offset={1}>
                <h3 className='text-center'>Unidades de ventas efectivas</h3>
                <Bar data={sales_month} options={options} />
              </Col>
              <Col span={10} offset={1}>
                <h3 className='text-center'>Unidades de ventas no efectivas</h3>
                <Bar data={sales_not_month} options={options} />
              </Col>
              <Col span={10} offset={1}>
                <h3 className='text-center'>Descuentos aplicados</h3>
                <Bar data={discount_apply} options={options} />
              </Col>
            </Row>
          )}
        </div>
      </Content>
    </Layout>
  )
}

export default ReportIndex
