import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Button,
  Form,
  Input,
  Tag,
  Carousel,
  Row,
  Col,
  Layout
} from 'antd'
import './Product.css'
import Header from '../Header'
import API from '../../api/index'

const Content = Layout.Content
const { TextArea } = Input

const ProductDetail = (props) => {
  const id = props.match.params.id
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const [expiration_date, setExpiration_date] = React.useState(undefined)
  const [product, setProduct] = React.useState({})

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    if (date) {
      const newDate = new Date(date)
      return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
    }
    return undefined
  }

  const products = async () => {
    const product = await API.products.detail(id)
    setStart_date(formatDate(product.start_date))
    setUpdate_date(formatDate(product.update_date))
    setExpiration_date(formatDate(product.expiration_date))
    setProduct(product)
  }

  const onBack = () => {
    setRedirect(`/products/${product.idBranch}`)
  }

  React.useEffect(() => {
    products()
  }, [])

  let categories = []
  let Branch
  if (product.nameCategories) {
    product.nameCategories.map((category, index) => (
      categories.push(<Tag color='blue' key={index}>{category}</Tag>)
    ))
  }
  if (product.nameBranch) {
    Branch = <Tag color='blue'>{product.nameBranch}</Tag>
  }
  let IMG = []
  if (product.image) {
    product.image.map((image, index) => {
      IMG.push(<img key={index} src={image} style={{ maxWidth: '100%', maxHeight: '100%' }} />)
    })
  }
  let buyOnline

  if (product.buy_online === true) {
    buyOnline = 'Si'
  } else {
    buyOnline = 'No'
  }
  let buySite
  if (product.buy_site === true) {
    buySite = 'Si'
  } else {
    buySite = 'No'
  }
  let isPromotion
  if (product.is_promotion === true) {
    isPromotion = 'Si'
  } else {
    isPromotion = 'No'
  }
  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del producto</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen'
                >
                  <Carousel autoplay>
                    {IMG}
                  </Carousel>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                >
                  <TextArea
                    disabled
                    placeholder={product.description}
                    autosize={{ minRows: 4, maxRows: 6 }} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={product.name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Precio'
                >
                  <Input
                    type='text'
                    disabled
                    value={product.price}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Inventario'
                >
                  <Input
                    type='text'
                    disabled
                    value={product.inventory ? product.inventory : 0}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Stock mínimo'
                >
                  <Input
                    type='text'
                    disabled
                    value={product.minimumStock ? product.minimumStock : 0}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Sucursal'
                >
                  {Branch}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Categoria'
                >
                  {categories}
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Calificación'
                >
                  <Input
                    type='text'
                    disabled
                    value={product.qualification}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Máximo de descuento permitido (%)'
                >
                  <Input
                    type='text'
                    disabled
                    value={`${product.maximumDiscountAllowed} %`}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Compra en sitio'
                >
                  <Input
                    type='text'
                    disabled
                    value={buySite}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Compra en linea'
                >
                  <Input
                    type='text'
                    disabled
                    value={buyOnline}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Producto con descuento'
                >
                  <Input
                    type='text'
                    disabled
                    value={isPromotion}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    type='text'
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Fecha de actualización'
                >
                  <Input
                    type='text'
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
              {(isPromotion === 'Si' && product.price_discount) && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Precio con descuento'
                  >
                    <Input
                      type='text'
                      disabled
                      value={product.price_discount}
                    />
                  </Form.Item>
                </Col>
              )}
              {(isPromotion === 'Si' && product.discount) && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Porcentaje de descuento'
                  >
                    <Input
                      type='text'
                      disabled
                      value={`${product.discount}%`}
                    />
                  </Form.Item>
                </Col>
              )}
              {(isPromotion === 'Si' && expiration_date) && (
                <Col span={7} offset={1}>
                  <Form.Item
                    label='Fecha de expiración del descuento'
                  >
                    <Input
                      type='text'
                      disabled
                      value={expiration_date}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
   
export default ProductDetail
