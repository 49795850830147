import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  Layout
} from 'antd'
import Header from '../../../../../Header'
import API from '../../../../../../api/index'

const { Content } = Layout

const InventoryUpdate = (props) => {
  const id = props.match.params.id
  const name = props.match.params.name
  const branch = props.match.params.branch
  const trade = props.match.params.trade
  const [product, setProduct] = React.useState(undefined)
  const [quantity, setQuantity] = React.useState(undefined)
  const [errorQuantity, setErrorQuantity] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const onBack = () => {
    setRedirect(`/inventorymerchant/${product}/${name}/${branch}/${trade}`)
  }

  const onSubmit = async () => {
    clearErrors()
    if (quantity < 1) {
      Modal.error({
        title: 'Error',
        content: `La cantidad de producto no puede ser inferior a 1`
      })
    } else {
      const response = await API.inventory.update(id, {
        product: id,
        quantity: quantity
      })
      if (response.nModified === 1) {
        Modal.success({
          title: 'Actualizar inventario',
          content: `El inventario de producto ${name} fue actualizado exitosamente`
        })
        clearErrors()
      }
      if (response.errors) {
        makeErrors(response.errors)
      }
    }
  }

  const makeErrors = (error) => {
    if (error.quantity) {
      setErrorQuantity(error.quantity.message)
    }
  }

  const clearErrors = () => {
    setErrorQuantity(undefined)
  }

  const inventory = async (id) => {
    const result = await API.inventory.detail(id)
    setProduct(result.product)
    setQuantity(result.quantity)
  }

  React.useState(() => {
    inventory(id)
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }

  return (
    <Layout className='layout'>
      <Header item='branch' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualizacion de Inventario para {name}</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Producto'
                >
                  <Input
                    disabled
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Cantidad del producto'
                  validateStatus={errorQuantity && ('error')}
                  help={errorQuantity}
                >
                  <InputNumber
                    min={0}
                    value={quantity}
                    onChange={(value) => setQuantity(value)} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default InventoryUpdate
