import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Tag,
  Icon
} from 'antd'
import API from '../../../../api/index'

const BranchListOperator = (props) => {
  const [branches, setBranches] =  React.useState([])
  const [redirect, setRedirect] = React.useState(undefined)
  const [name, setName] =  React.useState(undefined)
  const [id, setId] =  React.useState(undefined)
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Nombre', dataIndex: 'name', key: 'name'},
    {title: 'Ciudad', dataIndex: 'nameCity', key: 'nameCity'},
    {title: 'Dirección', dataIndex: 'address', key: 'address'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (index, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewBranch(record._id)}>Ver detalle</Tag>
            <Tag color='#254A90' onClick={() => handleVentas(record._id, id)}>Ventas</Tag>
            <Tag color='#72A2F0' onClick={() => handleProducts(record._id)}>Productos</Tag>
          </span>
        )
      }
    }
  ]

  const branch = async () => {
    const response = await API.branchOffice.branchOperator()
    if (response.length > 0) {
      setName(response[0].nameTrade)
      setId(response[0].idTrade)
    }
    setBranches(response)
  }

  const handleViewBranch = (id) => {
    setRedirect(`/branchmerchant/detail/${id}`)
  }

  const handleVentas = (idbranch, idtrade) => {
    setRedirect(`/branchmerchant/sales/${idbranch}/${idtrade}`)
  }

  const handleProducts = (idbranch) => {
    setRedirect(`/productsmerchants/${idbranch}/${id}`)
  }

  React.useEffect(() => {
    branch()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Sucursales de {name}</strong></h3>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={branches}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
    </div>
  )
}   

export default BranchListOperator
