import axios from 'axios'
import config from './config'

const URL = `${config.URL}/address`

export async function list (user) {
  const result = await axios.get(`${URL}/list/${user}`, config.configWithToken())
  return result.data
}

export default {
  list
}
