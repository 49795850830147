import axios from 'axios'
import config from './config'

const URL = `${config.URL}/sections`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/${page}`, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export async function status (id, status) {
  const result = await axios.get(`${URL}/remove/${id}/${status}`, config.configWithToken())
  return result.data
}

export async function product () {
  const result = await axios.get(`${URL}/product`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const result = await axios.get(`${URL}/search/admin/${page}/${name}`, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/sections/${id}`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  update,
  status,
  product,
  detail,
  search,
  deletes
}
