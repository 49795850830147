import axios from 'axios'
import config from './config'

const URL = `${config.URL}/country/`

export async function list () {
  const result = await axios.get(`${URL}list/active`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}${id}`)
  return result.data
}

export default {
  list,
  detail
}
