import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Upload,
  Button,
  Icon,
  Layout
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout

const ProfessionCreate = (props) => {
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [image, setImage] = React.useState()
  const [errorImage, setErrorImage] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const onBack = () => {
    setRedirect('/profession')
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.profession.create({
      name: name,
      image: image
    })

    if (response._id) {
      Modal.success({
        title: 'Creación de una profesión',
        content: `La profesión ${name} fue creada exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.image) {
      setErrorImage(error.image.message)
    }
  }

  const clearFields = () => {
    setName(undefined)
    setImage(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorImage(undefined)
  }

  const beforeUpload = () => {
    return false
  }

  const handleClearImage = async () => {
    setImage(undefined)
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <Layout className='layout'>
      <Header item='profession' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nueva profesión</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    name='name'
                    placeholder='Ingrese nombre de la profesión'
                    onChange={(event) => setName(event.target.value)} 
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Imagen*'
                  validateStatus={errorImage && ('error')}
                  help={errorImage}
                 >
                  {!image ? (
                    <Upload
                      name='file'
                      listType='picture'
                      beforeUpload={() => beforeUpload()}
                      onChange={(event) => setImage(event.file)}
                    >
                      <Button><Icon type='upload' /> Seleccionar imagen</Button>
                    </Upload>
                  ) : (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon
                        type='picture'
                        theme='outlined'
                        style={{ fontSize: '20px' }} 
                      />
                      {' '}
                      <span
                        style={{ paddingLeft: '10px', paddingRight: '10px' }}
                      >
                        {image.name}
                      </span> {' '}
                      <Icon
                        type='close'
                        style={{ fontSize: '20px', color: 'red' }}
                        onClick={() => handleClearImage()}
                      />
                    </div>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default ProfessionCreate
