import React from 'react'
import { Table } from 'antd'
import API from '../../api/index'

const Log = (props) => {
  const [page, setPage] = React.useState(1)
  const user = props.user
  const [logs, setLogs] = React.useState([])
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Descripción', dataIndex: 'description', key: 'description'},
    {title: 'Fecha de creación', dataIndex: 'creation_date', key: 'creation_date'}
  ]

  const getLogs = async (page) => {
    const logs = await API.log.listByUser(page, user)
    setLogs(logs)
  }

  const handlePrevUser = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      getLogs(pageTemp)
    }
  }

  const handleNextUser = () => {
    if (logs.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      getLogs(pageTemp)
    }
  }

  React.useEffect(() => {
    getLogs(page)
  }, [])

  return (
    <div>
      <Table
        columns={columns}
        dataSource={logs}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev'
            ) : ('ant-pagination-prev')}
          >
            <a onClick={() => handlePrevUser()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li className={logs.length >= 10 ? ('ant-pagination-next'
            ) : ('ant-pagination-disabled ant-pagination-next')}
          >
            <a onClick={() => handleNextUser()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Log
