import React from 'react'
import { Redirect } from 'react-router-dom'
import { Menu } from 'antd'


const Comerciante = (props) => {
  const item = props.item
  const [redirect, setRedirect] = React.useState(undefined)

  const handleLogout = () => {
    window.localStorage.clear()
    setRedirect('/')
  }

  const handleRedirect = (url) => {
    const pathname = window.location.pathname
    if (pathname !== url) {
      setRedirect(url)
    }
  }

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <Menu
      theme='dark'
      mode='horizontal'
      defaultSelectedKeys={[item]}
      style={{ lineHeight: '64px' }}
    >
      <Menu.Item key='1'>
        <span className='yellow'>Ganga</span> <span className='white'>Hoy</span>
      </Menu.Item>
      <Menu.Item key='home'>
        <a onClick={() => handleRedirect('/home')}>Inicio</a>
      </Menu.Item>
      <Menu.Item key='user'>
        <a onClick={() => handleRedirect('/usermerchant')}>Operadores</a>
      </Menu.Item>
      <Menu.Item key='category'>
        <a onClick={() => handleRedirect('/category')}>Categorias</a>
      </Menu.Item>
      <Menu.Item key='trade'>
        <a onClick={() => handleRedirect('/trademerchant')}>Comercios</a>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => handleLogout()}>Salir</a>
      </Menu.Item>
    </Menu>
  )
}

export default Comerciante
