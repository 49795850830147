import React from 'react'
import { Tag } from 'antd'
import API from '../../api/index'


const Action = (props) => {
  const section = props.section || {}
  const [is_activate, setIs_activate] = React.useState(section.is_activate)

  const handleAction = async () => {
    await API.sections.status(section._id, !is_activate)
    setIs_activate(!is_activate)
  }

  let color = 'green'
  let text = 'Activar'
  if (is_activate) {
    color = 'red'
    text = 'Desactivar'
  }
  return (
    <Tag color={color} onClick={() => handleAction()}>{text}</Tag>
  )
}
export default Action