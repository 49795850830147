import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Layout,
  Table,
  Icon,
  InputNumber
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const Content = Layout.Content

const SalesDetail = (props) => {
  const [bill, setBill] = React.useState({})
  const [Product, setProduct] = React.useState([])
  const [start_date, setStart_date] = React.useState(undefined)
  const [update_date, setUpdate_date] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const id = props.match.params.id
  const columns = [
    {
      title: '#',
      dataIndex: 'key',
      key: 'key'
    },
    {
      title: 'Producto',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Precio',
      dataIndex: 'price',
      key: 'price',
      render: (index, record) => {
        return (
          <span>
            {record.price && (
              <InputNumber
                value={record.price}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            )}
          </span>
        )
      }
    },
    {
      title: 'Promoción',
      dataIndex: 'is_promotion',
      key: 'is_promotion',
      render: (text, record) => {
        return (
          <span>
            {record.is_promotion ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {
      title: 'Descuento',
      dataIndex: 'discount',
      key: 'discount',
      render: (text, record) => {
        return (
          <span>
            {record.discount ? (
              <InputNumber
                disabled
                value={record.discount}
                formatter={value => `${value}%`}
                parser={value => value.replace('%', '')}
                style={{ width: 150 }}
              />
            ) : '------'}
          </span>
        )
      }
    },
    {
      title: 'Precio con descuento',
      dataIndex: 'price_discount',
      key: 'price_discount',
      render: (text, record) => {
        return (
          <span>
            {record.price_discount ? (
              <InputNumber
                value={record.price_discount}
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                style={{ width: 150 }}
                disabled
              />
            ) : '------'}
          </span>
        )
      }
    },
    {
      title: 'Cantidad del producto',
      dataIndex: 'quantity',
      key: 'quantity'
    },
    {
      title: 'SubTotal',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (text, record) => {
        return (
          <span>
            {record.totalPrice && (
              <InputNumber
                disabled
                formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                value={record.totalPrice}
                style={{ width: 150 }}
              />
            )
            }
          </span>
        )
      }
    }
  ]

  const getMonth = (index) => {
    const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']
    return months[index]
  }

  const formatDate = (date) => {
    const newDate = new Date(date)
    return ('0' + (newDate.getDate())).slice(-2) + ' de ' + getMonth(newDate.getMonth()) + ' del ' + 
    newDate.getFullYear() + ', ' + ('0' + newDate.getHours()).slice(-2) + ':' + ('0' + newDate.getMinutes()).slice(-2)
  }

  const getBill = async () => {
    const bill = await API.bill.detail(id)
    setStart_date(formatDate(bill.start_date))
    setUpdate_date(formatDate(bill.update_date))
    setProduct(bill.products)
    setBill(bill)
  }

  React.useEffect(() => {
    getBill()
  }, [])

  const onBack = () => {
    setRedirect('/home')
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  let response
  if (bill.pay_mode === 'buy_site') {
    response = 'Lugar'
  } else if (bill.pay_mode === 'buy_online') {
    response = 'Online'
  } else if (bill.pay_mode === 'buy_points') {
    response = 'Compra con puntos'
  }
  return (
    <Layout className='layout'>
      <Header item='sales' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle de la factura</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Nombre'
                >
                  <Input
                    disabled
                    value={bill.nameUser}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Sucursal'
                >
                  <Input
                    disabled
                    value={bill.nameBranch}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Método de pago'
                >
                  <Input
                    disabled
                    value={response}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Puntos por la compra'
                >
                  <Input
                    disabled
                    value={bill.points}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de creación'
                >
                  <Input
                    disabled
                    value={start_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Fecha de pago'
                >
                  <Input
                    type='text'
                    disabled
                    value={update_date}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Subtotal'
                >
                  <InputNumber
                    disabled
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={bill.totalPriceWithoutPoint}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Descuento por puntos'
                >
                  <InputNumber
                    disabled
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={bill.totalPriceWithoutPoint - bill.totalPriceBill}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Total pagado'
                >
                  <InputNumber
                    disabled
                    formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={bill.totalPriceBill}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item
                  label='Puntos usados'
                >
                  <InputNumber
                    disabled
                    parser={value => value.replace(/\$\s?|(,*)/g, '')}
                    value={bill.points_redeemed}
                    style={{ width: 150 }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <h3><strong>Productos</strong></h3>
              </Col>
              <Table
                columns={columns}
                dataSource={Product}
                size='small'
              />
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default SalesDetail
