import axios from 'axios'
import config from './config'

const URL = `${config.URL}/product`

export async function create (data) {
  const result = await axios.post(`${URL}`, data, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`)
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/all/product/${page}`, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/change/status`, { status: status, id: id }, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  const result = await axios.post(`${URL}/update/${id}`, data, config.configWithToken())
  return result.data
}

export async function search (page, product) {
  const result = await axios.get(`${URL}/search/${page}/${product}`, config.configWithToken())
  return result.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/product/${id}`, config.configWithToken())
  return result.data
}

export async function listBranch (id, page) {
  const result = await axios.get(`${URL}/allbranch/${id}/${page}`, config.configWithToken())
  return result.data
}

export default {
  create,
  list,
  change,
  update,
  detail,
  search,
  deletes,
  listBranch
}
