import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Modal,
  Form,
  Input,
  Button,
  Select,
  Layout
} from 'antd'
import Geosuggest from 'react-geosuggest'
import API from '../../../api/index'
import Header from '../../Header'
import Maps from '../../maps/Maps'

const { Content } = Layout

const BranchCreate = (props) => {
  const [idTrade, setIdTrade] = React.useState(undefined)
  const [name, setName] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [city, setCity] = React.useState(undefined)
  const [errorCity, setErrorCity] = React.useState(undefined)
  const [address, setAddress] = React.useState(undefined)
  const [errorAddress, setErrorAddress] = React.useState(undefined)
  const [country, setCountry] = React.useState(undefined)
  const [state, setState] = React.useState(undefined)
  const [cellphone, setCellphone] = React.useState(undefined)
  const [errorCellphone, setErrorCellphone] = React.useState(undefined)
  const [user, setUser] = React.useState(undefined)
  const [errorUser, setErrorUser] = React.useState(undefined)
  const [listUser, setListUser] = React.useState([])
  const [idCity, setIdCity] = React.useState([])
  const [idCountry, setIdCountry] = React.useState([])
  const [idState, setIdState] = React.useState([])
  const [lat, setLat] = React.useState(0)
  const [lng, setLng] = React.useState(0)
  const [redirect, setRedirect] = React.useState(undefined)
  const id = props.match.params.id



  const onSuggestSelect = (data) => {
    if (data) {
      setLat(data.location.lat)
      setLng(data.location.lng)
      setAddress(data.description)
    } else {
      setAddress(undefined)
      setLat(0)
      setLng(0)
    }
  }

  const createRow = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.name}</Select.Option>)
    })
    return row
  }

  const createRowUser = (values) => {
    let row = []
    values.map(value => {
      return row.push(<Select.Option value={value._id} key={value._id}>{value.first_name + ' ' + value.last_name}</Select.Option>)
    })
    return row
  }

  const User = async () => {
    const users = await API.trade.getOperators(id)
    setListUser(users)
  }

  const Country = async () => {
    const idCountry = await API.country.list()
    setIdCountry(idCountry)
    stateC()
  }

  const stateC = async (country = country) => {
    if (country) {
      const idState = await API.state.list(country)
      setIdState(idState)
    }
  }

  const cityC = async (state = state) => {
    if (state) {
      const idCity = await API.city.list(state)
      setIdCity(idCity)
    }
  }

  const idTradeC = async () => {
    const id = id
    const idTrade = await API.trade.detail(id)
    const name = idTrade.nit + ' ' + idTrade.trade_name
    setIdTrade(name)
  }

  const onSubmit = async () => {
    clearErrors()
    const response = await API.branchOffice.create({
      idTrade: id,
      name: name,
      city: city,
      address: address,
      cellphone: cellphone,
      user: user,
      gps: `${lat},${lng}`,
      allow_gps: true
    })
    console.log('response: ', response)
    if (response._id) {
      Modal.success({
        title: 'Creación de una sucursal',
        content: `La sucursal ${name} fue creada exitosamente`
      })
      clearFields()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.city) {
      setErrorCity(error.city.message)
    }
    if (error.address) {
      setErrorAddress(error.address.message)
    }
    if (error.cellphone) {
      setErrorCellphone(error.cellphone.message)
    }
    if (error.user) {
      setErrorUser(error.user.message)
    }
  }

  const clearFields = () => {
    setName(undefined)
    setCountry(undefined)
    setState(undefined)
    setCity(undefined)
    setCellphone(undefined)
    setUser(undefined)
    setIdTrade(undefined)
    setAddress(undefined)
    setLat(undefined)
    setLng(undefined)
    clearErrors()
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorCity(undefined)
    setErrorAddress(undefined)
    setErrorCellphone(undefined)
    setErrorUser(undefined)
  }

  const handleChangeCountry = async (value) => {
    setCountry(value)
    stateC(value)
  }

  const handleChangeState = async (value) => {
    setState(value)
    cityC(value)
  }

  const handleMaps = async (latitude, longitude) => {
    setLat(latitude)
    setLng(longitude)
  }

  const onBack = async () => {
    setRedirect(`/branches/list/${id}`)
  }

  React.useEffect(() => {
    idTradeC()
    Country()
    User()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  const City = createRow(idCity)
  const State = createRow(idState)
  const countryC = createRow(idCountry)
  const userC = createRowUser(listUser)

  return (
    <Layout className='layout' >
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Nueva sucursal</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Comercio'
                >
                  <Input
                    disabled
                    placeholder={idTrade}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre*'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el nombre de la sucursal'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='País*'
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una país'
                    onChange={(value) => handleChangeCountry(value)}
                    value={country}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryC}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Estado*'
                >
                  <Select
                      showSearch
                      type='text'
                      style={{ width: '100%' }}
                      placeholder='Seleccione una estado'
                      onChange={(value) => handleChangeState(value)}
                      value={state}
                      filterOption={(input, option) => 
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                    {State}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ciudad*'
                  validateStatus={errorCity && ('error')}
                  help={errorCity}
                >
                  <Select
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una ciudad'
                    onChange={(value) => setCity(value)}
                    value={city}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {City}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Teléfono*'
                  validateStatus={errorCellphone && ('error')}
                  help={errorCellphone}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese el teléfono de la sucursal'
                    onChange={(event) => setCellphone(event.target.value)}
                    value={cellphone}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Operador(es)*'
                  validateStatus={errorUser && ('error')}
                  help={errorUser}
                >
                  <Select
                    mode='multiple'
                    showSearch
                    type='text'
                    style={{ width: '100%' }}
                    placeholder='Seleccione una usuario'
                    onChange={(value) => setUser(value)}
                    value={user}
                    filterOption={(input, option) => 
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {userC}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Dirección*'
                  validateStatus={errorAddress && ('error')}
                  help={errorAddress}
                >
                  <Geosuggest
                    placeholder='Ingrese la dirección'
                    onSuggestSelect={(data) => onSuggestSelect(data)}
                    location={new window.google.maps.LatLng(4.6979319, -74.0780855)}
                    radius={20}
                    inputClassName='ant-input'
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Ubicación'
                >
                  {address && (<Maps lat={lat} lng={lng} handleMaps={(lat, lng) => handleMaps(lat, lng)} />)}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default BranchCreate
