import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Tag,
  Button,
  Icon
} from 'antd'
import API from '../../../../../../api/index'

const InventoryList = (props) => {
  const branch = props.branch
  const trade = props.trade
  const id = props.id
  const [inventory, setInventory] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [redirect, setRedirect] = React.useState(undefined)
  const name = props.name
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key'},
    {title: 'Cantidad', dataIndex: 'quantity', key: 'quantity'},
    {title: 'Fecha de creación', dataIndex: 'start_date', key: 'start_date'},
    {title: 'Fecha de actualización', dataIndex: 'update_date', key: 'update_date'},
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },{title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='orange' onClick={() => handleUpdateInventory(record._id)}>Editar</Tag>
          </span>
        )
      }
    }
  ]

  const handleReturn = () => {
    setRedirect(`/productsmerchants/${branch}/${trade}`)
  }

  const Inventory = async (page) => {
    const result = await API.inventory.list(id, page)
    setInventory(result)
  }

  const handleCreateInventory = () => {
    setRedirect(`/inventorymerchant/create/${id}/${name}/${branch}/${trade}`)
  }

  const handleUpdateInventory = (id) => {
    setRedirect(`/inventorymerchant/update/${id}/${name}/${branch}/${trade}`)
  }

  const handlePrevInventory = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      Inventory(pageTemp)
    }
  }

  const handleNextInventory = () => {
    if (inventory.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      Inventory(pageTemp)
    }
  }

  React.useState(() => {
    inventory(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado del producto {name}</strong></h3>
        </Col>
        <Col span={15} style={{ textAlign: 'right' }}>
          <Button onClick={() => handleReturn()}>Regresar</Button>
          {' '}
          <Button onClick={() => handleCreateInventory()}>Crear inventario</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={inventory}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={
            page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')
            }
          >
            <a onClick={() => handlePrevInventory()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>
            {page}
          </li>
          <li 
            className={
              inventory.length >= 10 ? ('ant-pagination-next') 
              : ('ant-pagination-disabled ant-pagination-next')
            }
          >
            <a onClick={() => handleNextInventory()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default InventoryList
