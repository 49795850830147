import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Form,
  Input,
  Row,
  Col,
  Button,
  Layout
} from 'antd'
import Header from '../../Header'
import API from '../../../api/index'

const { TextArea } = Input
const Content = Layout.Content

const BusinessDetail = (props) => {
  const [business, setBusiness] = React.useState({})
  const [redirect, setRedirect] = React.useState(undefined)
  const id = props.match.params.id
  const topic = props.match.params.topic

  const getBusiness = async () => {
    const busi = await API.business.detail(id)
    setBusiness(busi)
  }

  React.useEffect(() => {
    getBusiness()
  }, [])

  const onBack = () => {
    setRedirect(`/business/list/${topic}`)
  }

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <Layout className='layout'>
      <Header item='topic' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Detalle del negocío</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
            </Col>
          </Row>
          <Form layout='vertical' >
            <Row>
              <Col span={7} offset={1} >
                <Form.Item label='Nombre' >
                  <Input disabled value={business.name} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item label='Imagen' >
                  <img
                    alt={business.name}
                    src={business.image}
                    style={{ maxWidth: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item label='País' >
                  <Input disabled value={business.countryName} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item label='Estado' >
                  <Input disabled value={business.stateName} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item label='Ciudad' >
                  <Input type='text' disabled value={business.cityName}/>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label='Dirección*'>
                  <Input type='text' disabled value={business.address} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1} >
                <Form.Item label='Email'>
                  <Input disabled value={business.email} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item label='Teléfono'>
                  <Input disabled value={business.cellphone} />
                </Form.Item>
              </Col>
              <Col span={7} offset={1} >
                <Form.Item label='Temas'>
                  <Input type='text' disabled value={business.nameTopic}/>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item label='Descripción'>
                  <TextArea disabled type='text' value={business.description} row={4} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default BusinessDetail
