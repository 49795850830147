import React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import {
  Table,
  Row,
  Col,
  Tag,
  Button,
  Icon,
  Input,
  Modal
} from 'antd'
import API from '../../api/index'
import { getItem } from '../../api/utils'
import Action from './Action'
const Search = Input.Search

const TopicList =  (props) => {
  const [professions, setProfessions] = React.useState([])
  const [topic, setTopic] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)
  const type = getItem('type')
  const { push } = useHistory()
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key' },
    {title: 'Nombre', dataIndex: 'name', key: 'name'},
    {title: 'Tipo', dataIndex: 'type', key: 'type',
      render: (value) => {
        return (
        <span>{value === 'payment' ? 'Pago' : 'Gratis'}</span>
        )
      }
    },
    {title: 'Activo', dataIndex: 'is_active', key: 'is_active',
      render: (text, record) => {
        return (
          <span>
            {record.is_active ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action',
      render: (text, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => push(`/topic/detail/${record._id}`)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => push(`/topic/update/${record._id}`)}>Editar</Tag>
            <Action topic={record} />
            <Tag color='purple' onClick={() => push(`/topic/assistant/${record._id}`)}>Asistentes</Tag>
            <Tag color='#26D543' onClick={() => push(`/business/list/${record._id}`)}>Negocios</Tag>
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
          </span>
        )
      }
    }
  ]

  const handleDownload = async () => {
    const users = 'topic'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleDelete = (id) => {
    setDelete(true)
    setDeleteId(id)
  }

  const handleTopicDelete = async () => {
    const result = await API.topic.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'La feria ha sido eliminada exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const Topic = async (page) => {
    const result = await API.topic.list(page)
    setTopic(result)
    setProfessions(result)
  }

  const getTopicByName = async (page) => {
    const result = await API.topic.search(page, search)
    setTopic(result)
    setProfessions(result)
  }

  const handleCreateTopic = () => {
    setRedirect('/topic/create')
  }

  const handlePrev = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      Topic(pageTemp)
    }
  }

  const handleNext = () => {
    if (professions.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      Topic(pageTemp)
    }
  }

  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    Topic(1)
  }

  const onSearch  =() => {
    setIs_search(true)
    setPage(1)
    getTopicByName(1)
  }

  React.useState(() => {
    Topic(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }

  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de ferias</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            name='search'
            placeholder='Ingrese nombre de la feria'
            onSearch={() => onSearch}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
          {Delete && (
            <Modal
              title='Alerta'
              visible={Delete}
              onOk={() =>handleTopicDelete()}
              onCancel={() => handleAlertNot()}
              okText='Continuar'
              cancelText='Cancelar'
            >
              <p>¿Desea eliminar la feria?</p>
            </Modal>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          {(type === 'Administrador' || type === 'supervisor') && (
            <Button onClick={handleDownload}>Descargar csv</Button>
          )}
          {' '}
          <Button onClick={handleCreateTopic}>Crear feria</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={topic}
        size='small'
        pagination={{
          position: 'none'
       }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrev()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={topic.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={() => handleNext()}>Siguiente</a>
          </li>
       </ul>
      </div>
    </div>
  )
}
export default TopicList
