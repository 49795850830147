import axios from 'axios'
import config from './config'
import { setItem } from './utils'

const URL = `${config.URL}/auth`

export async function login (email, password) {
  const result = await axios.post(`${URL}/superuser`, { email, password })
  if (result.data.token) {
    setItem('token', result.data.token)
    setItem('type', result.data.type)
    setItem('fullName', result.data.fullName)
  }
  return result.data
}

export async function verify (token) {
  const result = await axios.post(`${URL}/verify`, { token }, config.configWithToken())
  return result.data
}

export default {
  login,
  verify
}
