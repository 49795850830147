import React from 'react'
import SectionsList from './List'
import { Layout } from 'antd'
import Header from '../Header'

const { Content } = Layout

const Sections = (props) => {
  return (
    <Layout className='layout'>
      <Header item='sections' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <SectionsList />
        </div>
      </Content>
    </Layout>
  )
}
export default Sections