import React from 'react'
import { Tag } from 'antd'
import API from '../../api/index'

const Action = (props) => {
  const [is_active, setIs_active] = React.useState(props.trade.is_active)
  const trade = props.trade

  const handleAction = async () => {
    await API.trade.change(trade._id, !is_active)
    setIs_active(!is_active)
  }

  let color = 'green'
  let text = 'Activar'
  if (is_active) {
    color = 'red'
    text = 'Desactivar'
  }
  return (
    <Tag color={color} onClick={() => handleAction()}>{text}</Tag>
  )
}

export default Action
