import React from 'react'
import { Redirect } from 'react-router-dom'
import { getItem } from '../../api/utils'
import {
  Table,
  Row,
  Col,
  Button,
  Tag,
  Icon,
  Input,
  Modal
} from 'antd'
import API from '../../api/index'
import Action from './Action'
const Search = Input.Search

const SectionsList = (props) => {
  const [sections, setSections] = React.useState([])
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState(undefined)
  const [is_search, setIs_search] = React.useState(false)
  const [redirect, setRedirect] = React.useState(undefined)
  const [Delete, setDelete] = React.useState(false)
  const [deleteId, setDeleteId] = React.useState(undefined)
  const type = getItem('type')
  const columns = [
    {title: '#', dataIndex: 'key', key: 'key' },
    {title: 'Nombre', dataIndex: 'name', key: 'name' },
    {title: 'Descripción', dataIndex: 'description', key: 'description'},
    {title: 'Activo', dataIndex: 'is_activate', key: 'is_activate', 
      render: (index, record) => {
        return (
          <span>
            {record.is_activate ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Primaria (máximo segun administrador)', dataIndex: 'is_primary', key: 'is_primary',
      render: (index, record) => {
        return (
          <span>
            {record.is_primary ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Menú (máximo 3)', dataIndex: 'is_menu', key: 'is_menu', 
      render: (index, record) => {
        return (
          <span>
            {record.is_menu ? (
              <Icon type='check-circle' theme='twoTone' twoToneColor='#52c41a' />
            ) : (
              <Icon type='close-circle' theme='twoTone' twoToneColor='red' />
            )}
          </span>
        )
      }
    },
    {title: 'Acción', dataIndex: 'action', key: 'action', 
      render: (index, record) => {
        return (
          <span>
            <Tag color='blue' onClick={() => handleViewSections(record._id)}>Ver detalle</Tag>
            <Tag color='orange' onClick={() => handleUpdateSections(record._id)}>Editar</Tag>
            <Action section={record} />
            {type === 'Administrador' && (
              <Tag color='#848C8B' onClick={() => handleDelete(record._id)}>Eliminar</Tag>
            )}
          </span>
        )
      }
    }
  ]
  

  const Sections = async (page) => {
    const response = await API.sections.list(page)
    setSections(response)
  }

  const getSectionsByName = async (page) => {
    const response = await API.sections.search(page, search)
    setSections(response)
  }

  const handleClearSearch = () => {
    setIs_search(false)
    setSearch(undefined)
    setPage(1)
    Sections(1)
  }

  const onSearch = () => {
    setIs_search(true)
    setPage(1)
    getSectionsByName(1)
  }

  const handleDownload = async () => {
    const users = 'sections'
    const result = await API.csv.download({
      model: users
    })
    if (result._id) {
      Modal.success({
        title: 'Descarga archivo csv',
        content: `El archivo csv fue enviado a su email exitosamente`
      })
    }
  }

  const handleDelete = (id) => {
    setDelete(true)
    setDeleteId(id)
  }

  const handleUserDelete = async () => {
    const result = await API.sections.deletes(deleteId)
    if (result.nModified === 1) {
      setDelete(false)
      setDeleteId(undefined)
      Modal.success({
        title: 'La seccion ha sido eliminada exitosamente'
      })
    }
  }

  const handleAlertNot = () => {
    setDelete(false)
    setDeleteId(undefined)
  }

  const handleCreateSections = () => {
    setRedirect('/sections/create')
  }

  const handleViewSections = (id) => {
    setRedirect(`/sections/detail/${id}`)
  }

  const handleUpdateSections = (id) => {
    setRedirect(`/sections/update/${id}`)
  }

  const handlePrevSections = () => {
    if (page > 1) {
      const pageTemp = page - 1
      setPage(pageTemp)
      Sections(pageTemp)
    }
  }

  const handleNextSections = () => {
    if (sections.length >= 10) {
      const pageTemp = page + 1
      setPage(pageTemp)
      Sections(pageTemp)
    }
  }

  React.useEffect(() => {
    Sections(page)
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <div>
      <Row style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <h3><strong>Listado de secciones</strong></h3>
        </Col>
        <Col span={8}>
          <Search
            value={search}
            name='search'
            placeholder='Ingrese nombre de la sección'
            onSearch={() => onSearch()}
            onChange={(event) => setSearch(event.target.value)}
            enterButton
          />
          {search && is_search && (
            <Tag
              color='red'
              style={{ marginTop: '5px' }}
              onClick={() => handleClearSearch()}
            >
              Limpiar busqueda
            </Tag>
          )}
          {Delete && (
            <Modal
              title='Alerta'
              visible={Delete}
              onOk={() => handleUserDelete()}
              onCancel={() => handleAlertNot()}
              okText='Continuar'
              cancelText='Cancelar'
            >
              <p>¿Desea eliminar esta sección?</p>
            </Modal>
          )}
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Button onClick={() => handleDownload()}>Descargar csv</Button>
          {' '}
          <Button onClick={() => handleCreateSections()}>Crear sección</Button>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={sections}
        size='small'
        pagination={{
          position: 'none'
        }}
      />
      <div style={{
        textAlign: 'right',
        marginTop: '10px'
      }}>
        <ul className='ant-pagination '>
          <li className={page === 1 ? ('ant-pagination-disabled ant-pagination-prev') : ('ant-pagination-prev')}>
            <a onClick={() => handlePrevSections()}>Anterior</a>
          </li>
          <li className='ant-pagination-item ant-pagination-item-1 ant-pagination-item-active'>{page}</li>
          <li className={sections.length >= 10 ? ('ant-pagination-next') : ('ant-pagination-disabled ant-pagination-next')}>
            <a onClick={() => handleNextSections()}>Siguiente</a>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SectionsList
