import axios from 'axios'
import config from './config'

const URL = `${config.URL}/bill`

export async function list (id) {
  const result = await axios.get(`${URL}/list/user/${id}`, config.configWithToken())
  return result.data
}

export async function branch (id, page) {
  const result = await axios.get(`${URL}/list/branch/${id}/${page}`, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}/`, config.configWithToken())
  return result.data
}

export async function all (page) {
  const result = await axios.get(`${URL}/list/all/admin/${page}`, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const result = await axios.get(`${URL}/search/branch/${name}/${page}`, config.configWithToken())
  return result.data
}

export async function generate_report (start_date, end_date, products, branch) {
  const result = await axios.post(`${URL}/generate-report`, {start_date, end_date, products, branch}, config.configWithToken())
  return result.data
}

export default {
  list,
  branch,
  detail,
  all,
  search,
  generate_report
}
