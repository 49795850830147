import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Layout,
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout

const Configuration = (props) => {
  const [id, setId] = React.useState(undefined)
  const [valuePoint, setValuePoint] = React.useState(undefined)
  const [errorValuePoint, setErrorValuePoint] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const onSubmit = async () => {
    clearErrors()
    if (id) {
      const response = await API.configuration.update(id, {
        valuePoint: valuePoint
      })
      if (response.updated) {
        Modal.success({
          title: 'Configuración del sistema',
          content: 'La configuración del sistema se actualizó exisitosamente'
        })
      } else {
        makeErrors(response.errors)
      }
    } else {
      const response = await API.configuration.create({
        valuePoint: valuePoint
      })
      if (response._id) {
        Modal.success({
          title: 'Configuración del sistema',
          content: 'La configuración del sistema se actualizó exisitosamente'
        })
      } else {
        makeErrors(response.errors)
      }
    }
  }

  const makeErrors = (error) => {
    if (error.valuePoint) {
      setErrorValuePoint(error.valuePoint.message)
    }
  }

  const clearErrors = () => {
    setErrorValuePoint(undefined)
  }

  const onBack = async () => {
    setRedirect('/home')
  }

  const getConfiguration = async () => {
    const configuration = await API.configuration.detail()
    if (configuration._id) {
      setId(configuration._id)
      setValuePoint(configuration.valuePoint)
    }
  }
  React.useEffect(() => {
    getConfiguration()
  }, [])

  if (redirect) {
    return (<Redirect to={redirect} />)
  }
  return (
    <Layout className='layout'>
      <Header item='configuration' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Configuración del sistema</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Guardar</Button>
            </Col>
          </Row>
          <div>* Son campos obligatorios</div>
          <p />
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Valor por punto*'
                  validateStatus={errorValuePoint && ('error')}
                  help={errorValuePoint}
                >
                  <Input
                    type='text'
                    name='valuePoint'
                    placeholder='Ingrese el valor por punto'
                    onChange={(event) => setValuePoint(event.target.value)}
                    value={valuePoint}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}

export default Configuration
