import axios from 'axios'
import config from './config'

const URL = `${config.URL}/account`

export async function create (data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      if (data[key] === 'image') {
        form.append([key], data[key], data[key].name)
      } else {
        form.append([key], data[key])
      }
    }
  })
  const result = await axios.post(`${URL}/create`, form, config.configWithToken())
  return result.data
}

export async function detail (id) {
  const result = await axios.get(`${URL}/detail/${id}`, config.configWithToken())
  return result.data
}

export async function list (page) {
  const result = await axios.get(`${URL}/list/all/${page}`, config.configWithToken())
  return result.data
}

export async function change (id, status) {
  if (status === true) {
    status = 'active'
  } else {
    status = 'deactive'
  }
  const result = await axios.post(`${URL}/change/status`, {
    status: status,
    id: id
  }, config.configWithToken())
  return result.data
}

export async function users_by_trade (trade) {
  const result = await axios.post(`${URL}/all/users-by-trade`, {trade}, config.configWithToken())
  return result.data
}

export async function typeuser (type) {
  const result = await axios.post(`${URL}/typeuser`, {
    type_user: type
  }, config.configWithToken())
  return result.data
}

export async function update (id, data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      form.append([key], data[key])
    }
  })
  const result = await axios.post(`${URL}/update/${id}`, form, config.configWithToken())
  return result.data
}

export async function search (page, name) {
  const response = await axios.get(`${URL}/search/${page}/${name}`, config.configWithToken())
  return response.data
}

export async function deletes (id) {
  const result = await axios.get(`${URL}/delete/user/${id}`, config.configWithToken())
  return result.data
}

export async function merchantUser (page) {
  const result = await axios.post(`${URL}/usercreate/list/${page}`, {}, config.configWithToken())
  return result.data
}

export async function updateMerchant (id, data) {
  let form = new FormData()
  Object.keys(data).forEach(function (key) {
    if (data[key]) {
      form.append([key], data[key])
    }
  })
  const result = await axios.post(`${URL}/merchant/update/${id}`, form, config.configWithToken())
  return result.data
}

export async function listUserTrade () {
  const result = await axios.get(`${URL}/trader/user`, config.configWithToken())
  return result.data
}

export default {
  list,
  detail,
  change,
  create,
  typeuser,
  update,
  search,
  deletes,
  merchantUser,
  updateMerchant,
  listUserTrade,
  users_by_trade
}
