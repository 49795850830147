import React from 'react'
import { Redirect } from 'react-router-dom'
import {
  Row,
  Col,
  Layout,
  Form,
  Input,
  Button,
  Select,
  Modal
} from 'antd'
import Header from '../Header'
import API from '../../api/index'

const { Content } = Layout
const { TextArea } = Input

const SectionsCreate = (props) => {
  const id = props.match.params.id
  const [name, setName] = React.useState(undefined)
  const [name_verify, setName_verify] = React.useState(undefined)
  const [errorName, setErrorName] = React.useState(undefined)
  const [description, setDescription] = React.useState(undefined)
  const [errorDescription, setErrorDescription] = React.useState(undefined)
  const [type, setType] = React.useState(undefined)
  const [errorType, setErrorType] = React.useState(undefined)
  const [is_primary, setIs_primary] = React.useState(undefined)
  const [errorPrimary, setErrorPrimary] = React.useState(undefined)
  const [is_menu, setIs_menu] = React.useState(undefined)
  const [errorMenu, setErrorMenu] = React.useState(undefined)
  const [is_menu_verify, setIs_menu_verify] = React.useState(undefined)
  const [redirect, setRedirect] = React.useState(undefined)

  const onBack = () => {
    setRedirect('/sections')
  }

  const onSubmit = async () => {
    clearErrors()
    let isMenu
    let nameTrue
    if (is_menu_verify !== is_menu) {
      isMenu = is_menu
    }
    if (name_verify !== name) {
      nameTrue = name
    }
    const response = await API.sections.update(id, {
      name: nameTrue,
      description: description,
      is_primary: is_primary,
      is_menu: isMenu,
      type: type
    })
    if (response.ok) {
      Modal.success({
        title: 'Actualización de una sección',
        content: `La sección ${name} fue actualizada exitosamente`
      })
      clearErrors()
    }
    if (response.errors) {
      makeErrors(response.errors)
    }
  }

  const makeErrors = (error) => {
    if (error.name) {
      setErrorName(error.name.message)
    }
    if (error.description) {
      setErrorDescription(error.description.message)
    }
    if (error.type) {
      setErrorType(error.type.message)
    }
    if (error.is_primary) {
      setErrorPrimary(error.is_primary.message)
    }
    if (error.is_menu) {
      setErrorMenu(error.is_menu.message)
    }
  }

  const clearErrors = () => {
    setErrorName(undefined)
    setErrorDescription(undefined)
    setErrorType(undefined)
    setErrorMenu(undefined)
    setErrorPrimary(undefined)
  }

  const getSections = async () => {
    const section = await API.sections.detail(id)
    setName(section.name)
    setName_verify(section.name)
    if (section.is_primary) {
      setIs_primary('true')
    } else {
      setIs_primary('false')
    }
    if (section.is_menu) {
      setIs_menu('true')
      setIs_menu_verify('true')
    } else {
      setIs_menu('false')
      setIs_menu_verify('false')
    }
    setType(section.type)
    setDescription(section.description)
  }

  React.useEffect(() => {
    getSections()
  }, [])

  if (redirect) {
    return (
      <Redirect to={redirect} />
    )
  }
  return (
    <Layout className='layout'>
      <Header item='sections' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={8}>
              <h3><strong>Actualización de la sección</strong></h3>
            </Col>
            <Col span={8} offset={8} style={{ textAlign: 'right' }}>
              <Button onClick={() => onBack()}>Regresar</Button>
              {' '}
              <Button onClick={() => onSubmit()}>Editar</Button>
            </Col>
          </Row>
          <Form
            layout='vertical'
          >
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Nombre'
                  validateStatus={errorName && ('error')}
                  help={errorName}
                >
                  <Input
                    type='text'
                    placeholder='Ingrese nombre de la Sección'
                    onChange={(event) => setName(event.target.value)}
                    value={name}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Descripción'
                  validateStatus={errorDescription && ('error')}
                  help={errorDescription}
                >
                  <TextArea
                    type='text'
                    placeholder='Ingrese una breve descripción de la Sección'
                    onChange={(event) => setDescription(event.target.value)}
                    value={description}
                    autosize={{ minRows: 4, maxRows: 6 }}
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='Tipo de sección'
                  validateStatus={errorType && ('error')}
                  help={errorType}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setType(value)}
                    value={type}
                    placeholder='Seleccione un tipo'
                  >
                    <Select.Option value='Persona'>Persona</Select.Option>
                    <Select.Option value='Producto'>Producto</Select.Option>
                    <Select.Option value='Negocio'>Negocio</Select.Option>
                    <Select.Option value='Fidelidad'>Programa de fidelidad</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Es una sección primaria?'
                  validateStatus={errorPrimary && ('error')}
                  help={errorPrimary}
                 >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setIs_primary(value)}
                    value={is_primary}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item
                  label='¿Pertenece al menú?'
                  validateStatus={errorMenu && ('error')}
                  help={errorMenu}
                >
                  <Select
                    type='text'
                    style={{ width: '100%' }}
                    onChange={(value) => setIs_menu(value)}
                    value={is_menu}
                    placeholder='Seleccione su respuesta'
                  >
                    <Select.Option value='true'>Si</Select.Option>
                    <Select.Option value='false'>No</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Content>
    </Layout>
  )
}
export default SectionsCreate