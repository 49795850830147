import React from 'react'
import BranchList from './List'
import { Layout } from 'antd'
import Header from '../../Header'

const { Content } = Layout


const Branch = (props) => {
  const id = props.match.params.branch
  
  return (
    <Layout className='layout'>
      <Header item='trade' />
      <Content style={{ padding: '0 50px' }}>
        <div className='content-auto'>
          <BranchList id={id} />
        </div>
      </Content>
    </Layout>
  )
}

export default Branch
